import Button from "components/form/Button/Button";
import React, { useState } from "react";
import UnSubscribeFilterPopup from "../UnSubscribeFilterPopup";
import DisplayTable from "../DisplayTable";

const SubscribeReport = () => {
  const [showPopup, setShowPopup] = useState(false);

  const header = [
    {
      title: <div className="ps-3">Name</div>,
    },
    {
      title: <div className="text-nowrap">Date of Birth</div>,
    },
    {
      title: <div className="ps-3">Contact Details</div>,
    },

    {
      title: <div className="text-nowrap">College Details</div>,
    },

    {
      title: <div className="text-nowrap">Location</div>,
    },

    {
      title: (
        <div className="text-nowrap">Basic Education & Areas of Interset</div>
      ),
    },
  ];
  const rowData = [];

  return (
    <div className="bg-white rounded mt-2 pt-1 pb-1">
      {showPopup && (
        <UnSubscribeFilterPopup
          onHide={() => {
            setShowPopup(false);
          }}
        />
      )}
      <div className="d-flex gap-3 text-20-700 justify-content-between align-items-center mb-5 p-3">
        <div>Subscribe Report</div>
        <div className="d-flex gap-4">
          <Button
            value="Filter"
            btnStyle="btn-dashboard-primary"
            className="cps-30 cpe-30 pt-2 pb-2 text-15-500 h-auto"
            onClick={() => {
              setShowPopup(true);
            }}
          />

          {/* <CSVLink
              data={exportReport}
              filename={`${type}.csv`}
              className="text-decoration-none"
            >
              <Button
                value="Export"
                btnStyle="btn-dashboard-primary"
                className="cps-30 cpe-30 pt-2 pb-2 text-15-500 h-auto"
              />
            </CSVLink> */}
        </div>
      </div>
      <DisplayTable header={header} rowData={rowData} />
    </div>
  );
};

export default SubscribeReport;
