import { useRef, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import Dropdown from "components/form/Dropdown";
import TextInput from "components/form/TextInput";
import TextArea from "components/form/TextArea";
import FileUpload from "components/form/FileUpload";
import Button from "components/form/Button";
import { useDispatch } from "react-redux";
import { addQuestions, throwSuccess } from "store/globalSlice";
import {
  objectToFormData,
  searchKeyword,
  seoMetaData,
  slugURL,
  trimLeftSpace,
} from "utils/helpers";
import { omit } from "lodash";
import { useParams } from "react-router-dom";

const StudyToolsForm = ({ type }) => {
  const params = useParams();
  // console.log(params);
  const dispatch = useDispatch();
  const formRef = useRef();
  const [btnLoading, setBtnLoading] = useState(false);

  const handelSave = async (values) => {
    setBtnLoading(true);
    let newObject = values;
    if (values.type === "flashCard") {
      newObject = omit(newObject, [
        "answer",
        "description",
        "externalFileName",
        "keyword",
        "meta_keyword",
        "option1",
        "option2",
        "option3",
        "option4",
      ]);
    }
    if (values.type === "askQuestion") {
      newObject = omit(newObject, [
        "externalFile",
        "description",
        "externalFileName",
        "keyword",
        "meta_keyword",
      ]);
    }
    if (values.type === "expertSolution") {
      newObject = omit(newObject, [
        "externalFile",
        "externalFileName",
        "keyword",
        "meta_keyword",
        "option1",
        "option2",
        "option3",
        "option4",
        "answer",
      ]);
    }
    const payload = objectToFormData(newObject);
    const response = await dispatch(addQuestions(payload));
    if (response?.status === 201) {
      dispatch(throwSuccess("Question Add Successfully"));
      if (formRef.current) {
        formRef.current.resetForm();
      }
    }
    setBtnLoading(false);
  };
  const validationSchema = Yup.object().shape({
    question: Yup.string().required("Title is required."),
    // keywords: Yup.string().required("Keywords is required."),
    slug: Yup.string().required("Slug URL is required."),
    option1: Yup.lazy((_, obj) => {
      const { type } = obj?.parent;
      if (type === "askQuestion") {
        return Yup.string().required("Option a is required.");
      } else {
        return Yup.string();
      }
    }),
    option2: Yup.lazy((_, obj) => {
      const { type } = obj?.parent;
      if (type === "askQuestion") {
        return Yup.string().required("Option b is required.");
      } else {
        return Yup.string();
      }
    }),
    answer: Yup.lazy((_, obj) => {
      const { type } = obj?.parent;
      if (type === "askQuestion") {
        return Yup.string().required("Answer is required.");
      } else {
        return Yup.string();
      }
    }),
    description: Yup.lazy((_, obj) => {
      const { type } = obj?.parent;
      if (type === "expertSolution") {
        return Yup.string().required("Description is required.");
      } else {
        return Yup.string();
      }
    }),
    externalFile: Yup.lazy((_, obj) => {
      const { type } = obj?.parent;
      if (type === "flashCard") {
        return Yup.string().required("External File is required.");
      } else {
        return Yup.string();
      }
    }),
  });
  const initialValues = {
    type: type,
    question: "",
    option1: "",
    option2: "",
    option3: "",
    option4: "",
    answer: "",
    description: "",
    keywords: "",
    keyword: "",
    slug: "",
    meta_title: "",
    meta_description: "",
    meta_keyword: "",
    meta_keywords: "",
    externalFile: "",
    externalFileName: "",
  };
  // console.log("type", params?.qType);
  return (
    <div className="bg-white rounded p-3">
      <Formik
        innerRef={formRef}
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handelSave}
      >
        {(props) => {
          const {
            values,
            errors,
            handleChange,
            setFieldValue,
            submitForm,
            handleReset,
          } = props;
          const {
            type,
            question,
            keyword,
            keywords,
            option1,
            option2,
            option3,
            option4,
            answer,
            description,
            slug,
            meta_title,
            meta_description,
            meta_keyword,
            meta_keywords,
            externalFileName,
          } = values;

          let answerList = [];
          if (option1) {
            answerList.push({ id: option1, label: option1 });
          }
          if (option2) {
            answerList.push({ id: option2, label: option2 });
          }
          if (option3) {
            answerList.push({ id: option3, label: option3 });
          }
          if (option4) {
            answerList.push({ id: option4, label: option4 });
          }
          let keywordsArray = keywords ? keywords.split(",") : [];
          let metaKeywordsArray = meta_keywords ? meta_keywords.split(",") : [];

          return (
            <form className="row">
              {/* <div className="col-md-12">
                <div className="row">
                  <div className="col-md-6 cmb-22">
                    <Dropdown
                      label="Question Type"
                      placeholder="Select Question Type"
                      id="type"
                      value={type}
                      onChange={handleChange}
                      options={[
                        {
                          id: "flashCard",
                          label: "Flash Card",
                        },
                        {
                          id: "askQuestion",
                          label: "Ask Question",
                        },
                        {
                          id: "expertSolution",
                          label: "Expert Solution",
                        },
                      ]}
                    />
                  </div>
                </div>
              </div> */}

              <div className="cmb-22">
                <TextInput
                  required
                  label="Title"
                  placeholder="Enter Title"
                  id="question"
                  value={question}
                  onChange={(e) => {
                    setFieldValue("slug", slugURL(e.target.value));
                    setFieldValue("keywords", searchKeyword(e.target.value));
                    setFieldValue("meta_title", seoMetaData(e.target.value));
                    handleChange(e);
                  }}
                  error={errors.question}
                />
              </div>
              <div className="cmb-22">
                <TextInput
                  required
                  label="Slug Url"
                  labelPrev={
                    (params?.qType === "ask-questions" &&
                      `https://helpacourse.com/qa/${slug}`) ||
                    (params?.qType === "expert-solutions" &&
                      `https://helpacourse.com/es/${slug}`) ||
                    (params?.qType === "flash-cards" &&
                      `https://helpacourse.com/fc/${slug}`)
                  }
                  placeholder="Enter Slug URL"
                  id="slug"
                  value={slug}
                  // `https://helpacourse.com/quiz-solutions/${slug}`
                  // value={slugURL(question)}
                  error={errors.slug}
                  onChange={handleChange}
                />
              </div>
              {type === "askQuestion" && (
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-3 cmb-22">
                      <TextInput
                        required
                        label="Option a"
                        placeholder="Enter Option a"
                        id="option1"
                        value={option1}
                        error={errors.option1}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-md-3 cmb-22">
                      <TextInput
                        required
                        label="Option b"
                        placeholder="Enter Option b"
                        id="option2"
                        value={option2}
                        error={errors.option2}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-md-3 cmb-22">
                      <TextInput
                        label="Option c"
                        placeholder="Enter Option c"
                        id="option3"
                        value={option3}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-md-3 cmb-22">
                      <TextInput
                        label="Option d"
                        placeholder="Enter Option d"
                        id="option4"
                        value={option4}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
              )}

              {option1 && option2 && type === "askQuestion" && (
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-6 cmb-22">
                      <Dropdown
                        isRequired
                        label="Answer"
                        placeholder="Select Answer"
                        options={answerList}
                        id="answer"
                        value={answer}
                        onChange={handleChange}
                        error={errors.answer}
                      />
                    </div>
                  </div>
                </div>
              )}

              {type === "expertSolution" && (
                <div className="cmb-22">
                  <TextArea
                    isRequired
                    label="Description"
                    placeholder="Enter Description"
                    id="description"
                    value={description}
                    onChange={handleChange}
                    error={errors.description}
                  />
                </div>
              )}

              {type === "flashCard" && (
                <div className="cmb-22">
                  <FileUpload
                    required
                    id="externalFile"
                    fileText={externalFileName || ""}
                    label="Image/Pdf"
                    error={errors.externalFile}
                    onChange={(e) => {
                      setFieldValue("externalFileName", e.target.fileName);
                      handleChange(e);
                    }}
                  />
                </div>
              )}

              {/* <div className="col-md-12 cmb-22">
                <div className="row">
                  <div className="">
                    <TextInput
                      required
                      label="Keywords for Search Results"
                      placeholder="Enter Search Keywords"
                      id="keyword"
                      value={keyword}
                      rightIcon={<i className="bi bi-plus-circle" />}
                      rightIconClick={() => {
                        if (trimLeftSpace(keyword)) {
                          let arr = keywordsArray;
                          arr.push(keyword);
                          let arrString = arr.length === 0 ? "" : arr.join(",");
                          setFieldValue("keyword", "");
                          setFieldValue("keywords", arrString);
                        }
                      }}
                      onChange={handleChange}
                      onKeyUp={(e) => {
                        if (
                          e?.keyCode === 13 &&
                          trimLeftSpace(e.target.value)
                        ) {
                          let arr = keywordsArray;
                          arr.push(keyword);
                          let arrString = arr.length === 0 ? "" : arr.join(",");
                          setFieldValue("keyword", "");
                          setFieldValue("keywords", arrString);
                        }
                      }}
                      error={errors.keywords}
                    />
                  </div>
                </div>

                {keywordsArray.length > 0 && (
                  <div className="row mt-3">
                    <div className="w-auto d-flex flex-wrap gap-3">
                      {keywordsArray?.map((el, index) => {
                        return (
                          <div
                            key={index}
                            className="w-auto d-flex align-items-center gap-2 shadow ps-2 pt-2 pe-2 pb-1"
                          >
                            <span>{el}</span>
                            <span>
                              <i
                                className="bi bi-trash-fill pointer"
                                onClick={() => {
                                  let newArry = keywordsArray.filter(
                                    (_, i) => i !== index
                                  );
                                  let arrString =
                                    newArry.length === 0
                                      ? ""
                                      : newArry.join(",");
                                  setFieldValue("keywords", arrString);
                                }}
                              />
                            </span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </div> */}

              <div className="col-md-12 cmb-22">
                <div className="row">
                  <div className="text-20-700">SEO Meta Data</div>

                  <div className="cmb-22">
                    <TextInput
                      label="Title"
                      placeholder="Enter Title"
                      id="meta_title"
                      value={meta_title}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="cmb-22">
                    <TextArea
                      label="Description"
                      placeholder="Enter Description"
                      id="meta_description"
                      value={meta_description}
                      onChange={handleChange}
                    />
                  </div>
                  <div>
                    <TextInput
                      label="Keywords"
                      placeholder="Enter Keywords"
                      id="meta_keyword"
                      value={meta_keyword}
                      onChange={handleChange}
                      rightIcon={<i className="bi bi-plus-circle" />}
                      rightIconClick={() => {
                        if (trimLeftSpace(meta_keyword)) {
                          let arr = metaKeywordsArray;
                          arr.push(meta_keyword);
                          let arrString = arr.length === 0 ? "" : arr.join(",");
                          setFieldValue("meta_keyword", "");
                          setFieldValue("meta_keywords", arrString);
                        }
                      }}
                      onKeyUp={(e) => {
                        if (
                          e?.keyCode === 13 &&
                          trimLeftSpace(e.target.value)
                        ) {
                          let arr = metaKeywordsArray;
                          arr.push(meta_keyword);
                          let arrString = arr.length === 0 ? "" : arr.join(",");
                          setFieldValue("meta_keyword", "");
                          setFieldValue("meta_keywords", arrString);
                        }
                      }}
                    />
                  </div>
                  {metaKeywordsArray.length > 0 && (
                    <div className="row mt-3">
                      <div className="w-auto d-flex flex-wrap gap-3">
                        {metaKeywordsArray?.map((el, index) => {
                          return (
                            <div
                              key={index}
                              className="w-auto d-flex align-items-center gap-2 shadow ps-2 pt-2 pe-2 pb-1 "
                            >
                              <span>{el}</span>
                              <span>
                                <i
                                  className="bi bi-trash-fill pointer"
                                  onClick={() => {
                                    let newArry = metaKeywordsArray.filter(
                                      (_, i) => i !== index
                                    );
                                    let arrString =
                                      newArry.length === 0
                                        ? ""
                                        : newArry.join(",");
                                    setFieldValue("meta_keywords", arrString);
                                  }}
                                />
                              </span>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="d-flex justify-content-center gap-5 mt-3">
                <Button
                  value="Cancel"
                  btnStyle="btn-primary-darker-outline"
                  className="cps-40 cpe-40 text-17-500"
                  onClick={handleReset}
                />

                <Button
                  value="Submit"
                  btnStyle="btn-dashboard-primary"
                  className="cps-40 cpe-40 text-17-500"
                  onClick={(e) => {
                    if (!btnLoading) {
                      submitForm(e);
                    }
                  }}
                  btnLoading={btnLoading}
                />
              </div>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};
export default StudyToolsForm;
