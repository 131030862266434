// import { decrypt, encrypt } from "./encryptdecrypt";
import { dialCode } from "utils/constants/dialcodes";

export function getHeaderData() {
  let header = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };
  if (getDataFromLocalStorage("token")) {
    header = {
      ...header,
      ...{ Authorization: `Bearer ${getDataFromLocalStorage("token")}` },
    };
  }
  return header;
}

export const storeLocalStorageData = (newData) => {
  // const decryptData = decrypt(localStorage.userData || {});
  // localStorage.userData = encrypt({ ...decryptData, ...newData });
  const decryptData = localStorage?.userData
    ? JSON.parse(localStorage?.userData)
    : {};
  localStorage.userData = JSON.stringify({ ...decryptData, ...newData });
};

export const getDataFromLocalStorage = (key = "") => {
  let returnValue = "";
  if (localStorage?.userData) {
    // const localObjectData = decrypt(localStorage?.userData);
    const localObjectData = JSON.parse(localStorage?.userData);
    if (key) {
      returnValue = localObjectData[key] ? localObjectData[key] : "";
    } else {
      returnValue = localObjectData;
    }
  }
  return returnValue;
};

export const titleCaseString = (value) => {
  if (typeof value !== "string") return "";
  return value.toLowerCase().replace(/\b(\w)/g, (s) => s.toUpperCase()); // Capital first character of each word
};

export const slugURL = (value) => {
  return value
    .toLowerCase()
    .replace(/ /g, "-")
    .replace(/[^\w-]+/g, "");
};

// export const slugURL = (value) => {
//   return value
//     .toLowerCase()
//     .split(/\s+/)
//     .filter(function (x) {
//       return x !== " ";
//     })
//     .join("-");
// };

export const searchKeyword = (value) => {
  let returnValue = "";
  if (value) {
    returnValue = value.split(" ")?.join(",");
  }

  return returnValue;
};

export const seoMetaData = (value) => {
  return value;
};

export const objectToFormData = (obj) => {
  let formData = new FormData();
  for (let key in obj) {
    formData.append(key, obj[key]);
  }
  return formData;
};
export const objectToQueryParams = (object) => {
  return new URLSearchParams(object).toString();
};

// Trim all left space
export const trimLeftSpace = (value) => value.replace(/^\s+/g, ""); // Remove white space from left side
export const trimAllSpace = (value) => value.replace(/ /g, "");

//Get dialCode From Country Code
export const getDialCode = (code) => {
  const returnValue = dialCode?.find((o) => o.code === code)?.dial_code || "";
  return returnValue;
};
