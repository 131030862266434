import "./CheckBox.scss";

const CheckBox = ({ label, isCheck, onChange, name, id, disabled }) => {
  return (
    <div id="check-box-container">
      <div className="form-check">
        <input
          className="form-check-input"
          type="checkbox"
          checked={isCheck}
          onChange={onChange}
          name={name}
          id={id}
          disabled={disabled}
        />
        {label && (
          <label className="form-check-label text-15-500">{label}</label>
        )}
      </div>
    </div>
  );
};
export default CheckBox;
