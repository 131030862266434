import StatusContainer from "components/layouts/StatusContainer/StatusContainer";
import Table from "components/layouts/Table";
import { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  deletePlan,
  getSubscriptionPlan,
  planChangeStaus,
  throwSuccess,
} from "store/globalSlice";
import { icons } from "utils/constants";
import SubscriptionDetailsPopup from "./SubscriptionDetailsPopup";
import DeleteConfirmPopup from "components/layouts/DeleteConfirmPopup/DeleteConfirmPopup";
import Button from "components/form/Button/Button";

const Subscriptions = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showAs, setShowAS] = useState(false);
  const [isData, setIsData] = useState(null);
  const [tableList, setTableList] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);
  const [isDeletePopup, setIsDeletePopup] = useState(false);
  const [planDeleteID, setPlanDeleteID] = useState({});

  // const handelDelete = async (id) => {
  //   const response = await dispatch(deletePlan(id));
  //   if (response?.status === 200) {
  //     fetchTableData();
  //   }
  // };
  const fetchTableData = async () => {
    const response = await dispatch(getSubscriptionPlan());
    setTableList(response?.data || []);
    setTableLoading(false);
  };

  useEffect(() => {
    fetchTableData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const header = [
    {
      title: "Name(For Stripe)",
    },
    {
      title: <div className="text-start ps-3">Category</div>,
    },
    {
      title: "Amount",
    },
    {
      title: "Status",
    },
    {
      title: "Action",
    },
  ];

  const rowData = [];
  tableList.forEach((elem) => {
    const countryType = elem?.country_type === "INR";

    const { name, id, category, amount, status } = elem;
    let obj = [
      {
        value: name,
      },
      {
        value: <div className="text-start ps-3">{category}</div>,
      },
      {
        value: `${countryType ? "₹" : "$"}${amount}`,
      },
      {
        value: (
          <StatusContainer
            title="Subscription"
            status={status}
            data={elem}
            apiFunction={async () => {
              return await dispatch(planChangeStaus(elem?.id));
            }}
            handelSuccess={() => {
              setTableList((prev) => {
                let oldVal = prev.map((el) => {
                  let newObj = el;
                  if (newObj?.id === elem?.id) {
                    newObj.status = elem?.status === 1 ? 0 : 1;
                  }
                  return newObj;
                });
                return oldVal;
              });
            }}
          />
        ),
      },
      {
        value: (
          <Dropdown align="end">
            <Dropdown.Toggle variant="" id="dropdown-basic">
              <img src={icons.threeDots} alt="options" className="pointer" />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item
                href=""
                className="d-flex align-items-center gap-2 mb-1"
                onClick={() => {
                  setShowAS(true);
                  setIsData(elem);
                }}
              >
                <span>
                  <img src={icons.viewDetails} alt="details" />
                </span>
                <span className="mt-1">View Details</span>
              </Dropdown.Item>

              <Dropdown.Item
                href=""
                className="d-flex align-items-center gap-2"
                onClick={() => {
                  navigate(`/subscription/edit/${id}`);
                  setTableLoading(tableLoading);
                }}
              >
                <span>
                  <img src={icons.edit} alt="trash" />
                </span>
                <span className="mt-1">Edit</span>
              </Dropdown.Item>

              <Dropdown.Item
                href=""
                className="d-flex align-items-center gap-2"
                onClick={() => {
                  setIsDeletePopup(true);
                  setPlanDeleteID(elem);
                }}
                // onClick={(e) => {
                //   e.preventDefault();
                //   handelDelete(id);
                // }}
              >
                <span>
                  <img src={icons.trash} alt="trash" />
                </span>
                <span className="mt-1">Delete</span>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        ),
      },
    ];
    rowData.push({ data: obj });
  });

  return (
    <div className="bg-white rounded pb-3">
      {showAs &&
        (!isData ? (
          ""
        ) : (
          <SubscriptionDetailsPopup
            isData={isData}
            onHide={() => {
              setShowAS(false);
            }}
          />
        ))}
      {isDeletePopup && (
        <DeleteConfirmPopup
          title="Subscription Plan"
          // deleteId={deleteId}
          onHide={() => {
            setIsDeletePopup(false);
          }}
          apiFunction={async () => {
            await dispatch(deletePlan(planDeleteID.id));
            dispatch(throwSuccess("Plan Delete Successfully."));
            setIsDeletePopup(false);
            fetchTableData();
            return;
          }}
          handelSuccess={() => {
            fetchTableData();
          }}
        />
      )}
      <div className="cps-24 cpt-24 cpe-24 mb-3 d-flex justify-content-between align-items-center">
        <div className="text-20-700 color-black-100">Subscriptions</div>
        <div className="d-flex justify-content-end mb-3">
          <Button
            value="+ Add Subscription"
            className="ps-3 pe-3 h-auto pt-2 pb-1"
            btnStyle="btn-primary-darker-outline"
            onClick={() => {
              navigate(`/subscription/add-subscription`);
            }}
          />
        </div>
      </div>
      <Table isLoading={tableLoading} header={header} rowData={rowData} />
    </div>
  );
};
export default Subscriptions;
