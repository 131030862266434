import LabelText from "../LabelText/LabelText";
import "./ReportSearchInput.scss";

const ReportSearchInput = ({
  placeholder,
  value,
  onChange,
  label,
  id,
  isClearable,
  onBlur,
}) => {
  return (
    <div id="report-search-input-container">
      <LabelText label={label} />
      <div className="r-search-block">
        <input
          type="text"
          id={id}
          onChange={onChange}
          placeholder={placeholder}
          value={value}
          onBlur={onBlur}
        />
        {isClearable && value && (
          <div
            className="right-icon-block"
            onClick={() => {
              onChange({
                target: {
                  id: id,
                  value: "",
                },
              });
            }}
          >
            <i className="bi bi-x" />
          </div>
        )}
      </div>
    </div>
  );
};
export default ReportSearchInput;
