import React, { useEffect, useRef, useState } from "react";
import { isEqual } from "lodash";
import * as Yup from "yup";
import { Formik } from "formik";
import { useDispatch } from "react-redux";
import Button from "components/form/Button";
import FileUpload from "components/form/FileUpload";
import PhoneNumber from "components/form/PhoneNumber";
import TextArea from "components/form/TextArea";
import TextInput from "components/form/TextInput";
import Loader from "components/layouts/Loader";
import PasswordInput from "components/form/PasswordInput";
// import RadioButton from "components/form/RadioButton";
// import { useParams } from "react-router-dom";
import { objectToFormData, trimLeftSpace } from "utils/helpers";
import {
  getSiteSetingDetails,
  throwError,
  throwSuccess,
  updateSiteSetingDetails,
} from "store/globalSlice";
// import TextEditor from "components/form/TextEditor/TextEditor";

const Setting = () => {
  // const params = useParams();

  // const { sType } = params;
  // const paramsObject = {
  //   "site-setting": "siteSetting",
  //   "smtp-setting": "smtpSetting",
  // };

  // let type = paramsObject[sType];

  const dispatch = useDispatch();
  const formRef = useRef();
  const [btnLoading, setBtnLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [siteData, setSiteData] = useState({});
  const [textEditorReset, setTextEditorReset] = useState(false);
  const {
    id,
    email,
    contact_mobile,
    facebook_link,
    linkedln_link,
    whatsUp_mobile,
    location,
    app_title,
    app_logo,
    app_logoName,
    fav_icon,
    fav_iconName,
    home_page_meta_title,
    meta_keywords,
    meta_keyword,
    meta_description,
    stripe_status,
    publishable_key,
    secret_key,
    stripe_recuring,
    signin_secret,
    statusRadio,
    header,
    footer,
  } = siteData || {};

  const fetchSiteSetingDetails = async () => {
    const response = await dispatch(getSiteSetingDetails());
    const siteDataObj = response?.data;
    setSiteData(siteDataObj);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchSiteSetingDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handelSave = async (values) => {
    setBtnLoading(true);
    const payload = objectToFormData(values);
    const response = await dispatch(updateSiteSetingDetails(id, payload));
    if (response?.status === 200) {
      dispatch(throwSuccess("Data Updated Successfully."));
      fetchSiteSetingDetails();
      if (formRef.current) {
        formRef.current.resetForm();
      }
    }
    setBtnLoading(false);
  };

  // const FILE_SIZE = 15 * 1024;

  const validationSchema = Yup.object().shape({
    app_logo: Yup.string().required("WebIcon must be a valid WebIcon"),
    adminEmail: Yup.string().matches(
      /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/,
      "Email must be a valid email"
    ),
    adminPassword: Yup.string()
      .matches(/^\S*$/, "Whitespace is not allowed.")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,16}$/,
        "Please set a strong password with (One Uppercase, One Lowercase, One Special Character, Minimum 6 Character Maximum 16 Character)"
      ),
    email: Yup.string()
      .required("Email is required.")
      .matches(/[a-z0-9]+@[a-z]+\.[a-z]{2,3}/, "Email must be a valid email"),
    contact_mobile: Yup.string().required("Mobile is required."),
    whatsUp_mobile: Yup.string().required("WhatsApp Contact is required."),
    facebook_link: Yup.string().required("Facebook Link is required."),
    linkedln_link: Yup.string().required("Linkedin Link is required."),
    location: Yup.string().required("Location is required."),
    app_title: Yup.string().required("App Title Title is required."),
    home_page_meta_title: Yup.string().required(
      "Homepage Meta Title is required."
    ),
    meta_keywords: Yup.string().required("Meta Keywords is required."),
    meta_description: Yup.string().required(
      "Homepage Meta Description is required."
    ),
    // header: Yup.string().required("Header Content is required."),
    // footer: Yup.string().required("Footer Content is required."),
  });

  const initialValues = {
    email: email || "",
    contact_mobile: contact_mobile || "",
    facebook_link: facebook_link || "",
    linkedln_link: linkedln_link || "",
    whatsUp_mobile: whatsUp_mobile || "",
    location: location || "",
    app_title: app_title || "",
    app_logo: app_logo || "",
    app_logoName: app_logoName || "",
    fav_icon: fav_icon || "",
    fav_iconName: fav_iconName || "",
    home_page_meta_title: home_page_meta_title || "",
    meta_keywords: meta_keywords || "",
    meta_keyword: meta_keyword || "",
    meta_description: meta_description || "",
    stripe_status: stripe_status || "",
    publishable_key: publishable_key || "",
    secret_key: secret_key || "",
    stripe_recuring: stripe_recuring || "",
    signin_secret: signin_secret || "",
    statusRadio: statusRadio || "0",
    country_code: "IN",
    header: header || "",
    footer: footer || "",
    adminEmail: "",
    adminPassword: "",
  };

  return (
    <div id="setting-container" className="bg-white rounded p-3">
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center pt-5 pb-5">
          <Loader size="md" />
        </div>
      ) : (
        <>
          <div className="text-20-700 color-black-100 cmb-20">Site Setting</div>

          <Formik
            enableReinitialize
            innerRef={formRef}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(e) => {
              handelSave(e);
            }}
          >
            {(props) => {
              const {
                values,
                errors,
                handleChange,
                setFieldValue,
                handleSubmit,
                handleReset,
                setFieldError,
              } = props;
              const { meta_keyword, meta_keywords } = values;

              /* console.log("fav_iconName", values?.fav_iconName);
              console.log("app_logoName", values?.app_logoName); */

              /* let keywordsArray = keywords ? keywords.split(",") : []; */

              let metaKeywordsArray = meta_keywords
                ? meta_keywords.split(",")
                : [];

              return (
                <form className="row">
                  <div className="col-md-6 cmb-22">
                    <TextInput
                      label="Admin Login Email"
                      placeholder="Enter email"
                      id="adminEmail"
                      value={values?.adminEmail}
                      onChange={handleChange}
                      error={errors.adminEmail}
                    />
                  </div>
                  <div className="col-md-6 cmb-22">
                    <PasswordInput
                      label="Admin Login Password"
                      placeholder="Enter password"
                      id="adminPassword"
                      value={values?.adminPassword}
                      onChange={handleChange}
                      error={errors.adminPassword}
                    />
                  </div>
                  <div className="col-md-6 cmb-22">
                    <TextInput
                      required
                      label="Contact Email"
                      placeholder="Enter Email"
                      id="email"
                      value={values?.email}
                      onChange={handleChange}
                      error={errors.email}
                    />
                  </div>

                  <div className="col-md-6 cmb-22">
                    <TextInput
                      required
                      label="Facebook"
                      placeholder="Facebook Link"
                      id="facebook_link"
                      value={values?.facebook_link}
                      onChange={handleChange}
                      error={errors.facebook_link}
                    />
                  </div>
                  <div className="col-md-6 cmb-22">
                    <TextInput
                      required
                      label="Linkedin"
                      placeholder="Linkedin Link"
                      id="linkedln_link"
                      onChange={handleChange}
                      value={values?.linkedln_link}
                      error={errors.linkedln_link}
                    />
                  </div>
                  <div className="col-md-6 cmb-22">
                    <PhoneNumber
                      required
                      id="whatsUp_mobile"
                      label="WhatsApp Contact"
                      placeholder="WhatsApp Contact"
                      value={values?.whatsUp_mobile}
                      error={errors.whatsUp_mobile}
                      country_code={values?.country_code}
                      onCodeChange={(e) => {
                        handleChange(e);
                        setFieldValue("whatsUp_mobile", "");
                      }}
                      onChange={handleChange}
                      onBlur={(isError) => {
                        let newerror = isError ? "Invalid mobile number" : "";
                        setFieldError("whatsUp_mobile", newerror);
                      }}
                    />
                  </div>
                  <div className="col-md-6 cmb-22">
                    <FileUpload
                      required
                      id="app_logo"
                      label="App Logo"
                      error={errors.app_logo}
                      fileText={values?.app_logoName || ""}
                      onChange={(e) => {
                        setFieldValue("app_logoName", e.target.fileName);
                        handleChange(e);
                      }}
                    />
                  </div>

                  <div className="col-md-6 cmb-22">
                    <FileUpload
                      required
                      id="fav_icon"
                      label="Fav Icon"
                      error={errors.fav_icon}
                      fileText={values?.fav_iconName}
                      onChange={(e) => {
                        setFieldValue("fav_iconName", e.target.fileName);
                        handleChange(e);
                      }}
                    />
                  </div>
                  <div className="col-md-6 cmb-22">
                    <TextInput
                      required
                      label="Homepage Meta Title"
                      placeholder="Enter Homepage Meta Title"
                      id="home_page_meta_title"
                      onChange={handleChange}
                      value={values?.home_page_meta_title}
                      error={errors.home_page_meta_title}
                    />
                  </div>
                  <div className="col-md-6 cmb-22">
                    <div className="row">
                      <div className="">
                        <TextInput
                          required
                          label="Home Page Meta Keywords"
                          placeholder="Enter Meta Keywords"
                          id="meta_keyword"
                          value={values?.meta_keyword}
                          error={errors.meta_keyword}
                          rightIcon={<i className="bi bi-plus-circle" />}
                          rightIconClick={() => {
                            if (trimLeftSpace(meta_keyword)) {
                              let arr = metaKeywordsArray;
                              arr.push(meta_keyword);
                              let arrString =
                                arr.length === 0 ? "" : arr.join(",");
                              setFieldValue("meta_keyword", "");
                              setFieldValue("meta_keywords", arrString);
                            }
                          }}
                          onChange={handleChange}
                          onKeyUp={(e) => {
                            if (
                              e?.keyCode === 13 &&
                              trimLeftSpace(e.target.value)
                            ) {
                              let arr = metaKeywordsArray;
                              arr.push(meta_keyword);

                              let arrString =
                                arr.length === 0 ? "" : arr.join(",");
                              setFieldValue("meta_keyword", "");
                              setFieldValue("meta_keywords", arrString);
                            }
                          }}
                          // error={errors.meta_keywords}
                        />
                      </div>
                    </div>

                    {metaKeywordsArray.length > 0 && (
                      <div className="row mt-3">
                        <div className="w-auto d-flex flex-wrap gap-3">
                          {metaKeywordsArray?.map((el, index) => {
                            return (
                              <div
                                key={index}
                                className="w-auto d-flex align-items-center gap-2 shadow ps-2 pt-2 pe-2 pb-1"
                              >
                                <span>{el}</span>
                                <span>
                                  <i
                                    className="bi bi-trash-fill pointer"
                                    onClick={() => {
                                      let newArry = metaKeywordsArray.filter(
                                        (_, i) => i !== index
                                      );
                                      let arrString =
                                        newArry.length === 0
                                          ? ""
                                          : newArry.join(",");
                                      setFieldValue("meta_keywords", arrString);
                                    }}
                                  />
                                </span>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="cmb-22">
                    <TextArea
                      isRequired
                      label="Homepage Meta Description"
                      placeholder="Enter Meta Description"
                      id="meta_description"
                      onChange={handleChange}
                      value={values?.meta_description}
                      error={errors.meta_description}
                    />
                  </div>

                  <div className="cmb-22">
                    {/* <TextEditor
                      label="Header Code - before </head>"
                      placeholder="Enter Code Here"
                      id="header"
                      isReset={textEditorReset}
                      value={values?.header}
                      error={errors.header}
                      onChange={handleChange}
                      isHideOption
                    /> */}
                    <TextArea
                      label="Header Code - before </head>"
                      placeholder="Enter Code Here"
                      id="header"
                      onChange={handleChange}
                      value={values?.header}
                      error={errors.header}
                    />
                  </div>

                  <div className="cmb-22">
                    {/* <TextEditor
                      label="Footer Code - before </body>"
                      placeholder="Enter Code Here"
                      id="footer"
                      isReset={textEditorReset}
                      value={values?.footer}
                      error={errors.footer}
                      onChange={handleChange}
                      isHideOption
                    /> */}
                    <TextArea
                      label="Footer Code - before </body>"
                      placeholder="Enter Code Here"
                      id="footer"
                      onChange={handleChange}
                      value={values?.footer}
                      error={errors.footer}
                    />
                  </div>

                  <div className="d-flex justify-content-center gap-5 mt-3">
                    <Button
                      value="Cancel"
                      btnStyle="btn-primary-darker-outline"
                      className="cps-40 cpe-40 text-17-500"
                      onClick={() => {
                        handleReset();
                        setTextEditorReset(!textEditorReset);
                        dispatch(
                          throwError({
                            message: "Your changes has been reset!",
                          })
                        );
                      }}
                    />
                    <Button
                      value="Submit"
                      btnStyle="btn-dashboard-primary"
                      className="cps-40 cpe-40 text-17-500"
                      onClick={handleSubmit}
                      btnLoading={btnLoading}
                      disabled={isEqual(values, initialValues)}
                    />
                  </div>
                  {/* </div> */}
                </form>
              );
            }}
          </Formik>
        </>
      )}
    </div>
  );
};

export default Setting;
