import React from "react";
import "./LabelText.scss";

const LabelText = ({ label, labelPrev, required }) => {
  return (
    <div id="label-text-container">
      <label className="label-text text-15-500 color-black-100 w-100 pb-1">
        {label}
        {required && <span style={{ color: "red" }}>*</span>}
      </label>
      <label>{labelPrev}</label>
    </div>
  );
};

export default LabelText;
