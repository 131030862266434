import logo from "assets/images/logo.png";
import category from "assets/images/category.png";
import people from "assets/images/people.png";
import products from "assets/images/products.png";
import promotion from "assets/images/promotion.png";
import gist from "assets/images/gist.png";
import admin from "assets/images/admin.png";
import bell from "assets/images/bell.png";
import profile from "assets/images/profile.png";
import logout from "assets/images/logout.png";
import Download from "assets/images/Download.png";
import Sort from "assets/images/Sort.png";
import close from "assets/images/close.png";
import camera from "assets/images/camera.png";
import lock from "assets/images/lock.png";
import noUser from "assets/images/noUser.png";
import addSquare from "assets/images/addSquare.png";
import back from "assets/images/back.png";
import cameraActive from "assets/images/cameraActive.png";
import setting from "assets/images/setting.png";
import closeSquare from "assets/images/closeSquare.png";
import threeDots from "assets/images/threeDots.png";
import userChat from "assets/images/userChat.png";
import viewDetails from "assets/images/viewDetails.png";
import trash from "assets/images/trash.png";
import archive from "assets/images/archive.png";
import search from "assets/images/search.png";
import graphIcon from "assets/images/graphIcon.png";
import menuIcon from "assets/images/menuIcon.png";
import addSquareBlack from "assets/images/addSquareBlack.png";
import archiveBlack from "assets/images/archiveBlack.png";
import downloadBlack from "assets/images/downloadBlack.png";
import arrowRight from "assets/images/arrowRight.png";
import firstBeautician from "assets/images/firstBeautician.png";
import secondBeautician from "assets/images/secondBeautician.png";
import thirdBeautician from "assets/images/thirdBeautician.png";
import fourthBeautician from "assets/images/fourthBeautician.png";
import fifthBeautician from "assets/images/fifthBeautician.png";
import unApproveB1 from "assets/images/unApproveB1.png";
import unApproveB2 from "assets/images/unApproveB2.png";
import downArrow from "assets/images/downArrow.png";
import edit from "assets/images/edit.png";
import contact from "assets/images/contact.png";
import subscription from "assets/images/subscription.svg";
import image1 from "assets/images/image1.jpg";
import image2 from "assets/images/image2.jpg";
import image3 from "assets/images/image3.jpg";

export const icons = {
  logo,
  category,
  people,
  products,
  promotion,
  gist,
  admin,
  bell,
  profile,
  logout,
  Download,
  Sort,
  close,
  camera,
  lock,
  noUser,
  addSquare,
  back,
  cameraActive,
  setting,
  closeSquare,
  threeDots,
  userChat,
  viewDetails,
  trash,
  archive,
  search,
  graphIcon,
  menuIcon,
  addSquareBlack,
  archiveBlack,
  downloadBlack,
  arrowRight,
  firstBeautician,
  downArrow,
  secondBeautician,
  thirdBeautician,
  fourthBeautician,
  fifthBeautician,
  unApproveB1,
  unApproveB2,
  edit,
  contact,
  subscription,
  image1,
  image2,
  image3,
};
export default icons;
