import DownloadButton from "components/layouts/DownloadButton";
import SortButton from "components/layouts/SortButton";
import { useState } from "react";
import { icons } from "utils/constants";
import CardList from "./CardList";
import CardPieChart from "./CardPieChart/CardPieChart";
import "./Dashboard.scss";

const Dashboard = () => {
  const tempArray = [
    {
      key: "Financial Summary",
      value: [
        {
          key: "Services Transactions:",
          total: "1000",
          sign: "$",
          title: "Total Services Transactions",

          value: [
            {
              key: "Total HST/GST:",
              value: "150",
              sign: "$",
              color: "#132C4E",
            },
            {
              key: "Total PST/QST:",
              value: "100",
              sign: "$",
              color: "#1571ED",
            },
            {
              key: "Total Platform fee earned:",
              value: "200",
              sign: "$",
              color: "#F2994A",
            },
            {
              key: "Total Platform fee HST/GST:",
              value: "20",
              sign: "$",
              color: "#9747FF",
            },
            {
              key: "Total Platform fee PST/QST:",
              value: "5",
              sign: "$",
              color: "#FFD059",
            },
            {
              key: "Total Subscription fee:",
              value: "80",
              sign: "$",
              color: "#411F00",
            },
          ],
        },
        {
          key: "Product Transactions",
          total: "1000",
          sign: "$",
          title: "Total Services Transactions:",
          value: [
            {
              key: "Total HST/GST:",
              value: "150",
              sign: "$",
              color: "#132C4E",
            },
            {
              key: "Total PST/QST:",
              value: "100",
              sign: "$",
              color: "#1571ED",
            },
            {
              key: "Total Platform fee earned:",
              value: "200",
              sign: "$",
              color: "#F2994A",
            },
            {
              key: "Total Platform fee HST/GST:",
              value: "20",
              sign: "$",
              color: "#9747FF",
            },
            {
              key: "Total Platform fee PST/QST:",
              value: "5",
              sign: "$",
              color: "#FFD059",
            },
            {
              key: "Total Subscription fee:",
              value: "80",
              sign: "$",
              color: "#411F00",
            },
            {
              key: "Shipping cost:",
              value: "55",
              sign: "$",
              color: "#FF481A",
            },
          ],
        },
        {
          key: "Brand Media Revenue",
          total: "1000",
          sign: "$",
          title: "Total Sales:",

          value: [
            {
              key: "Total HST/GST:",
              value: "150",
              sign: "$",
              color: "#FFD059",
            },
            {
              key: "Total PST/QST:",
              value: "100",
              sign: "$",
              color: "#1571ED",
            },
          ],
        },
      ],
    },
    {
      key: "Total Number of Businesses",
      value: [
        {
          key: "Total Businesses (Services)",
          total: "714",
          title: "Total Number of businesses:",
          value: [
            {
              key: "Haircare:",
              value: "150",
              color: "#1571ED",
            },
            {
              key: "Nail Services:",
              value: "50",
              color: "#718095",
            },
            {
              key: "Make-Up:",
              value: "80",
              color: "#FF481A",
            },
            {
              key: "Barbers:",
              value: "180",
              color: "#9747FF",
            },
            {
              key: "Facial/Skincare:",
              value: "40",
              color: "#411F00",
            },
            {
              key: "Massage:",
              value: "55",
              color: "#00A5D9",
            },
            {
              key: "SPA:",
              value: "35",
              color: "#219653",
            },
            {
              key: "Tattoo Art:",
              value: "27",
              color: "#1571ED",
            },
            {
              key: "Photography:",
              value: "15",
              color: "#F2994A",
            },
            {
              key: "Beauty Product Shop:",
              value: "42",
              color: "#FFD059",
            },
            {
              key: "Beauty Consultant:",
              value: "40",
              color: "#C018A5",
            },
          ],
        },
        {
          key: "Total Businesses (Products)",
          total: "714",
          title: "Total Number of businesses:",
          value: [
            {
              key: "Haircare:",
              value: "150",
              color: "#1571ED",
            },
            {
              key: "Nail Services:",
              value: "50",
              color: "#718095",
            },
            {
              key: "Make-Up:",
              value: "80",
              color: "#FF481A",
            },
            {
              key: "Barbers:",
              value: "180",
              color: "#9747FF",
            },
            {
              key: "Facial/Skincare:",
              value: "40",
              color: "#411F00",
            },
            {
              key: "Massage:",
              value: "55",
              color: "#00A5D9",
            },
            {
              key: "SPA:",
              value: "35",
              color: "#219653",
            },
            {
              key: "Tattoo Art:",
              value: "27",
              color: "#1571ED",
            },
            {
              key: "Photography:",
              value: "15",
              color: "#F2994A",
            },
            {
              key: "Beauty Product Shop:",
              value: "42",
              color: "#FFD059",
            },
            {
              key: "Beauty Consultant:",
              value: "40",
              color: "#C018A5",
            },
          ],
        },
        {
          key: "Total Businesses (Brands)",
          total: "1,035",
          title: "Total Number of businesses:",
          value: [
            {
              key: "Beauty:",
              value: "120",
              color: "#132C4E",
            },
            {
              key: "Lifestyle:",
              value: "100",
              color: "#1571ED",
            },
            {
              key: "Fashion:",
              value: "200",
              color: "#F2994A",
            },
            {
              key: "Cosmetics:",
              value: "180",
              color: "#9747FF",
            },
            {
              key: "Personal care:",
              value: "350",
              color: "#FFD059",
            },
            {
              key: "Home Care:",
              value: "85",
              color: "#411F00",
            },
          ],
        },
      ],
    },
    {
      key: "Total Number of Customers By:",
      value: [
        {
          key: "Gender",
          value: [
            {
              key: "Female:",
              value: "2000",
              color: "#F2994A",
            },
            {
              key: "Male:",
              value: "1200",
              color: "#132C4E",
            },
            {
              key: "Trangender:",
              value: "800",
              color: "#1571ED",
            },
            {
              key: "Others:",
              value: "250",
              color: "#FFD059",
            },
          ],
        },
        {
          key: "Demography",
          value: [
            {
              key: "Blacks:",
              value: "2000",
              color: "#F2994A",
            },
            {
              key: "Hispanic/Latino:",
              value: "1200",
              color: "#FFD059",
            },
            {
              key: "Asian:",
              value: "800",
              color: "#132C4E",
            },
            {
              key: "Indigenous (North America):",
              value: "250",
              color: "#1571ED",
            },
            {
              key: "White or Caucasian:",
              value: "240",
              color: "#9747FF",
            },
            {
              key: "Mixed Race:",
              value: "255",
              color: "#FF481A",
            },
            {
              key: "Others:",
              value: "280",
              color: "#411F00",
            },
          ],
        },
        {
          key: "Province",
          value: [
            {
              key: "British Columbia:",
              value: "2000",
              color: "#1571ED",
            },
            {
              key: "Alberta:",
              value: "1200",
              color: "#219653",
            },
            {
              key: "Saskatchewan:",
              value: "800",
              color: "#F2994A",
            },
            {
              key: "Manitoba:",
              value: "200",
              color: "#FFD059",
            },
            {
              key: "Ontario:",
              value: "180",
              color: "#411F00",
            },
            {
              key: "Quebec:",
              value: "300",
              color: "#9747FF",
            },
            {
              key: "New Brunswick:",
              value: "400",
              color: "#00A5D9",
            },
            {
              key: "Nova Scotia:",
              value: "450",
              color: "#718095",
            },
            {
              key: "Prince Edward Island:",
              value: "600",
              color: "#FF481A",
            },
            {
              key: "Newfoundland and Labrador:",
              value: "267",
              color: "#132C4E",
            },
          ],
        },
        {
          key: "Age Range",
          value: [
            {
              key: "Less than 18:",
              value: "150",
              color: "#FFD059",
            },
            {
              key: "18 - 25:",
              value: "2000",
              color: "#F2994A",
            },
            {
              key: "26 - 30:",
              value: "1500",
              color: "#132C4E",
            },
            {
              key: "31 - 40:",
              value: "1480",
              color: "#1571ED",
            },
            {
              key: "41 - 50:",
              value: "1280",
              color: "#411F00",
            },
            {
              key: "50+:",
              value: "1000",
              color: "#9747FF",
            },
          ],
        },
      ],
    },
  ];
  const [isCardList, setIsCardList] = useState(true);

  return (
    <div id="dashboard-container">
      <div className="cardlist-header d-flex gap-3 cps-24 cpt-26 cpb-25 cpe-24 cmb-24">
        <div className="d-flex">
          <button
            type="button"
            className="chart-button text-15-500 color-black-100 cps-18 cpt-10 cpb-10 cpe-16"
            onClick={() => {
              setIsCardList(!isCardList);
            }}
          >
            <img
              src={!isCardList ? icons.menuIcon : icons.graphIcon}
              alt="graph-icon"
              className="cpe-20"
            />
            {!isCardList ? `List View` : `Graph View`}
          </button>
        </div>
        <div className="d-flex gap-3 flex-wrap">
          <SortButton
            val="Nov 2022"
            icon={<img src={icons.Sort} alt="sort-icon" className="cps-12" />}
          />
          <DownloadButton
            icon={
              <img src={icons.Download} alt="download-icon" className="cpe-7" />
            }
            val="DOWNLOAD REPORT"
          />
        </div>
      </div>

      {isCardList ? (
        <CardList dashboardData={tempArray} />
      ) : (
        <CardPieChart dashboardData={tempArray} />
      )}
    </div>
  );
};
export default Dashboard;
