import React, { useEffect, useState } from "react";
import Button from "components/form/Button";
import { FileUploader } from "react-drag-drop-files";
import CheckBox from "components/form/CheckBox/CheckBox";
import CustomDropdown from "components/form/Dropdown";
import "./ImageGallery.scss";
import CardView from "./CardView";
import TableView from "./TableView";
import { useDispatch } from "react-redux";
import { omit } from "lodash";
import {
  getImages,
  imageUpload,
  removeImages,
  throwSuccess,
} from "store/globalSlice";
import Loader from "components/layouts/Loader";
// import { Dropdown } from "react-bootstrap";

const ImageGallery = () => {
  const dispatch = useDispatch();
  const [imageIds, setImageIds] = useState([]);
  const [btnLoading, setBtnLoading] = useState(false);
  const [tableData, setTableData] = useState({
    limit: 100,
    intervalLimit: 5,
    offset: 0,
    total: 0,
    loading: true,
    type: "",
    data: [],
  });

  const [type, setType] = useState(0);
  const fileTypes = ["JPEG", "JPG", "PNG", "PDF"];
  const handleChange = async (file) => {
    if (file) {
      let fd = new FormData();
      for (let i = 0; i < file.length; i++) {
        fd.append("FileList", file[i]);
      }
      const payload = fd;
      if (fileTypes === "JPEG" || "JPG" || "PNG") {
        const response = await dispatch(imageUpload(payload));
        if (response?.status === 200) {
          dispatch(throwSuccess("Upload Successfully"));
          fetchImages();
        }
      }
    }
  };

  const fetchImages = async (obj) => {
    setImageIds([]);
    let payload = omit(obj, ["data", "loading", "total", "intervalLimit"]);
    const response = await dispatch(getImages(payload));
    if (response?.status === 200) {
      setTableData((prev) => {
        return {
          ...prev,
          data: response?.data?.rows || [],
          total: response?.data?.count || 0,
          loading: false,
        };
      });
    }
    response?.data?.rows ||
      [].forEach((ele) => {
        ele.isChecked = false;
      });
  };

  const deleteImage = async () => {
    setBtnLoading(true);
    const payload = {
      filesData: imageIds,
    };
    const response = await dispatch(removeImages(payload));
    if (response?.status === 200) {
      fetchImages();
    }
    setBtnLoading(false);
  };

  const handleSelectAll = (e) => {
    if (tableData?.data?.length > 0) {
      if (imageIds.length === tableData?.data?.length) {
        setImageIds([]);
      } else {
        setImageIds(tableData?.data.map((li) => li.id));
      }
    } else {
      setImageIds([]);
    }
  };

  const handleFileType = (e) => {
    let oldData = tableData;
    oldData = { ...oldData, type: e.target.value };
    setTableData(oldData);
    fetchImages(oldData);
  };

  useEffect(() => {
    fetchImages(tableData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div id="image-gallery-container" className="bg-white rounded pb-3">
      {tableData?.loading ? (
        <div className="d-flex justify-content-center align-items-center cpt-135 cpb-135">
          <Loader size="md" />
        </div>
      ) : (
        <>
          <div className="text-20-700 color-black-100 cps-24 cpt-24 cpe-24 mb-3">
            Media Library
          </div>
          <div className="image-gallery-section row">
            <div className="image-upload mb-5">
              {/* <ImageUploading
            multiple
            value={images}
            onChange={onChange}
            maxNumber={maxNumber}
            dataURLKey="data_url"
            acceptType={["jpg"]}
          >
            {({
              imageList,
              onImageUpload,
              onImageRemoveAll,
              onImageUpdate,
              onImageRemove,
              isDragging,
              dragProps,
            }) => (
              // write your building UI
              <div className="upload__image-wrapper">
                <button
                  style={isDragging ? { color: "red" } : null}
                  onClick={onImageUpload}
                  {...dragProps}
                >
                  Click or Drop here
                </button>
                &nbsp;
                <button onClick={onImageRemoveAll}>Remove all images</button>
                {imageList.map((image, index) => (
                  <div key={index} className="image-item">
                    <img src={image.data_url} alt="" width="100" />
                    <div className="image-item__btn-wrapper">
                      <button onClick={() => onImageUpdate(index)}>
                        Update
                      </button>
                      <button onClick={() => onImageRemove(index)}>
                        Remove
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </ImageUploading> */}
              <FileUploader
                multiple={true}
                handleChange={handleChange}
                name="file"
                types={fileTypes}
              />
            </div>

            {/* <p>{file ? `File name: ${file[0].name}` : "no files uploaded yet"}</p>
        <p>{file ? `File name: ${file[1].name}` : "no files uploaded yet"}</p> */}

            <div className="mt-5">
              <div className="operations-block flex-wrap gap-3 d-flex justify-content-between mb-5 pb-4">
                <div className="d-flex align-items-center gap-3">
                  {tableData?.data?.length > 0 && (
                    <CheckBox
                      label="Select all"
                      id="selectAll"
                      name="isAllSelected"
                      isCheck={imageIds?.length === tableData?.data?.length}
                      onChange={handleSelectAll}
                    />
                  )}
                  {imageIds?.length > 0 && (
                    <Button
                      id="delete"
                      value="Delete"
                      btnStyle="btn-dashboard-primary"
                      className="cps-30 cpe-30 text-15-500"
                      onClick={() => {
                        deleteImage();
                      }}
                      btnLoading={btnLoading}
                    />
                  )}
                </div>
                <div className="d-flex gap-3 align-items-center ">
                  <div className="d-flex text-nowrap gap-2 align-items-center">
                    Sort by
                    <CustomDropdown
                      id="sortBy"
                      placeholder="Select Files"
                      onChange={handleFileType}
                      value={tableData?.type}
                      options={[
                        {
                          id: "",
                          label: "All",
                        },
                        {
                          id: "Images",
                          label: "Images",
                        },
                        {
                          id: "pdf",
                          label: "Pdf",
                        },
                      ]}
                    />
                  </div>
                  {/* <div className="d-flex gap-1 justify-content-end">
                    <div className="grid-icon-container">
                      <i
                        className={`bi ${
                          type === 0 ? "bi-list-ul" : "bi-grid"
                        } text-20-700 pointer`}
                        onClick={() => {
                          setType(type === 0 ? 1 : 0);
                        }}
                      />
                    </div>
                    
                  </div> */}
                </div>
              </div>
            </div>

            {type === 0 ? (
              <CardView
                tableData={tableData}
                imageIds={imageIds}
                setImageIds={setImageIds}
              />
            ) : (
              <TableView
                tableData={tableData}
                imageIds={imageIds}
                setImageIds={setImageIds}
              />
            )}
          </div>
        </>
      )}
      {/* {tableData?.data?.length > 0 && (
        <div className="pagination-section">
          <div className="d-flex align-items-center gap-3">
            <span>View</span>
            <span className="paginate-number-section pt-1 pb-1">
              <Dropdown align="bottom">
                <Dropdown align="bottom">
                  <Dropdown.Toggle variant="" id="dropdown-basic">
                    <span className="text-13-500">{tableData.limit}</span>
                    <span className="text-13-500">
                      <i className="bi bi-caret-down-fill pointer ms-2" />
                    </span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu
                    style={{
                      minWidth: "80px",
                    }}
                  >
                    <Dropdown.Item
                      href=""
                      className="d-flex align-items-center gap-2 mb-1"
                      onClick={(e) => {
                        e.preventDefault();
                        let oldData = tableData;
                        oldData = { ...oldData, offset: 0, limit: 5 };
                        setTableData(oldData);
                        fetchImages(oldData);
                      }}
                    >
                      <span className="mt-1">5</span>
                    </Dropdown.Item>
                    <Dropdown.Item
                      href=""
                      className="d-flex align-items-center gap-2 mb-1"
                      onClick={(e) => {
                        e.preventDefault();
                        let oldData = tableData;
                        oldData = { ...oldData, offset: 0, limit: 10 };
                        setTableData(oldData);
                        fetchImages(oldData);
                      }}
                    >
                      <span className="mt-1">10</span>
                    </Dropdown.Item>
                    <Dropdown.Item
                      href=""
                      className="d-flex align-items-center gap-2 mb-1"
                      onClick={(e) => {
                        e.preventDefault();
                        let oldData = tableData;
                        oldData = { ...oldData, offset: 0, limit: 15 };
                        setTableData(oldData);
                        fetchImages(oldData);
                      }}
                    >
                      <span className="mt-1">15</span>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Dropdown>
            </span>
          </div>
        </div>
      )} */}
    </div>
  );
};

export default ImageGallery;
