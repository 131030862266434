import Button from "components/form/Button";
import DatePicker from "components/form/DatePicker";
import Dropdown from "components/form/Dropdown";
import PhoneNumber from "components/form/PhoneNumber";
import ReportSearchInput from "components/form/ReportSearchInput";
import { Formik } from "formik";
import React from "react";
import { Modal } from "react-bootstrap";

const PaymentPopUp = ({ onHide, handlePayment, tableData, setTableData }) => {
  const {
    first_name,
    startDate,
    endDate,
    status,
    last_name,
    email,
    plan_type,
    mobile_no,
    countryCode,
  } = tableData || {};
  const initialValues = {
    plan_type: plan_type || "",
    first_name: first_name || "",
    last_name: last_name || "",
    status: status || "",
    email: email || "",
    mobile_no: mobile_no || "",
    startDate: startDate || "",
    endDate: endDate || "",
    countryCode: countryCode || "",
  };
  return (
    <Modal
      show
      aria-labelledby="contained-modal-title-vcenter"
      centered
      size="md"
      className="report-popup"
    >
      <Modal.Body>
        <div className="user-report-view-form-section p-2">
          <div className="">
            <Formik
              enableReinitialize
              initialValues={initialValues}
              onSubmit={(values) => {
                let oldData = {
                  ...tableData,
                  plan_type: values.plan_type,
                  first_name: values.first_name,
                  last_name: values.last_name,
                  status: values.status,
                  email: values.email,
                  mobile_no: values.mobile_no,
                  startDate: values.startDate,
                  endDate: values.endDate,
                  countryCode: values?.countryCode,
                  loading: true,
                  offset: 0,
                };
                setTableData(oldData);
                handlePayment(oldData);
                onHide();
              }}
            >
              {(props) => {
                const {
                  values,
                  handleChange,
                  handleSubmit,
                  setFieldValue,
                  setValues,
                } = props;

                return (
                  <form>
                    <div className="row">
                      <div className="d-flex justify-content-between">
                        <Button
                          value="Reset"
                          btnStyle="btn-primary-darker-outline"
                          className="cps-20 cpe-20 text-17-500"
                          onClick={() => {
                            setValues({
                              ...values,
                              startDate: "",
                              endDate: "",
                              status: "",
                              plan_type: "",
                              first_name: "",
                              last_name: "",
                              email: "",
                              mobile_no: "",
                              countryCode: "US",
                            });
                          }}
                        />
                        <div className="d-flex justify-content-end gap-3 mb-5">
                          <Button
                            value="Cancel"
                            btnStyle="btn-primary-darker-outline"
                            className="cps-20 cpe-20 text-17-500"
                            onClick={onHide}
                          />
                          <Button
                            value="Search"
                            btnStyle="btn-dashboard-primary"
                            className="cps-20 cpe-20 text-17-500"
                            onClick={handleSubmit}
                          />
                        </div>
                      </div>
                      <div className="col-md-6 cmb-22">
                        <DatePicker
                          isClearable
                          disabled={
                            values?.status === "0" || values?.status === "3"
                          }
                          label="FROM DATE"
                          placeholder="From Date"
                          id="startDate"
                          onChange={handleChange}
                          value={values?.startDate}
                        />
                      </div>
                      <div className="col-md-6 cmb-22">
                        <DatePicker
                          disabled={
                            values?.status === "0" || values?.status === "3"
                          }
                          label="TO DATE"
                          placeholder="To Date"
                          id="endDate"
                          onChange={handleChange}
                          value={values?.endDate}
                          isClearable
                        />
                      </div>
                      <div className="col-md-6 cmb-22">
                        <ReportSearchInput
                          isClearable
                          label="FIRST NAME"
                          id="first_name"
                          placeholder="First Name"
                          onChange={handleChange}
                          value={values?.first_name}
                        />
                      </div>
                      <div className="col-md-6 cmb-22">
                        <ReportSearchInput
                          isClearable
                          label="LASE NAME"
                          id="last_name"
                          placeholder="Last Name"
                          onChange={handleChange}
                          value={values?.last_name}
                        />
                      </div>
                      <div className="col-md-6 cmb-22">
                        <ReportSearchInput
                          isClearable
                          label="EMAIL"
                          id="email"
                          placeholder="Email"
                          onChange={handleChange}
                          value={values?.email}
                        />
                      </div>
                      <div className="col-md-6 cmb-22">
                        <PhoneNumber
                          isClearable
                          label="MOBILE NUMBER"
                          id="mobile_no"
                          placeholder="Mobile Number"
                          onChange={handleChange}
                          value={values?.mobile_no}
                          country_code={values?.countryCode}
                          onCodeChange={(e) => {
                            setFieldValue("countryCode", e?.target?.value);
                          }}
                        />
                      </div>

                      <div className="cmb-22">
                        <Dropdown
                          isClearable
                          id="status"
                          label="Subscription Type"
                          placeholder="Select Subscription Type"
                          onChange={(e) => {
                            if (
                              e.target.value === "0" ||
                              e.target.value === ""
                            ) {
                              setFieldValue("plan_type", "");
                            }
                            if (
                              e.target.value === "0" ||
                              e.target.value === "3"
                            ) {
                              setFieldValue("startDate", "");
                              setFieldValue("endDate", "");
                            }
                            handleChange(e);
                          }}
                          value={values?.status}
                          options={[
                            {
                              id: "1",
                              label: "Subscribed",
                            },
                            {
                              id: "0",
                              label: "Un-Subscribed",
                            },
                            {
                              id: "2",
                              label: "Canceled",
                            },
                            {
                              id: "3",
                              label: "Un-Successful",
                            },
                          ]}
                        />
                      </div>

                      {!!values.status && (
                        <>
                          {values?.status !== "0" && (
                            <div className="cmb-22">
                              <Dropdown
                                isClearable
                                id="plan_type"
                                label="Subscription Plan"
                                onChange={handleChange}
                                placeholder="Select Subscription Plan"
                                value={values?.plan_type}
                                options={[
                                  {
                                    id: "Monthly",
                                    label: "Monthly",
                                  },
                                  {
                                    id: "Quarterly",
                                    label: "Quarterly",
                                  },
                                  {
                                    id: "Yearly",
                                    label: "Yearly",
                                  },
                                ]}
                              />
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </form>
                );
              }}
            </Formik>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default PaymentPopUp;
