import Button from "components/form/Button";
import DatePicker from "components/form/DatePicker";
import PhoneNumber from "components/form/PhoneNumber";
import ReportSearchInput from "components/form/ReportSearchInput";
import { Formik } from "formik";
import React, { useRef } from "react";
import { Modal } from "react-bootstrap";
import { trimAllSpace } from "utils/helpers";

const IpFilterPopup = ({ onHide, tableData, setTableData, handelIp }) => {
  const formRef = useRef();
  const {
    startDate,
    endDate,
    Ip,
    first_name,
    last_name,
    mobile_no,
    email,
    countryCode,
  } = tableData || {};
  const initialValues = {
    startDate: startDate || "",
    endDate: endDate || "",
    first_name: first_name || "",
    last_name: last_name || "",
    mobile_no: mobile_no || "",
    email: email || "",
    Ip: Ip || "",
    countryCode: countryCode || "",
  };
  return (
    <Modal
      show
      aria-labelledby="contained-modal-title-vcenter"
      centered
      size="md"
      className="report-popup"
    >
      <Modal.Body>
        <div className="user-report-view-form-section p-2">
          <div className="">
            <Formik
              innerRef={formRef}
              enableReinitialize
              initialValues={initialValues}
              onSubmit={(values) => {
                let oldData = {
                  ...tableData,
                  startDate: values?.startDate,
                  endDate: values?.endDate,
                  first_name: values?.first_name,
                  last_name: values?.last_name,
                  mobile_no: values?.mobile_no,
                  email: values?.email,
                  Ip: values?.Ip,
                  countryCode: values?.countryCode,
                  loading: true,
                };
                setTableData(oldData);
                handelIp(oldData);
                if (formRef.current) {
                  formRef.current.resetForm();
                }
              }}
            >
              {(props) => {
                const {
                  values,
                  handleChange,
                  handleSubmit,
                  setValues,
                  setFieldValue,
                } = props;
                return (
                  <form>
                    <div className="row">
                      <div className="d-flex justify-content-between">
                        <Button
                          value="Reset"
                          btnStyle="btn-primary-darker-outline"
                          className="cps-40 cpe-40 text-17-500"
                          onClick={() => {
                            setValues({
                              ...values,
                              startDate: "",
                              endDate: "",
                              first_name: "",
                              last_name: "",
                              mobile_no: "",
                              email: "",
                              Ip: "",
                              countryCode: "US",
                            });
                          }}
                        />
                        <div className="d-flex justify-content-end gap-3 mb-5">
                          <Button
                            value="Cancel"
                            btnStyle="btn-primary-darker-outline"
                            className="cps-20 cpe-20 text-17-500"
                            onClick={onHide}
                          />
                          <Button
                            value="Search"
                            btnStyle="btn-dashboard-primary"
                            className="cps-20 cpe-20 text-17-500"
                            onClick={handleSubmit}
                          />
                        </div>
                      </div>
                      <div className="col-md-6 cmb-22">
                        <DatePicker
                          label="FROM DATE"
                          placeholder="From Date"
                          id="startDate"
                          onChange={handleChange}
                          value={values?.startDate}
                          isClearable
                        />
                      </div>
                      <div className="col-md-6 cmb-22">
                        <DatePicker
                          label="TO DATE"
                          placeholder="To Date"
                          id="endDate"
                          onChange={handleChange}
                          value={values?.endDate}
                          isClearable
                        />
                      </div>
                      <div className="col-md-6 cmb-22">
                        <ReportSearchInput
                          placeholder="First Name"
                          label="FIRST NAME"
                          id="first_name"
                          onChange={handleChange}
                          value={values?.first_name}
                          isClearable
                        />
                      </div>
                      <div className="col-md-6 cmb-22">
                        <ReportSearchInput
                          placeholder="Last Name"
                          label="LAST NAME"
                          id="last_name"
                          onChange={handleChange}
                          value={values?.last_name}
                          isClearable
                        />
                      </div>
                      <div className="col-md-6 cmb-22">
                        <PhoneNumber
                          isClearable
                          label="MOBILE NO"
                          id="mobile_no"
                          placeholder="Mobile Number"
                          onChange={handleChange}
                          value={values?.mobile_no}
                          country_code={values?.countryCode}
                          onCodeChange={(e) => {
                            setFieldValue("countryCode", e?.target?.value);
                          }}
                        />
                      </div>
                      <div className="col-md-6 cmb-22">
                        <ReportSearchInput
                          placeholder="Email"
                          label="EMAIL"
                          id="email"
                          onChange={(e) => {
                            setFieldValue(
                              "email",
                              trimAllSpace(e.target.value)
                            );
                          }}
                          value={values?.email}
                          isClearable
                        />
                      </div>
                      <div>
                        <ReportSearchInput
                          placeholder="IP Address"
                          label="IP ADDRESS"
                          id="Ip"
                          onChange={handleChange}
                          value={values?.Ip}
                          isClearable
                        />
                      </div>
                    </div>
                  </form>
                );
              }}
            </Formik>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default IpFilterPopup;
