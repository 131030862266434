import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import Button from "components/form/Button";
import PasswordInput from "components/form/PasswordInput";
import TextInput from "components/form/TextInput";
import { icons } from "utils/constants";
import { login } from "store/globalSlice";
import "./Login.scss";

const Login = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const [btnLoading, setBtnLoading] = useState(false);
  const handelSave = async (values) => {
    setBtnLoading(true);
    await dispatch(login(values));

    setBtnLoading(false);
  };
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Email must be a valid email")
      .required("Email is required."),
    password: Yup.string().required("Password is required."),
  });
  const initialValues = {
    email: "",
    password: "",
  };

  return (
    <div id="login-continer">
      <div className="login-block">
        <div className="login-heading-block bg-dashboard-primary cmb-24 cpt-20 cpb-10">
          <div className="logo-block pb-3">
            <img
              src={icons.logo}
              alt="sliike-logo"
              style={{ height: "30px" }}
            />
          </div>
          <div className="logo-text text-17-500 color-blue-10">
            Welcome to HAC admin portal
          </div>
        </div>
        <div className="login-content-block cpt-40 cpb-40 cps-32 cpe-32">
          <div className="login-content-block-heading cmb-24">
            <div className="text-17-600  color-black-100">SIGN IN</div>
            <div className="text-15-500 color-black-80">
              Sign in to manage your account.
            </div>
          </div>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handelSave}
          >
            {(props) => {
              const { values, errors, handleChange, submitForm } = props;
              const { email, password } = values;
              return (
                <form
                  onSubmit={submitForm}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      submitForm(e);
                    }
                  }}
                >
                  <div className="cmb-24">
                    <TextInput
                      label="Email Address"
                      placeholder="Enter you email address here"
                      id="email"
                      value={email}
                      onChange={handleChange}
                      error={errors.email}
                    />
                  </div>

                  <div className="cmb-16">
                    <PasswordInput
                      label="Password"
                      placeholder="Enter password"
                      id="password"
                      value={password}
                      onChange={handleChange}
                      error={errors.password}
                    />
                  </div>

                  <div className="forgot-text cmb-24">
                    {/* <span className="text-16-500 color-blue pointer">
                      Forgot Password?
                    </span> */}
                  </div>

                  <div>
                    <Button
                      value={params?.secreat ? "CONTINUE" : "SIGN IN"}
                      btnStyle={
                        email && password
                          ? "btn-dashboard-primary"
                          : "btn-primary"
                      }
                      onClick={submitForm}
                      btnLoading={btnLoading}
                    />
                  </div>
                </form>
              );
            }}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default Login;
