import Button from "components/form/Button";
import React from "react";
import { Modal } from "react-bootstrap";

const SubscriptionDetailsPopup = ({ onHide, isData }) => {
  const countryType = isData?.country_type === "INR";

  return (
    <Modal show aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Body>
        <Modal.Title className="text-17-700 d-flex justify-content-center cmb-22">
          Subscription Details
        </Modal.Title>

        <div className="d-flex gap-2 cmb-10">
          <span className="color-dashboard-primary text-16-600">
            Plan Name:
          </span>
          <span>{isData?.name}</span>
        </div>

        <div className="d-flex gap-2 cmb-10">
          <span className="color-dashboard-primary text-16-600">
            Subscription Plan:
          </span>
          <span>{isData?.category}</span>
        </div>
        <div className="d-flex gap-2 cmb-10">
          <span className="color-dashboard-primary text-16-600">
            Subscription Amount:
          </span>
          <span>
            {countryType ? "₹" : "$"}
            {isData?.amount}
          </span>
        </div>
        <div className="cmb-10">
          <div className="color-dashboard-primary text-16-600 cmb-10">
            Subscription Description:
          </div>
          <div
            className="text-16-500 ps-2"
            dangerouslySetInnerHTML={{
              __html: isData?.description,
            }}
          ></div>
        </div>
        <div className="d-flex gap-2 cmb-22">
          <span className="color-dashboard-primary text-16-600">Status:</span>

          <span>{isData?.status === 1 ? "Active" : "InActive"}</span>
        </div>
        <div className="d-flex justify-content-center gap-2">
          <Button
            className="text-13-600 h-auto cps-18 cpe-18 cpt-5 cpb-5 color-dahsboard-primary text-16-600"
            btnStyle="btn-dashboard-primary"
            value="Ok"
            onClick={onHide}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SubscriptionDetailsPopup;
