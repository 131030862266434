import Button from "components/form/Button/Button";
import DatePicker from "components/form/DatePicker/DatePicker";
import ReportSearchInput from "components/form/ReportSearchInput/ReportSearchInput";
import { Formik } from "formik";
import moment from "moment";
import React from "react";
import { Modal } from "react-bootstrap";
import { isEqual } from "lodash";
import PhoneNumber from "components/form/PhoneNumber";
import { trimAllSpace } from "utils/helpers";

const UserFilterPopup = ({ onHide, tableData, handelFilter }) => {
  const handelSave = (values) => {
    handelFilter(values);
  };
  const initialValues = tableData;
  return (
    <Modal
      show
      aria-labelledby="contained-modal-title-vcenter"
      centered
      size="fullscreen"
      className="report-popup"
    >
      <Modal.Body>
        <div className="user-report-view-form-section p-5">
          <div className="">
            <Formik
              enableReinitialize
              initialValues={initialValues}
              onSubmit={handelSave}
              // onReset={handelSave}
            >
              {(props) => {
                const {
                  values,
                  handleChange,
                  handleSubmit,
                  setFieldValue,
                  setValues,
                } = props;

                return (
                  <form>
                    <div className="row form-block">
                      <div className="d-flex justify-content-between">
                        <Button
                          value="Reset"
                          btnStyle="btn-primary-darker-outline"
                          className="cps-40 cpe-40 text-17-500"
                          onClick={() => {
                            setValues({
                              ...values,
                              startDate: "",
                              endDate: "",
                              first_name: "",
                              last_name: "",
                              mobile_no: "",
                              DOB: "",
                              college_name: "",
                              college_location: "",
                              state: "",
                              country: "",
                              city: "",
                              zip: "",
                              basic_education: "",
                              countryCode: "US",
                            });
                          }}
                        />
                        <div className="d-flex justify-content-end gap-3 mb-5">
                          <Button
                            value="Cancel"
                            btnStyle="btn-primary-darker-outline"
                            className="cps-40 cpe-40 text-17-500"
                            onClick={onHide}
                          />
                          <Button
                            value="Search"
                            btnStyle="btn-dashboard-primary"
                            className="cps-40 cpe-40 text-17-500"
                            onClick={handleSubmit}
                            disabled={isEqual(values, initialValues)}
                          />
                        </div>
                      </div>

                      <div className="col-md-3 cmb-22">
                        <DatePicker
                          isClearable
                          placeholder="From Date"
                          label="FROM DATE"
                          id="startDate"
                          onChange={handleChange}
                          value={values?.startDate}
                        />
                      </div>
                      <div className="col-md-3 cmb-22">
                        <DatePicker
                          minDate={values?.startDate}
                          isClearable
                          placeholder="To Date"
                          label="TO DATE"
                          id="endDate"
                          onChange={handleChange}
                          value={values?.endDate}
                        />
                      </div>
                      <div className="col-md-3" />
                      <div className="col-md-3" />

                      <div className="col-md-3 cmb-22">
                        <ReportSearchInput
                          isClearable
                          placeholder="First Name"
                          label="FIRST NAME"
                          id="first_name"
                          onChange={handleChange}
                          value={values?.first_name}
                        />
                      </div>
                      <div className="col-md-3 cmb-22">
                        <ReportSearchInput
                          isClearable
                          placeholder="Last Name"
                          label="LAST NAME"
                          id="last_name"
                          onChange={handleChange}
                          value={values?.last_name}
                        />
                      </div>

                      <div className="col-md-3 cmb-22">
                        <PhoneNumber
                          isClearable
                          label="MOBILE NO"
                          id="mobile_no"
                          placeholder="Mobile Number"
                          onChange={handleChange}
                          value={values?.mobile_no}
                          country_code={values?.countryCode}
                          onCodeChange={(e) => {
                            setFieldValue("countryCode", e?.target?.value);
                          }}
                        />
                      </div>
                      <div className="col-md-3 cmb-22">
                        <ReportSearchInput
                          isClearable
                          placeholder="Email"
                          label="EMAIL"
                          id="email"
                          onChange={(e) => {
                            setFieldValue(
                              "email",
                              trimAllSpace(e.target.value)
                            );
                          }}
                          value={values?.email}
                        />
                      </div>
                      <div className="col-md-3 cmb-22">
                        <DatePicker
                          isClearable
                          placeholder="Date Of Birth"
                          label="DOB"
                          id="DOB"
                          onChange={handleChange}
                          value={values?.DOB}
                          maxDate={moment()}
                        />
                      </div>
                      <div className="col-md-5 cmb-22">
                        <ReportSearchInput
                          isClearable
                          placeholder="College Name"
                          label="COLLEGE NAME"
                          // optionKey="college_name"
                          id="college_name"
                          onChange={handleChange}
                          value={values?.college_name}
                        />
                      </div>
                      <div className="col-md-4 cmb-22">
                        <ReportSearchInput
                          isClearable
                          placeholder="College Location"
                          label="COLLEGE LOCATION"
                          id="college_location"
                          onChange={handleChange}
                          value={values?.college_location}
                        />
                      </div>

                      <div className="col-md-3 cmb-22">
                        <ReportSearchInput
                          isClearable
                          placeholder="Country"
                          label="COUNTRY"
                          // optionValue="country"
                          id="country"
                          value={values?.country}
                          onChange={handleChange}
                        />
                      </div>

                      <div className="col-md-3 cmb-22">
                        <ReportSearchInput
                          isClearable
                          placeholder="State"
                          label="STATE"
                          id="state"
                          // optionValue="state"
                          value={values?.state}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-md-3 cmb-22">
                        <ReportSearchInput
                          isClearable
                          placeholder="City"
                          label="CITY"
                          // optionValue="city"
                          id="city"
                          onChange={handleChange}
                          value={values?.city}
                        />
                      </div>

                      <div className="col-md-3 cmb-22">
                        <ReportSearchInput
                          isClearable
                          placeholder="Zip"
                          label="ZIP"
                          id="zip"
                          onChange={handleChange}
                          value={values?.zip}
                        />
                      </div>

                      <div className="col-md-12 cmb-22">
                        <ReportSearchInput
                          isClearable
                          placeholder="Basic Education"
                          label="BASIC EDUCATION & AREAS OF INTEREST"
                          id="basic_education"
                          onChange={handleChange}
                          value={values?.basic_education}
                        />
                      </div>
                      {/* <div className="col-md-2 cmb-22">
                        <Dropdown
                          id="status"
                          label="STATUS"
                          placeholder="Select Status"
                          value={values?.status}
                          onChange={handleChange}
                          options={[
                            {
                              id: "1",
                              label: "Active",
                            },
                            {
                              id: "0",
                              label: "Inactive",
                            },
                          ]}
                        />
                      </div> */}
                      {/* <div className="col-md-3 cmb-22">
                        <ReportSearchInput
                        isClearable
                          label="IP ADDRESS"
                          id="Ip"
                          value={values?.Ip}
                          onChange={handleChange}
                        />
                      </div> */}

                      {/* <div className="d-flex justify-content-center gap-5 mt-3">
                        <Button
                          value="Cancel"
                          btnStyle="btn-primary-darker-outline"
                          className="cps-40 cpe-40 text-17-500"
                          onClick={onHide}
                        />
                        <Button
                          value="Search"
                          btnStyle="btn-dashboard-primary"
                          className="cps-40 cpe-40 text-17-500"
                        />
                      </div> */}
                    </div>
                  </form>
                );
              }}
            </Formik>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default UserFilterPopup;
