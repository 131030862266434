import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { commonRoute } from "utils/constants";
import Dashboard from "pages/Dashboard";
import Layout from "pages/Layout";
// import UserProfile from "pages/UserProfile";
// import AddAdmin from "pages/UserProfile/AddAdmin/AddAdmin";
// import Admins from "pages/Admins";
// import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
// import Beauticians from "pages/Beauticians/Beauticians";
// import ApprovalPage from "pages/Beauticians/ApprovalPage";
import StudyTools from "pages/StudyTools";
import StudyToolsForm from "pages/StudyTools/StudyTools";
// import FlashCards from "pages/StudyTools/FlashCards";
import Users from "pages/Users";
import Subscriptions from "pages/Subscriptions";
import SubscriptionForm from "pages/Subscriptions/SubscriptionForm";
import UserDetails from "pages/Users/UserDetails";
// import SiteSetting from "pages/SiteSetting";
import PaymentHistory from "pages/PaymentHistory";
import ContactUs from "pages/ContactUs";
import UserEdit from "pages/Users/UserEdit";
import ViewDetails from "pages/StudyTools/ViewDetails";
import StudyToolsEdit from "pages/StudyTools/StudyToolsEdit";
import SubscriptionEdit from "pages/Subscriptions/SubscriptionEdit";
// import Setting from "pages/Settings/Settings";
import Settings from "pages/Settings";
import SMTPSetting from "pages/Settings/SMTPSetting";
import ImageGallery from "pages/ImageGallery/ImageGallery";
import Report from "pages/Report/Report";

const routeArray = [
  {
    layoutId: "dashboard-container",
    path: commonRoute.dashboard,
    component: <Dashboard />,
  },
  {
    layoutId: "users-container",
    path: commonRoute.users,
    component: <Users />,
  },
  {
    layoutId: "user-edit-container",
    path: commonRoute.usersEdit,
    component: <UserEdit />,
    isBack: true,
  },
  {
    layoutId: "user-view-container",
    path: commonRoute.usersDetails,
    component: <UserDetails />,
    isBack: true,
  },
  {
    layoutId: "study-tool-container",
    path: commonRoute.studyToolsType,
    component: <StudyTools />,
  },
  {
    layoutId: "question-details-container",
    path: commonRoute.studyQuesView,
    component: <ViewDetails />,
    isBack: true,
  },
  {
    layoutId: "question-edit-container",
    path: commonRoute.studyToolsEdit,
    component: <StudyToolsEdit />,
    isBack: true,
  },
  {
    layoutId: "study-tool-form-container",
    path: commonRoute.studyToolsQuetion,
    component: <StudyToolsForm />,
    isBack: true,
  },
  {
    layoutId: "image-gallery-container",
    path: commonRoute.imageGallery,
    component: <ImageGallery />,
    isBack: true,
  },
  {
    layoutId: "subscriptions-container",
    path: commonRoute.subscription,
    component: <Subscriptions />,
  },
  {
    layoutId: "subscription-form-container",
    path: commonRoute.subscriptionForm,
    component: <SubscriptionForm />,
    isBack: true,
  },
  {
    layoutId: "subscription-edit-container",
    path: commonRoute.subscriptionEdit,
    component: <SubscriptionEdit />,
    isBack: true,
  },
  {
    path: commonRoute.studyTools,
    to: `${commonRoute.studyTools}/ask-questions`,
    isNavigate: true,
  },
  {
    layoutId: "payment-history-container",
    path: commonRoute.paymentHistory,
    component: <PaymentHistory />,
  },
  {
    layoutId: "payment-history-container",
    path: `${commonRoute.paymentHistory}/user-details/:id`,
    component: <UserDetails />,
    isBack: true,
  },
  {
    layoutId: "contact-us-container",
    path: commonRoute.contactUs,
    component: <ContactUs />,
  },
  {
    layoutId: "setting-container",
    path: commonRoute.settingsType,
    component: <Settings />,
  },

  // {
  //   layoutId: "site-setting-container",
  //   path: commonRoute.siteSetting,
  //   component: <SiteSetting />,
  // },
  {
    path: commonRoute.settings,
    to: `${commonRoute.settings}/site-setting`,
    isNavigate: true,
  },
  {
    layoutId: "smtp-container",
    path: commonRoute.settingsTypeSetting,
    component: <SMTPSetting />,
  },

  //old report
  // {
  //   layoutId: "reports-container",
  //   path: commonRoute.reportsType,
  //   component: <UserReport />,
  // },

  // {
  //   path: commonRoute.reports,
  //   to: `${commonRoute.reports}/user-report`,
  //   isNavigate: true,
  // },
  // {
  //   layoutId: "subscription-report-container",
  //   path: commonRoute.reportsTypeSubcription,
  //   component: <SubscriptionReport />,
  // },

  //updated report
  {
    layoutId: "reports-details",
    path: commonRoute.reportsType,
    component: <Report />,
  },
  {
    path: commonRoute.reports,
    to: `${commonRoute.reports}/user-reports`,
    isNavigate: true,
  },
  // {
  //   layoutId: "approval-beauticians-container",
  //   path: commonRoute.approvalPage,
  //   component: <ApprovalPage />,
  //   isBack: true,
  // },
  // {
  //   layoutId: "flash-card-container",
  //   path: commonRoute.studyToolCategories,
  //   component: <FlashCards />,
  // },
  // {
  //   layoutId: "brands-container",
  //   path: commonRoute.brands,
  //   component: <Beauticians />,
  // },
  // {
  //   layoutId: "services-container",
  //   path: commonRoute.services,
  //   component: "services",
  // },
  // {
  //   layoutId: "products-container",
  //   path: commonRoute.products,
  //   component: "products",
  // },
  // {
  //   layoutId: "promotions-container",
  //   path: commonRoute.promotions,
  //   component: "promotions",
  // },
  // {
  //   layoutId: "gist-container",
  //   path: commonRoute.gist,
  //   component: "gist",
  // },
  // {
  //   layoutId: "admins-container",
  //   path: commonRoute.admins,
  //   component: <Admins />,
  // },
  // {
  //   layoutId: "my-profile-container",
  //   path: commonRoute.myProfile,
  //   component: <UserProfile />,
  //   isBack: true,
  // },
  // {
  //   layoutId: "add-admin-container",
  //   path: commonRoute.addAdmin,
  //   component: <AddAdmin />,
  //   isBack: true,
  // },
  {
    path: "*",
    to: commonRoute.users,
    isNavigate: true,
  },
];

const appRoute = () => {
  return (
    <BrowserRouter>
      <Routes>
        {routeArray.map((elem, index) => {
          const { layoutId, isBack, isNavigate, path, to } = elem;
          return (
            <Route
              exact
              key={index}
              path={path}
              element={
                isNavigate ? (
                  <Navigate to={to} replace />
                ) : (
                  <Layout layoutId={layoutId || ""} isBack={isBack}>
                    {elem.component}
                  </Layout>
                )
              }
            />
          );
        })}
      </Routes>
    </BrowserRouter>
  );
};
export default appRoute;
