import Profile from "components/layouts/Profile";
import Table from "components/layouts/Table";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { omit } from "lodash";
import { getAllOrdersDetail } from "store/globalSlice";
import { titleCaseString } from "utils/helpers";
import "./PaymentHistory.scss";
import Button from "components/form/Button/Button";
import PaymentPopUp from "./PaymentPopup";

const PaymentHistory = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [tableData, setTableData] = useState({
    offset: 0,
    limit: 10,
    intervalLimit: 10,
    total: 0,
    loading: true,
    data: [],
    plan_type: "",
    first_name: "",
    last_name: "",
    status: "",
    email: "",
    mobile_no: "",
    countryCode: "US",
    startDate: "",
    endDate: "",
  });
  const [showPopup, setShowPopup] = useState(false);

  const fetchOrderData = async (obj) => {
    const payload = omit(obj, ["data", "loading", "total", "intervalLimit"]);
    const response = await dispatch(getAllOrdersDetail(payload));
    setTableData((prev) => {
      return {
        ...prev,
        data: response?.data?.rows || [],
        total: response?.data?.count || 0,
        loading: false,
      };
    });
    // setTableOrderData(response?.data.rows || []);
    // setTableDataLoading(false);
  };

  useEffect(() => {
    fetchOrderData(tableData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const header = [
    {
      title: <div className="text-start ps-3">Name</div>,
    },
    {
      title: "Order No.",
    },
    {
      title: "Subscription Date",
    },
    {
      title: "Subscription Plan",
    },
    {
      title: "Subscription Amount",
    },
    {
      title: "Status",
    },
  ];
  const rowData = [];
  tableData?.data.forEach((elem) => {
    const countryType = elem?.currency === "INR";
    const {
      UserId,
      User,
      id,
      start_date,
      subscriptionPlan,
      subscription_amount,
      status,
    } = elem;

    let obj = [
      {
        value: (
          <div
            className="text-start ps-3 d-flex align-items-center gap-2 pointer"
            onClick={() => {
              navigate(`/payment-history/user-details/${UserId}`);
            }}
          >
            <div>
              <Profile
                isRounded
                size="s-34"
                text={`${User?.first_name} ${User?.last_name}`}
              />
            </div>
            <div>
              <div>
                {User?.first_name && User?.last_name
                  ? titleCaseString(`${User?.first_name} ${User?.last_name}`)
                  : ""}
              </div>
            </div>
          </div>
        ),
      },
      {
        value: id,
      },
      {
        value: start_date ? moment(start_date).format("DD-MMM-YYYY") : "",
      },
      {
        value: subscriptionPlan,
      },
      {
        value: `${countryType ? "₹" : "$"}${subscription_amount}`,
      },
      {
        value:
          status === 0 ? (
            <span className="badge  text-bg-secondary">Unsubscribed</span>
          ) : status === 1 ? (
            <span className="badge  text-bg-success">Subscribed</span>
          ) : status === 2 ? (
            <span className="badge  text-bg-danger">Canceled</span>
          ) : (
            <span className="badge  text-bg-warning">Unsuccessful</span>
          ),
      },
    ];

    rowData.push({ data: obj });
  });
  return (
    <>
      {showPopup && (
        <PaymentPopUp
          tableData={tableData}
          setTableData={setTableData}
          handlePayment={fetchOrderData}
          onHide={() => {
            setShowPopup(false);
          }}
        />
      )}
      <div id="payment-history-container" className="bg-white rounded pb-3">
        <div className="d-flex ms-1 gap-3 text-20-700 ps-2 justify-content-between mb-5 p-3">
          Payment History
          <div className="d-flex gap-4">
            <Button
              value="Filter"
              btnStyle="btn-dashboard-primary"
              className="cps-30 cpe-30 pt-2 pb-2 text-15-500 h-auto"
              onClick={() => {
                setShowPopup(true);
              }}
            />
          </div>
        </div>
        <Table
          isLoading={tableData?.loading}
          header={header}
          rowData={rowData}
          tableData={tableData}
          changeOffset={(newOffset, newLimit = tableData.limit) => {
            let oldData = {
              ...tableData,
              offset: newOffset,
              limit: newLimit,
              loading: true,
            };
            setTableData(oldData);
            fetchOrderData(oldData);
          }}
          isPagination
        />
      </div>
    </>
  );
};

export default PaymentHistory;
