import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setIsSidebar } from "store/globalSlice";
import { commonRoute, icons } from "utils/constants";
import { Nav } from "react-bootstrap";
import "./Sidebar.scss";

const Sidebar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const leftMenu = [
    {
      id: 1,
      title: "Users",
      icon: icons.people,
      url: "/users",
      redirect: commonRoute.users,
      hrefMenu: commonRoute.users,
    },
    {
      id: 2,
      title: "Quiz Solutions",
      icon: icons.promotion,
      url: "/quiz-solutions",
      redirect: commonRoute.studyTools,
      hrefMenu: commonRoute.studyTools,
      subMenu: [
        {
          id: 1,
          title: "Ask Questions",
          url: "/ask-questions",
          redirect: `${commonRoute.studyTools}/ask-questions`,
          href: `${commonRoute.studyTools}/ask-questions`,
        },
        {
          id: 1,
          title: "Expert Solutions",
          url: "/expert-solutions",
          redirect: `${commonRoute.studyTools}/expert-solutions`,
          href: `${commonRoute.studyTools}/expert-solutions`,
        },
        {
          id: 1,
          title: "Flash Cards",
          url: "/flash-cards",
          redirect: `${commonRoute.studyTools}/flash-cards`,
          href: `${commonRoute.studyTools}/flash-cards`,
        },
      ],
    },
    {
      id: 3,
      title: "Media Library",
      icon: icons.category,
      url: "/media-library",
      redirect: commonRoute.imageGallery,
      hrefMenu: commonRoute.imageGallery,
    },
    {
      id: 4,
      title: "Subscriptions",
      icon: icons.products,
      url: "/subscription",
      redirect: commonRoute.subscription,
      hrefMenu: commonRoute.subscription,
    },
    {
      id: 5,
      title: "Payment History",
      icon: icons.products,
      url: "/payment-history",
      redirect: commonRoute.paymentHistory,
      hrefMenu: commonRoute.paymentHistory,
    },
    {
      id: 6,
      title: "Contact Us",
      icon: icons.contact,
      url: "/contact-us",
      redirect: commonRoute.contactUs,
      hrefMenu: commonRoute.contactUs,
    },
    // {
    //   id: 6,
    //   title: "Site Setting",
    //   icon: icons.setting,
    //   url: "/site-setting",
    //   redirect: commonRoute.siteSetting,
    // },
    {
      id: 7,
      title: "Settings",
      icon: icons.setting,
      url: "/settings",
      redirect: commonRoute.settings,
      hrefMenu: commonRoute.settings,
      subMenu: [
        {
          id: 1,
          title: "Site Settings",
          url: "/site-setting",
          redirect: `${commonRoute.settings}/site-setting`,
          href: `${commonRoute.settings}/site-setting`,
        },
        {
          id: 1,
          title: "SMTP Settings",
          url: "/smtp-setting",
          redirect: commonRoute.settingsTypeSetting,
          href: commonRoute.settingsTypeSetting,
        },
      ],
    },
    // {
    //   id: 6,
    //   title: "Setting",
    //   icon: icons.setting,
    //   url: "/setting",
    //   redirect: commonRoute.siteSetting,
    //   subMenu: [
    //     {
    //       id: 1,
    //       title: "Site Setting",
    //       url: "/site-setting",
    //       redirect: `${commonRoute.siteSetting}/site-setting`,
    //     },
    //     {
    //       id: 1,
    //       title: "SMTP Setting",
    //       url: "/smtp-setting",
    //       redirect: `${commonRoute.siteSetting}/smtp-setting`,
    //     },
    //   ],
    // },
    //old reports
    // {
    //   id: 8,
    //   title: "Reports",
    //   icon: icons.category,
    //   url: "/reports",
    //   redirect: commonRoute.reports,
    //   subMenu: [
    //     {
    //       id: 1,
    //       title: "Users",
    //       url: "/user-report",
    //       redirect: `${commonRoute.reports}/user-report`,
    //     },
    //     {
    //       id: 1,
    //       title: "Subscription",
    //       url: "/subscription-report",
    //       redirect: `${commonRoute.reports}/subscription-report`,
    //     },
    //     {
    //       id: 1,
    //       title: "Un-Subscribe",
    //       url: "/un-subscribe/report",
    //       redirect: `${commonRoute.reports}/subscription-report`,
    //     },
    //     {
    //       id: 1,
    //       title: "IP",
    //       url: "/ip-report",
    //       redirect: `${commonRoute.reports}/subscription-report`,
    //     },
    //   ],
    // },
    //updated reports
    {
      id: 8,
      title: "Report",
      icon: icons.category,
      url: "/reports",
      redirect: commonRoute.reports,
      hrefMenu: commonRoute.reports,
      subMenu: [
        {
          id: 1,
          title: "User Report",
          url: "/user-reports",
          redirect: `${commonRoute.reports}/user-reports`,
          href: `${commonRoute.reports}/user-reports`,
        },
        {
          id: 2,
          title: "Subscription",
          url: "/subscription-reports",
          redirect: `${commonRoute.reports}/subscription-reports`,
          href: `${commonRoute.reports}/subscription-reports`,
        },
        // {
        //   id: 3,
        //   title: "Subscribe",
        //   url: "/un-subscribe-reports",
        //   redirect: `${commonRoute.reports}/un-subscribe-reports`,
        // },
        {
          id: 4,
          title: "IP Report",
          url: "/ip-reports",
          redirect: `${commonRoute.reports}/ip-reports`,
          href: `${commonRoute.reports}/ip-reports`,
        },
      ],
    },
    // {
    //   id: 5,
    //   title: "Services",
    //   icon: icons.category,
    //   url: "/services",
    //   isTrue: false,
    //   redirect: commonRoute.services,
    // },
    // {
    //   id: 6,
    //   title: "Products",
    //   icon: icons.products,
    //   url: "/products",
    //   isTrue: false,
    //   redirect: commonRoute.products,
    // },
    // {
    //   id: 7,
    //   title: "Promotions",
    //   icon: icons.promotion,
    //   url: "/promotions",
    //   isTrue: false,
    //   redirect: commonRoute.promotions,
    // },
    // {
    //   id: 8,
    //   title: "Gist",
    //   icon: icons.gist,
    //   url: "/gist",
    //   isTrue: false,
    //   redirect: commonRoute.gist,
    // },
    // {
    //   id: 9,
    //   title: "Admins",
    //   icon: icons.admin,
    //   url: "/admins",
    //   isTrue: false,
    //   redirect: commonRoute.admins,
    // },
  ];
  return (
    <div id="left-side-block">
      <div
        className="menu-icn"
        onClick={() => {
          dispatch(setIsSidebar(false));
        }}
      >
        <i className="bi bi-list color-white d-flex" />
      </div>
      <div className="top-icon-block">
        <img
          src={icons.logo}
          alt="icon"
          style={{
            height: "30px",
          }}
        />
      </div>
      {/* <div className="menu-list-container d-flex flex-column gap-3">
        {leftMenu.map((elem, index) => {
          const { url, title, icon, redirect, subMenu } = elem;
          const isActive =
            url === `/${window.location.pathname?.split("/")[1]}`;
          return (
            <React.Fragment key={index}>
              <div
                href={elem?.hrefMenu}
                className={`menu-list-item ${
                  isActive ? "active-menu-item" : "inactive-menu-item"
                }`}
                key={index}
                onClick={() => {
                  navigate(redirect);
                }}
              >
                <span>
                  <img src={icon} alt="icon" />
                </span>
                <span>{title}</span>
              </div>
              {isActive && subMenu?.length > 0 && (
                <div className="">
                  {subMenu?.map((cElem, cIndex) => {
                    return (
                      <div
                        key={cIndex}
                        className={`sub-menu-item ${
                          window.location.pathname.includes(cElem?.url)
                            ? "active-sub-menu-item"
                            : "inactive-sub-menu-item"
                        }`}
                        onClick={() => {
                          if (!window.location.pathname.includes(cElem?.url)) {
                            navigate(cElem?.redirect);
                          }
                        }}
                      >
                        {cElem?.title}
                      </div>
                    );
                  })}
                </div>
              )}
            </React.Fragment>
          );
        })}
      </div> */}
      <div className="menu-list-container d-flex flex-column gap-3">
        {leftMenu.map((elem, index) => {
          const { url, title, icon, redirect, subMenu } = elem;
          const isActive =
            url === `/${window.location.pathname?.split("/")[1]}`;
          return (
            <React.Fragment key={index}>
              <Nav.Link
                href={elem?.hrefMenu}
                className={`menu-list-item ${
                  isActive ? "active-menu-item" : "inactive-menu-item"
                }`}
                key={index}
                onClick={(e) => {
                  e.preventDefault();
                  navigate(redirect);
                }}
              >
                <span>
                  <img src={icon} alt="icon" />
                </span>
                <span>{title}</span>
              </Nav.Link>
              {isActive && subMenu?.length > 0 && (
                <div className="">
                  {subMenu?.map((cElem, cIndex) => {
                    return (
                      <Nav.Link
                        href={cElem?.href}
                        key={cIndex}
                        className={`sub-menu-item ${
                          window.location.pathname.includes(cElem?.url)
                            ? "active-sub-menu-item"
                            : "inactive-sub-menu-item"
                        }`}
                        onClick={() => {
                          // e.preventDefault();
                          // e.stopPropagation();
                          // if (!window.location.pathname.includes(cElem?.url)) {
                          // navigate(cElem?.redirect);
                          // }
                        }}
                      >
                        {cElem?.title}
                      </Nav.Link>
                    );
                  })}
                </div>
              )}
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};
export default Sidebar;
