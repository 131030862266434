import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { handelLogout, setIsSidebar } from "store/globalSlice";
import { icons } from "utils/constants";
import "./Header.scss";

const Header = ({ isBack }) => {
  const { isSidebar } = useSelector((state) => ({
    isSidebar: state.global.isSidebar,
  }));
  const dispatch = useDispatch();
  const myRef = useRef();
  const navigate = useNavigate();
  const [isProfile, setIsProfile] = useState(false);
  const handleClickOutside = (e) => {
    if (myRef.current && !myRef.current.contains(e.target)) {
      setIsProfile(false);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  });
  return (
    <div id="header-container">
      <div className="d-flex">
        {!isSidebar && (
          <div
            className="menu-icn-open"
            onClick={() => {
              dispatch(setIsSidebar(true));
            }}
          >
            <i className="bi bi-list color-dashboard-primary d-flex" />
          </div>
        )}
        <div className="cps-24">
          {isBack && (
            <img
              src={icons.back}
              alt="back"
              className="pointer"
              onClick={() => {
                navigate(-1);
              }}
            />
          )}
        </div>
      </div>
      <div className="right-header-block">
        {/* <div className="notification-block">
          <img src={icons.bell} alt="bell" />
          <div className="outer-block">
            <div className="counter-block">3</div>
          </div>
        </div> */}
        <div
          className="profile-header-block pointer"
          onClick={() => {
            setIsProfile(!isProfile);
          }}
        />
        <div className="user-name-header-block">
          <div className="name-block">
            <span
              className="pointer"
              onClick={() => {
                setIsProfile(!isProfile);
              }}
            >
              Admin
            </span>
            <span
              className="d-flex pointer"
              onClick={() => {
                setIsProfile(!isProfile);
              }}
            >
              <i className="bi bi-chevron-down" />
            </span>
            {isProfile && (
              <div className="profile-option-block shadow" ref={myRef}>
                {/* <div
                  className="item-block"
                  onClick={() => {
                    navigate("/admins/profile-details/my");.
                  }}
                >
                  <span className="icon-c-block">
                    <img src={icons.profile} alt="profile" />
                  </span>
                  <span>Profile</span>
                </div> */}
                <div
                  className="item-block"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    dispatch(handelLogout());
                    setIsProfile(false);
                  }}
                >
                  <span className="icon-c-block">
                    <img src={icons.logout} alt="profile" />
                  </span>
                  <span>Logout</span>
                </div>
              </div>
            )}
          </div>
          {/* <div className="role-block">Admin</div> */}
        </div>
      </div>
    </div>
  );
};
export default Header;
