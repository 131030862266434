import { useEffect, useRef, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import Dropdown from "components/form/Dropdown";
import TextInput from "components/form/TextInput";
import TextArea from "components/form/TextArea";
import FileUpload from "components/form/FileUpload";
import Button from "components/form/Button";
import { useDispatch } from "react-redux";
import {
  getSingleQuestions,
  quesDetailsUpdate,
  throwSuccess,
} from "store/globalSlice";
import { objectToFormData, trimLeftSpace } from "utils/helpers";
import { omit, isEqual } from "lodash";
import { useParams } from "react-router-dom";
import Loader from "components/layouts/Loader";

const StudyToolsEdit = () => {
  const params = useParams();
  // console.log("param", params);

  const { type } = params;
  const paramsObject = {
    "ask-questions": "askQuestion",
    "expert-solutions": "expertSolution",
    "flash-cards": "flashCard",
  };

  let types = paramsObject[type];
  // console.log("types", types);

  const dispatch = useDispatch();
  const formRef = useRef();
  const [btnLoading, setBtnLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  let temp;
  if (params?.type === "ask-questions") {
    temp = "askQuestion";
  } else if (params?.type === "expert-solutions") {
    temp = "expertSolution";
  } else if (params?.type === "flash-cards") {
    temp = "flashCard";
  }

  const [quesdata, setQuesdata] = useState({});
  const {
    question,
    title,
    slug,
    option1,
    option2,
    option3,
    option4,
    answer,
    description,
    externalFile,
    externalFileName,
    keyword,
    keywords,
    meta_title,
    meta_description,
    meta_keyword,
    meta_keywords,
  } = quesdata || {};

  const fetchSingleQues = async () => {
    const response = await dispatch(getSingleQuestions(temp, params?.id));
    // console.log("response", response);
    const userDataObj = response?.data;
    // console.log("data", response?.data);
    setQuesdata(userDataObj);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchSingleQues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handelSave = async (values) => {
    setBtnLoading(true);
    // console.log("values", values);
    let newObject = values;
    if (values.type === "flashCard") {
      newObject = omit(newObject, [
        "answer",
        "description",
        "externalFileName",
        "keyword",
        "meta_keyword",
        "option1",
        "option2",
        "option3",
        "option4",
      ]);
    }
    if (values.type === "ask-questions") {
      // console.log("inside the ask block");
      newObject = omit(newObject, [
        "externalFile",
        "description",
        "externalFileName",
        "keyword",
        "meta_keyword",
      ]);
    }
    if (values.type === "expertSolution") {
      newObject = omit(newObject, [
        "externalFile",
        "externalFileName",
        "keyword",
        "meta_keyword",
        "option1",
        "option2",
        "option3",
        "option4",
        "answer",
      ]);
    }
    const payload = objectToFormData(newObject);
    // console.log("obj", newObject);
    const response = await dispatch(quesDetailsUpdate(params?.id, payload));
    if (response?.status === 200) {
      dispatch(throwSuccess("Question Updated Successfully."));
      await dispatch(getSingleQuestions(temp, params?.id));
    }
    setBtnLoading(false);
  };
  const validationSchema = Yup.object().shape({
    question: Yup.string().required("Title is required."),
    // keywords: Yup.string().required("Search Keywords is required."),
    slug: Yup.string()
      .required("Slug URL is required.")
      .max(60, "60 Characters Allowed"),
    meta_title: Yup.string().max(60, "60 Characters Allowed"),
    meta_description: Yup.string().max(160, "165 Characters Allowed"),
    meta_keyword: Yup.string().max(65, "65 Characters Allowed"),
    option1: Yup.lazy((_, obj) => {
      const { type } = obj?.parent;
      if (type === "askQuestion") {
        return Yup.string().required("Option a is required.");
      } else {
        return Yup.string();
      }
    }),
    option2: Yup.lazy((_, obj) => {
      const { type } = obj?.parent;
      if (type === "askQuestion") {
        return Yup.string().required("Option b is required.");
      } else {
        return Yup.string();
      }
    }),
    answer: Yup.lazy((_, obj) => {
      const { type } = obj?.parent;
      if (type === "askQuestion") {
        return Yup.string().required("Answer is required.");
      } else {
        return Yup.string();
      }
    }),
    description: Yup.lazy((_, obj) => {
      const { type } = obj?.parent;
      if (type === "expertSolution") {
        return Yup.string().required("Description is required.");
      } else {
        return Yup.string();
      }
    }),
    externalFile: Yup.lazy((_, obj) => {
      const { type } = obj?.parent;
      if (type === "flashCard") {
        return Yup.string().required("External File is required.");
      } else {
        return Yup.string();
      }
    }),
  });
  // console.log("questiotype:", types);
  const initialValues = {
    type: types,
    question: title || question || "",
    // question: question || "",
    option1: option1 || "",
    option2: option2 || "",
    option3: option3 || "",
    option4: option4 || "",
    answer: answer || "",
    description: description || "",
    externalFile: externalFile || "",
    externalFileName: externalFileName || "",
    keyword: keyword || "",
    keywords: keywords || "",
    slug: slug || "",
    meta_title: meta_title || "",
    meta_description: meta_description || "",
    meta_keyword: meta_keyword || "",
    meta_keywords: meta_keywords || "",
  };

  return (
    <div className="bg-white rounded p-3">
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center pt-5 pb-5">
          <Loader size="md" />
        </div>
      ) : (
        <Formik
          innerRef={formRef}
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(e) => {
            handelSave(e);
            // console.log("submit", e);
          }}
        >
          {(props) => {
            const {
              values,
              errors,
              handleChange,
              setFieldValue,
              submitForm,
              handleReset,
            } = props;

            const {
              /* question, */
              /* title, */
              keyword,
              keywords,
              option1,
              option2,
              option3,
              option4,
              /* answer,
            description,
            slug,
            meta_title,
            meta_description, */
              meta_keyword,
              meta_keywords,
              /* externalFileName, */
            } = values;
            let answerList = [];
            if (option1) {
              answerList.push({ id: option1, label: `a) ${option1}` });
            }
            if (option2) {
              answerList.push({ id: option2, label: `b) ${option2}` });
            }
            if (option3) {
              answerList.push({ id: option3, label: `c) ${option3}` });
            }
            if (option4) {
              answerList.push({ id: option4, label: `d) ${option4}` });
            }

            let keywordsArray = keywords ? keywords.split(",") : [];
            let metaKeywordsArray = meta_keywords
              ? meta_keywords.split(",")
              : [];
            return (
              <form className="row">
                {/* <div className="col-md-12">
                <div className="row">
                  <div className="col-md-6 cmb-22">
                    <Dropdown
                      label="Question Type"
                      placeholder="Select Question Type"
                      id="type"
                      value={type}
                      onChange={handleChange}
                      options={[
                        {
                          id: "flashCard",
                          label: "Flash Card",
                        },
                        {
                          id: "askQuestion",
                          label: "Ask Question",
                        },
                        {
                          id: "expertSolution",
                          label: "Expert Solution",
                        },
                      ]}
                    />
                  </div>
                </div>
              </div> */}

                <div className="cmb-22">
                  <TextInput
                    required
                    label="Title / Question"
                    placeholder="Enter Title / Question"
                    id="question"
                    value={values?.question}
                    onChange={handleChange}
                    error={errors.question}
                  />
                </div>
                <div className="cmb-22">
                  <TextInput
                    required
                    label="Slug URL"
                    placeholder="Enter Slug URL"
                    id="slug"
                    value={values?.slug}
                    error={errors?.slug}
                    onChange={handleChange}
                  />
                </div>
                {types === "askQuestion" && (
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-3 cmb-22">
                        <TextInput
                          required
                          label="Option a"
                          placeholder="Enter Option a"
                          id="option1"
                          value={values?.option1}
                          error={errors.option1}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-md-3 cmb-22">
                        <TextInput
                          required
                          label="Option b"
                          placeholder="Enter Option b"
                          id="option2"
                          value={values?.option2}
                          error={errors.option2}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-md-3 cmb-22">
                        <TextInput
                          label="Option c"
                          placeholder="Enter Option c"
                          id="option3"
                          value={values?.option3}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-md-3 cmb-22">
                        <TextInput
                          label="Option d"
                          placeholder="Enter Option d"
                          id="option4"
                          value={values?.option4}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                )}
                {option1 && option2 && types === "askQuestion" && (
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-6 cmb-22">
                        <Dropdown
                          isRequired
                          label="Answer"
                          placeholder="Select Answer"
                          options={answerList}
                          id="answer"
                          value={values?.answer}
                          onChange={handleChange}
                          error={errors.answer}
                        />
                      </div>
                    </div>
                  </div>
                )}
                {types === "expertSolution" && (
                  <div className="cmb-22">
                    <TextArea
                      isRequired
                      label="Description"
                      placeholder="Enter Description"
                      id="description"
                      value={values?.description}
                      onChange={handleChange}
                      error={errors.description}
                    />
                  </div>
                )}
                {types === "flashCard" && (
                  <div className="cmb-22">
                    <FileUpload
                      required
                      id="externalFile"
                      fileText={values?.externalFileName || ""}
                      label="Image/Pdf"
                      error={errors.externalFile}
                      onChange={(e) => {
                        setFieldValue("externalFileName", e.target.fileName);
                        handleChange(e);
                      }}
                    />
                  </div>
                )}
                {/* <div className="col-md-12 cmb-22">
                  <div className="row">
                    <div className="">
                      <TextInput
                        required
                        label="Keywords for Search Results"
                        placeholder="Enter Search Keywords"
                        id="keyword"
                        value={values?.keyword}
                        rightIcon={<i className="bi bi-plus-circle" />}
                        rightIconClick={() => {
                          if (trimLeftSpace(keyword)) {
                            let arr = keywordsArray;
                            arr.push(keyword);
                            let arrString =
                              arr.length === 0 ? "" : arr.join(",");
                            setFieldValue("keyword", "");
                            setFieldValue("keywords", arrString);
                          }
                        }}
                        onChange={handleChange}
                        onKeyUp={(e) => {
                          // console.log("target", e.target.value);
                          // console.log("keycode:", e.keyCode);

                          if (
                            e?.keyCode === 13 &&
                            trimLeftSpace(e.target.value)
                          ) {
                            let arr = keywordsArray;
                            arr.push(keyword);
                            // console.log("keyqordArray:", arr);
                            // console.log("keyboard press:", keywords);

                            let arrString =
                              arr.length === 0 ? "" : arr.join(",");
                            setFieldValue("keyword", "");
                            setFieldValue("keywords", arrString);
                            // console.log("keypress:", arrString);
                          }
                        }}
                        error={errors.keywords}
                      />
                    </div>
                  </div>

                  {keywordsArray.length > 0 && (
                    <div className="row mt-3">
                      <div className="w-auto d-flex flex-wrap gap-3">
                        {keywordsArray?.map((el, index) => {
                          return (
                            <div
                              key={index}
                              className="w-auto d-flex align-items-center gap-2 shadow ps-2 pt-2 pe-2 pb-1"
                            >
                              <span>{el}</span>
                              <span>
                                <i
                                  className="bi bi-trash-fill pointer"
                                  onClick={() => {
                                    let newArry = keywordsArray.filter(
                                      (_, i) => i !== index
                                    );
                                    let arrString =
                                      newArry.length === 0
                                        ? ""
                                        : newArry.join(",");
                                    setFieldValue("keywords", arrString);
                                  }}
                                />
                              </span>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </div> */}
                <div className="col-md-12 cmb-22 mt-3">
                  <div className="row">
                    <div className="text-20-700">SEO Meta Data</div>

                    <div className="cmb-22">
                      <TextInput
                        label="Title"
                        placeholder="Enter Title"
                        id="meta_title"
                        // value={values?.meta_title}
                        error={errors?.meta_title}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="cmb-22">
                      <TextArea
                        label="Description"
                        placeholder="Enter Description"
                        id="meta_description"
                        // value={values?.meta_description}
                        error={errors?.meta_description}
                        onChange={handleChange}
                      />
                    </div>
                    <div>
                      <TextInput
                        label="Keywords"
                        placeholder="Enter Keywords"
                        id="meta_keyword"
                        // value={values?.meta_keyword}
                        error={errors?.meta_keyword}
                        onChange={handleChange}
                        rightIcon={<i className="bi bi-plus-circle" />}
                        rightIconClick={() => {
                          if (trimLeftSpace(meta_keyword)) {
                            let arr = metaKeywordsArray;
                            arr.push(meta_keyword);
                            let arrString =
                              arr.length === 0 ? "" : arr.join(",");
                            setFieldValue("meta_keyword", "");
                            setFieldValue("meta_keywords", arrString);
                          }
                        }}
                        onKeyUp={(e) => {
                          if (
                            e?.keyCode === 13 &&
                            trimLeftSpace(e.target.value)
                          ) {
                            let arr = metaKeywordsArray;
                            arr.push(meta_keyword);
                            let arrString =
                              arr.length === 0 ? "" : arr.join(",");
                            setFieldValue("meta_keyword", "");
                            setFieldValue("meta_keywords", arrString);
                          }
                        }}
                      />
                    </div>
                    {metaKeywordsArray.length > 0 && (
                      <div className="row mt-3">
                        <div className="w-auto d-flex flex-wrap gap-3">
                          {metaKeywordsArray?.map((el, index) => {
                            return (
                              <div
                                key={index}
                                className="w-auto d-flex align-items-center gap-2 shadow ps-2 pt-2 pe-2 pb-1 "
                              >
                                <span>{el}</span>
                                <span>
                                  <i
                                    className="bi bi-trash-fill pointer"
                                    onClick={() => {
                                      let newArry = metaKeywordsArray.filter(
                                        (_, i) => i !== index
                                      );
                                      let arrString =
                                        newArry.length === 0
                                          ? ""
                                          : newArry.join(",");
                                      setFieldValue("meta_keywords", arrString);
                                    }}
                                  />
                                </span>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="d-flex justify-content-center gap-5 mt-3">
                  <Button
                    value="Cancel"
                    btnStyle="btn-primary-darker-outline"
                    className="cps-40 cpe-40 text-17-500"
                    onClick={handleReset}
                  />

                  <Button
                    value="Submit"
                    btnStyle="btn-dashboard-primary"
                    className="cps-40 cpe-40 text-17-500"
                    onClick={(e) => {
                      if (!btnLoading) {
                        submitForm(e);
                      }
                    }}
                    btnLoading={btnLoading}
                    disabled={isEqual(values, initialValues)}
                  />
                </div>
              </form>
            );
          }}
        </Formik>
      )}
    </div>
  );
};
export default StudyToolsEdit;
