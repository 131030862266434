import Button from "components/form/Button/Button";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";

const DeleteConfirmPopup = ({ onHide, handelSuccess, apiFunction, title }) => {
  const [btnLoader, setBtnLoader] = useState(false);

  const handelDelete = async () => {
    setBtnLoader(true);
    const response = await apiFunction();
    if (response?.status === 200) {
      handelSuccess();
    }
    setBtnLoader(false);
  };

  return (
    <Modal show aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Body>
        <div className="text-17-700 text-center cmb-22 mt-2">
         Are you sure you want to delete the user {title}?
        </div>
        <div className="delete-content-block text-center cmb-22">
          <span>
          This will delete user feedback permanently, you cannot undo this action.
          </span>
        </div>

        <div className="d-flex justify-content-center gap-3">
          <Button
            className="text-13-600 h-auto cps-18 cpe-18 cpt-5 cpb-5 color-dahsboard-primary text-16-600"
            btnStyle="btn-primary"
            value="Cancel"
            onClick={onHide}
            // btnLoading={btnLoader}
          />
          <Button
            className="text-13-600 h-auto cps-18 cpe-18 cpt-5 cpb-5 color-dahsboard-primary text-16-600"
            btnStyle="btn-dashboard-primary"
            value="Delete"
            onClick={handelDelete}
            btnLoading={btnLoader}
            // onClick={(e) => {
            //   e.preventDefault();
            //   handelDelete(isData?.id);
            //   // console.log("delete", e);
            // }}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default DeleteConfirmPopup;
