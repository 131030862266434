import Table from "components/layouts/Table";
import CheckBox from "components/form/CheckBox/CheckBox";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import moment from "moment/moment";
import {
  deleteContactDetail,
  getContactDetails,
  throwSuccess,
} from "store/globalSlice";
import "./ContactUs.scss";
import { getDialCode } from "utils/helpers";
import { icons } from "utils/constants";
import { Dropdown } from "react-bootstrap";
import DeleteConfirmPopup from "components/layouts/DeleteConfirmPopup/DeleteConfirmPopup";
import Button from "components/form/Button/Button";
import { CSVLink } from "react-csv";
import ContactUsFilterPopUp from "./ContactUsFilterPopUp";

const ContactUs = () => {
  const dispatch = useDispatch();
  // const [tableDataLoading, setTableDataLoading] = useState(true);
  const [showPopup, setShowPopup] = useState(false);
  const [isDeletePopup, setIsDeletePopup] = useState(false);
  const [contactSelectedId, setContactSelectedId] = useState([]);
  const [tableData, setTableData] = useState({
    offset: 0,
    limit: 10,
    intervalLimit: 10,
    total: 0,
    search: "",
    loading: true,
    data: [],
    startDate: "",
    endDate: "",
    name: "",
    email: "",
    mobile: "",
    countryCode: "US",
    message: "",
    is_user: "",
  });
  const [exportData, setExportData] = useState({
    data: [],
    loader: false,
  });
  const exportHeader = [
    "Name",
    "User Type",
    "Email",
    "Mobile No",
    "Message",
    "Date",
  ];

  const getContactUsData = async (returnData) => {
    let flagArray = [];
    if (returnData.length > 0) {
      flagArray = returnData?.map((o) => {
        return [
          o.name || "",
          o.name ? (o.is_user ? "User" : "Visitor") : "",
          o.email || "",
          `${getDialCode(o.country_code || "-")} ${o.mobile || "-"}`,
          o.message || "",
          moment(o.createdAt).format("DD-MMM-YYYY") || "",
        ];
      });
    }
    setExportData((prev) => {
      return { ...prev, data: flagArray, loader: false };
    });
  };

  const fetchContactData = async (obj) => {
    const response = await dispatch(getContactDetails(obj));
    if (response?.status === 200) {
      setTableData((prev) => {
        return {
          ...prev,
          data: response?.data?.rows || [],
          total: response?.data?.count || 0,
          loading: false,
        };
      });
      getContactUsData(response.data.rows);
    }
    response?.data?.rows ||
      [].forEach((ele) => {
        ele.isChecked = false;
      });
  };

  const handleSelect = (e) => {
    let oldList = contactSelectedId;
    if (oldList.includes(e)) {
      setContactSelectedId(contactSelectedId.filter((o) => o !== e));
    } else {
      setContactSelectedId([...contactSelectedId, e]);
    }
  };

  // useEffect(() => {
  //   if (tableData.total > 0) {
  //     getContactUsData(tableData.total);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [tableData.total]);

  useEffect(() => {
    fetchContactData(tableData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const header = [
    {
      title: "",
    },
    {
      title: <div className="text-nowrap">SI No</div>,
    },
    {
      title: "Name",
    },
    {
      title: "Mobile",
    },
    {
      title: "Email",
    },
    {
      title: "Message",
    },
    {
      title: "Date",
    },
    {
      title: "Action",
    },
  ];

  const rowData = [];

  tableData?.data.forEach((elem, index) => {
    const { name, country_code, mobile, email, message, createdAt, is_user } =
      elem;
    let obj = [
      {
        value: (
          <CheckBox
            id="selectAll"
            name="isAllSelected"
            isCheck={contactSelectedId.includes(elem.id)}
            onChange={() => handleSelect(elem.id)}
            disabled={is_user}
          />
        ),
      },
      {
        value: index + 1,
      },
      {
        value: (
          <div className="text-nowrap">
            {name}
            <span
              className={`pt-1 ${
                is_user === 0 ? "bg-danger" : "bg-success"
              } text-white ps-1 pe-1 ms-1 rounded-1`}
            >
              {is_user === 0 ? "V" : "U"}
            </span>
          </div>
        ),
      },
      {
        value: `${getDialCode(country_code || "-")} ${mobile || "-"}`,
      },
      {
        value: email,
      },
      {
        value: message,
      },
      {
        value: moment(createdAt).format("DD-MMM-YYYY"),
      },
      {
        value: (
          <Dropdown align="end">
            <Dropdown.Toggle variant="" id="dropdown-basic">
              <img src={icons.threeDots} alt="options" className="pointer" />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {/* <Dropdown.Item
                href=""
                className="d-flex align-items-center gap-2"
                onClick={() => {}}
              >
                <span>
                  <img src={icons.edit} alt="edit" />
                </span>
                <span className="mt-1">Edit</span>
              </Dropdown.Item> */}
              <Dropdown.Item
                href=""
                className="d-flex align-items-center gap-2"
                onClick={() => {
                  setIsDeletePopup(true);
                  setContactSelectedId([elem.id]);
                }}
                // onClick={(e) => {
                //   e.preventDefault();
                //   handelDelete(id);
                // }}
              >
                <span>
                  <img src={icons.trash} alt="trash" />
                </span>
                <span className="mt-1">Delete</span>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        ),
      },
    ];

    rowData.push({ data: obj });
  });

  const handleSelectAll = () => {
    if (tableData?.data?.length > 0) {
      let onlyID = [];
      tableData?.data?.forEach((o) => {
        if (o.is_user === 0) {
          onlyID.push(o.id);
        }
      });
      if (contactSelectedId.length === onlyID?.length) {
        setContactSelectedId([]);
      } else {
        setContactSelectedId(onlyID);
      }
    } else {
      setContactSelectedId([]);
    }
  };

  const deleteImage = async () => {
    setIsDeletePopup(true);
  };
  let onlyID = [];
  tableData?.data?.forEach((o) => {
    if (o.is_user === 0) {
      onlyID.push(o.id);
    }
  });
  return (
    <div id="contact-us-container" className="bg-white rounded pb-3">
      {isDeletePopup && (
        <DeleteConfirmPopup
          title="feedback"
          onHide={() => {
            setIsDeletePopup(false);
          }}
          apiFunction={async () => {
            const deletePayload = { id: contactSelectedId };
            await dispatch(deleteContactDetail(deletePayload));
            dispatch(throwSuccess("Users Delete Successfully."));
            setIsDeletePopup(false);
            fetchContactData(tableData);
            return;
          }}
        />
      )}
      <div className="text-20-700 mb-3 cps-24 cpt-24 d-flex justify-content-between align-items-center">
        Contact Details
        <div className="d-flex gap-2">
          <Button
            value="Filter"
            btnStyle="btn-dashboard-primary"
            className="cps-30 cpe-30 pt-2 pb-2 text-15-500 h-auto"
            onClick={() => {
              setShowPopup(true);
            }}
          />
          {tableData.data.length > 0 && (
            <CSVLink
              className="text-decoration-none"
              filename={`Contact Us.csv`}
              headers={exportHeader}
              data={exportData?.data}
            >
              <Button
                value="Export"
                btnStyle="btn-dashboard-primary"
                className="cps-15 cpe-15 text-15-500"
                btnLoading={exportData.loader}
                // onClick
              />
            </CSVLink>
          )}
        </div>
      </div>
      <div className="d-flex align-items-center gap-3 mb-2 ms-2">
        {tableData?.data?.length > 0 && (
          <CheckBox
            label="Select all"
            id="selectAll"
            name="isAllSelected"
            isCheck={contactSelectedId?.length === onlyID?.length}
            onChange={handleSelectAll}
          />
        )}
        {contactSelectedId?.length > 0 && (
          <Button
            id="delete"
            value="Delete"
            btnStyle="btn-dashboard-primary"
            className="cps-30 cpe-30 text-15-500"
            onClick={deleteImage}
            // btnLoading={btnLoading}
          />
        )}
      </div>
      <Table
        // isSearch
        isLoading={tableData?.loading}
        header={header}
        rowData={rowData}
        searchText={tableData?.search}
        tableData={tableData}
        searchLabel="Search User"
        searchInputChange={(searchText) => {
          let oldData = { ...tableData, search: searchText, loading: true };
          setTableData(oldData);
          fetchContactData(oldData);
        }}
        changeOffset={(newOffset, newLimit = tableData.limit) => {
          let oldData = {
            ...tableData,
            offset: newOffset,
            limit: newLimit,
            loading: true,
          };
          setTableData(oldData);
          fetchContactData(oldData);
        }}
        isPagination
      />
      {showPopup && (
        <ContactUsFilterPopUp
          tableData={tableData}
          setTableData={setTableData}
          handelContact={fetchContactData}
          onHide={() => {
            setShowPopup(false);
          }}
        />
      )}
    </div>
  );
};

export default ContactUs;
