import Button from "components/form/Button";
import Dropdown from "components/form/Dropdown";
import TextEditor from "components/form/TextEditor";
import TextInput from "components/form/TextInput";
import { Formik } from "formik";
import * as Yup from "yup";
import React, { useEffect, useRef, useState } from "react";
import {
  editPlan,
  getSinglePlan,
  throwError,
  throwSuccess,
} from "store/globalSlice";
import { isEqual } from "lodash";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import Loader from "components/layouts/Loader";

const SubscriptionEdit = () => {
  const dispatch = useDispatch();
  const params = useParams();

  const [btnLoading, setBtnLoading] = useState(false);
  const [textEditorReset, setTextEditorReset] = useState(false);

  // const [tableList, setTableList] = useState([]);
  // const [tableLoading, setTableLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [planData, setPlanData] = useState({});
  const { category, name, status, description, amount } = planData || {};

  const countryType = planData?.country_type === "INR";

  const formRef = useRef();

  const getSinglePlanData = async () => {
    const response = await dispatch(getSinglePlan(params?.id));
    const planData = response?.data;
    setPlanData(planData);
    setIsLoading(false);
  };

  const handelSave = async (values) => {
    setBtnLoading(true);
    const response = await dispatch(editPlan(params?.id, values));

    if (response?.status === 200) {
      dispatch(throwSuccess("Subscription Updated Successfully."));
      await dispatch(getSinglePlan(params?.id));
    }
    setBtnLoading(false);
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required."),
    category: Yup.string().required("Category is required."),
    amount: Yup.string().required("Amount is required."),
    description: Yup.string().required("Description is required."),
  });
  const initialValues = {
    name: name || "",
    category: category || "",
    amount: amount || "",
    description: description || "",
    status: status || "",
  };

  useEffect(() => {
    getSinglePlanData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div id="subscription-edit-container">
      <div className="bg-white rounded p-3">
        {isLoading ? (
          <div className="d-flex justify-content-center align-items-center pt-5 pb-5">
            <Loader size="md" />
          </div>
        ) : (
          <Formik
            innerRef={formRef}
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(e) => {
              handelSave(e);
            }}
          >
            {(props) => {
              const {
                values,
                errors,
                handleChange,
                submitForm,
                setFieldValue,
              } = props;

              return (
                <>
                  <div className="text-20-700 color-black-100">
                    Subscription Edit
                  </div>

                  <form className="row mt-3">
                    <div className="cmb-22">
                      <TextInput
                        required
                        label="Name(For Stripe)"
                        placeholder="Enter Name"
                        id="name"
                        value={values?.name}
                        error={errors.name}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-md-6 cmb-22">
                      <Dropdown
                        isRequired
                        label="Category"
                        placeholder="Select Category"
                        id="category"
                        value={values?.category}
                        onChange={handleChange}
                        error={errors.category}
                        options={[
                          {
                            id: "Monthly",
                            label: "Monthly",
                          },
                          {
                            id: "Quarterly",
                            label: "Quarterly",
                          },
                          {
                            id: "Yearly",
                            label: "Yearly",
                          },
                        ]}
                        disabled
                      />
                    </div>
                    <div className="col-md-6 cmb-22">
                      <TextInput
                        required
                        label={countryType ? "Amount (₹)" : "Amount ($)"}
                        placeholder="Enter Amount"
                        id="amount"
                        value={values?.amount}
                        error={errors.amount}
                        onChange={(e) => {
                          let val = e.target.value;
                          handleChange({
                            target: {
                              id: e.target.id,
                              value: val,
                            },
                          });
                        }}
                      />
                    </div>

                    <div className="cmb-24">
                      <TextEditor
                        required
                        label="Description"
                        placeholder="Description"
                        id="description"
                        isReset={textEditorReset}
                        value={values?.description}
                        error={errors.description}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="d-flex justify-content-center gap-5 mt-3">
                      <Button
                        value="Cancel"
                        btnStyle="btn-primary-darker-outline"
                        className="cps-40 cpe-40 text-17-500"
                        onClick={() => {
                          dispatch(
                            throwError({
                              message: "Your changes has been reset!",
                            })
                          );
                          setFieldValue("name", name);
                          setFieldValue("category", category);
                          setFieldValue("amount", amount);
                          setFieldValue("description", description);
                          setTextEditorReset(!textEditorReset);
                        }}
                      />

                      <Button
                        value="Submit"
                        btnStyle="btn-dashboard-primary"
                        className="cps-40 cpe-40 text-17-500"
                        onClick={(e) => {
                          if (!btnLoading) {
                            submitForm(e);
                          }
                        }}
                        btnLoading={btnLoading}
                        disabled={isEqual(values, initialValues)}
                      />
                    </div>
                  </form>
                </>
              );
            }}
          </Formik>
        )}
      </div>
    </div>
  );
};

export default SubscriptionEdit;
