export const commonRoute = {
  login: "/login",
  subLogin: "/login/:secreat",

  dashboard: "/dashboard",
  users: "/users",
  usersEdit: "/users/edit/:id",
  usersDetails: "/users/view-details/:id",

  studyTools: "/quiz-solutions",
  studyQuesView: "/quiz-solutions/:type/:id",
  studyToolsEdit: "/quiz-solutions/:type/edit/:id",
  studyToolsType: "/quiz-solutions/:qType",
  studyToolsQuetion: "/quiz-solutions/question/:id",
  imageGallery: "/media-library",
  subscription: "/subscription",
  subscriptionEdit: "/subscription/edit/:id",
  subscriptionForm: "/subscription/:id",

  paymentHistory: "/payment-history",
  contactUs: "/contact-us",
  settings: "/settings",
  settingsType: "/settings/:sType",
  settingsTypeSetting: "/settings/smtp-setting",
  // siteSetting: "/site-setting",
  // siteSettingType: "/site-setting/:sType",

  //old report
  // reports: "/reports",
  // reportsType: "/reports/:rType",
  // reportsTypeSubcription: "/reports/subscription-report",

  //updated report
  reports: "/reports",
  reportsType: "/reports/:rType",

  // studyToolCategories: "/study-tools/:type",
  // cardList: "/card-list",
  // beauticians: "/beauticians",
  // approvalPage: "/beauticians/:type",
  // brands: "/brands",
  // services: "/services",
  // products: "/products",
  // promotions: "/promotions",
  // gist: "/gist",
  // admins: "/admins",
  // addAdmin: "/admins/:type",
  // myProfile: "/admins/profile-details/:id",
  // changePassword: "/admins/change-password",
  // cardPieChart: "/card-pie-chart",
};
