import Table from "components/layouts/Table";

const DisplayTable = ({
  tableData,
  setTableData,
  fetchTableData,
  header,
  rowData,
}) => {
  return (
    <div className="overflow-auto">
      <Table
        isLoading={tableData?.loading}
        header={header}
        rowData={rowData}
        tableData={tableData}
        changeOffset={(newOffset, newLimit = tableData.limit) => {
          let oldData = {
            ...tableData,
            offset: newOffset,
            limit: newLimit,
            loading: true,
          };
          setTableData(oldData);
          fetchTableData(oldData);
        }}
        isPagination
      />
    </div>
  );
};
export default DisplayTable;
