import Header from "pages/Layout/Header";
import Sidebar from "pages/Layout/Sidebar";
import { useSelector } from "react-redux";
import "./Layout.scss";

const Layout = ({ layoutId, children, isBack }) => {
  const { isSidebar } = useSelector((state) => ({
    isSidebar: state.global.isSidebar,
  }));
  return (
    <div id="layout-container" className="d-flex">
      {isSidebar && (
        <div id="left-side-container">
          <Sidebar />
        </div>
      )}
      <div
        id="right-side-block"
        className={isSidebar ? "" : "force-full-width"}
      >
        <div>
          <Header isBack={isBack} />
        </div>

        <div
          className="cps-24 cpt-24 cpe-24 cpb-24 custom-body-height"
          id={layoutId || ""}
        >
          {children}
        </div>
      </div>
    </div>
  );
};
export default Layout;
