import Button from "components/form/Button/Button";
import Loader from "components/layouts/Loader";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getSingleQuestions } from "store/globalSlice";

const ViewDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  let temp;
  if (params?.type === "ask-questions") {
    temp = "askQuestion";
  } else if (params?.type === "expert-solutions") {
    temp = "expertSolution";
  } else if (params?.type === "flash-cards") {
    temp = "flashCard";
  }
  const [isLoading, setIsLoading] = useState(true);
  const [quesdata, setQuesdata] = useState({});
  const {
    question,
    title,
    slug,
    option1,
    option2,
    option3,
    option4,
    answer,
    description,
    externalFile,
    // keywords,
    meta_title,
    meta_description,
    meta_keywords,
  } = quesdata;

  const fetchSingleQues = async () => {
    const response = await dispatch(getSingleQuestions(temp, params?.id));
    const userDataObj = response?.data;
    setQuesdata(userDataObj);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchSingleQues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div id="question-details-container" className="bg-white rounded p-3">
      <div className="user-view-form-section">
        {isLoading ? (
          <div className="d-flex justify-content-center align-items-center pt-5 pb-5">
            <Loader size="md" />
          </div>
        ) : (
          <div>
            <div className="d-flex justify-content-between">
              <div className="text-20-700 d-flex align-items-center">
                {(params?.type === "ask-questions" && "Ask Question Details") ||
                  (params?.type === "expert-solutions" &&
                    "Expert Solutions Details") ||
                  (params?.type === "flash-cards" && "Flash Cards Details")}
              </div>

              <Button
                value="Edit"
                btnStyle="btn-dashboard-primary"
                className="cps-18 cpe-18 text-15-500"
                onClick={() => {
                  (params?.type === "ask-questions" &&
                    navigate(
                      `/quiz-solutions/ask-questions/edit/${params?.id}`
                    )) ||
                    (params?.type === "expert-solutions" &&
                      navigate(
                        `/quiz-solutions/expert-solutions/edit/${params?.id}`
                      )) ||
                    (params?.type === "flash-cards" &&
                      navigate(
                        `/quiz-solutions/flash-cards/edit/${params?.id}`
                      ));
                }}
              />
            </div>
            <div className="use-personal-details-container mb-3 d-flex justify-content-between">
              <div className="profile-detail-sec">
                <div className="text-17-700 pt-3">Question</div>

                {params?.type === "ask-questions" && (
                  <>
                    <div className="pt-3">
                      <span className="text-16-600 color-black-60">
                        Title:{" "}
                      </span>
                      <span className="text-16-600 color-dashboard-primary">
                        {question}
                      </span>
                    </div>
                    <div className="pt-3">
                      <span className="text-16-600 color-black-60">
                        Slug URL:{" "}
                      </span>
                      <span className="text-16-600 color-dashboard-primary">
                        {slug}
                      </span>
                    </div>
                    <div className="pt-3 d-flex gap-3">
                      <span className="text-16-600 color-black-60">
                        Options:
                      </span>
                      <div>
                        <span className="text-16-600 color-black-60 d-flex gap-1">
                          Options A:
                          <span className="text-16-600 color-dashboard-primary">
                            {option1}
                          </span>
                        </span>
                        <span className="text-16-600 color-black-60 d-flex gap-1">
                          Options B :
                          <span className="text-16-600 color-dashboard-primary">
                            {option2}
                          </span>
                        </span>
                        <span className="text-16-600 color-black-60 d-flex gap-1">
                          Options C:
                          <span className="text-16-600 color-dashboard-primary">
                            {option3}
                          </span>
                        </span>
                        <span className="text-16-600 color-black-60 d-flex gap-1">
                          Options D:
                          <span className="text-16-600 color-dashboard-primary">
                            {option4}
                          </span>
                        </span>
                      </div>
                    </div>
                    <div className="pt-3">
                      <span className="text-16-600 color-black-60">
                        Answer:{" "}
                      </span>
                      <span className="text-16-600 color-dashboard-primary">
                        {answer}
                      </span>
                    </div>
                    {/* <div className="pt-3">
                      <span className="text-16-600 color-black-60">
                        Keywords:{" "}
                      </span>
                      <span className="text-16-600 color-dashboard-primary">
                        {keywords}
                      </span>
                    </div> */}
                  </>
                )}
                {params?.type === "expert-solutions" && (
                  <>
                    <div className="pt-3">
                      <span className="text-16-600 color-black-60">
                        Title:{" "}
                      </span>
                      <span className="text-16-600 color-dashboard-primary">
                        {title}
                      </span>
                    </div>
                    <div className="pt-3">
                      <span className="text-16-600 color-black-60">
                        Slug URL:
                      </span>
                      <span className="text-16-600 color-dashboard-primary">
                        {slug}
                      </span>
                    </div>
                    <div className="pt-3">
                      <span className="text-16-600 color-black-60">
                        Description:
                      </span>
                      <span className="text-16-600 color-dashboard-primary">
                        {description}
                      </span>
                    </div>
                    {/* <div className="pt-3">
                      <span className="text-16-600 color-black-60">
                        Keywords:{" "}
                      </span>
                      <span className="text-16-600 color-dashboard-primary">
                        {keywords}
                      </span>
                    </div> */}
                  </>
                )}
                {params?.type === "flash-cards" && (
                  <>
                    <div className="pt-3">
                      <span className="text-16-600 color-black-60">
                        Title:{" "}
                      </span>
                      <span className="text-16-600 color-dashboard-primary">
                        {title}
                      </span>
                    </div>
                    <div className="pt-3">
                      <span className="text-16-600 color-black-60">
                        Slug URL:
                      </span>
                      <span className="text-16-600 color-dashboard-primary">
                        {slug}
                      </span>
                    </div>
                    <div className="pt-3">
                      <span className="text-16-600 color-black-60 ">
                        Image/Pdf:
                      </span>
                      <span className="text-16-600 color-dashboard-primary ">
                        {externalFile?.type === "img" ? (
                          <img
                            src={externalFile}
                            alt="file-images"
                            style={{ width: "250px", height: "250px" }}
                          />
                        ) : (
                          <iframe
                            src={externalFile}
                            title="Answer Link"
                            className="w-100"
                            style={{
                              width: "100%",
                              height: "300px",
                            }}
                          />
                        )}
                      </span>
                    </div>
                    {/* <div className="pt-3">
                      <span className="text-16-600 color-black-60">
                        Keywords:{" "}
                      </span>
                      <span className="text-16-600 color-dashboard-primary">
                        {keywords}
                      </span>
                    </div> */}
                  </>
                )}
              </div>
            </div>
            <div className="use-education-details-container mb-3">
              <div className="text-17-700 pt-3">SEO Meta Data</div>
              <div className="pt-3">
                <span className="text-16-600 color-black-60">Title: </span>
                <span className="text-16-600 color-dashboard-primary">
                  {meta_title}
                </span>
              </div>
              <div className="pt-3">
                <span className="text-16-600 color-black-60">Description:</span>
                <span className="text-16-600 color-dashboard-primary">
                  {meta_description}
                </span>
              </div>
              <div className="pt-3">
                <span className="text-16-600 color-black-60">Keywords:</span>
                <span className="text-16-600 color-dashboard-primary">
                  {meta_keywords}
                </span>
              </div>
            </div>
          </div>
        )}

        {/* <div className="d-flex justify-content-center gap-5 mt-3">
          <Button
            value="Ok"
            btnStyle="btn-dashboard-primary"
            className="cps-40 cpe-40 text-17-500"
            onClick={() => {}}
          />
        </div> */}
      </div>
    </div>
  );
};

export default ViewDetails;
