import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { omit } from "lodash";
import Profile from "components/layouts/Profile";
import { getDialCode, titleCaseString } from "utils/helpers";
import { subscriptionReports } from "store/globalSlice";
import DisplayTable from "../DisplayTable";
import SubscriptionFilterPopup from "../SubscriptionFilterPopup";
import Button from "components/form/Button";
import { CSVLink } from "react-csv";

const SubscriptionReport = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [exportData, setExportData] = useState({
    data: [],
    loader: false,
  });
  const [showPopup, setShowPopup] = useState(false);
  const [tableData, setTableData] = useState({
    offset: 0,
    limit: 10,
    intervalLimit: 10,
    total: 0,
    loading: true,
    data: [],
    startDate: "",
    endDate: "",
    first_name: "",
    last_name: "",
    status: "",
    email: "",
    mobile_no: "",
    subscriptionType: "",
    countryCode: "US",
  });

  const exportHeader = [
    "First Name",
    "Last Name",
    "Email",
    "Mobile No",
    "Date Of Birth",
    "College",
    "College Location",
    "Country",
    "State",
    "City",
    "Zip",
    "Basic Education",
    "Subscription Plan",
    "Subscription Amount",
    // "Subscription Date",
  ];

  const ExportPlanData = async (returnData) => {
    let flagArray = [];
    flagArray = returnData?.map((o) => {
      return [
        o.User?.first_name || "",
        o.User?.last_name || "",
        o.User?.email || "",
        o.User?.mobile_no || "",
        o.User?.DOB || "",
        o.User?.college?.college_name || "",
        o.User?.college_location?.college_location || "",
        o.User?.country?.country || "",
        o.User?.state?.state || "",
        o.User?.city?.city || "",
        o.User?.zip || "",
        o.User?.basic_education || "",
        o.Subscription_plan?.name || "",
        o.Subscription_plan?.amount || "",
      ];
    });
    // console.log(flagArray);
    setExportData((prev) => {
      return { ...prev, data: flagArray, loader: false };
    });
  };
  // console.log(exportHeader);

  const fetchTableData = async (obj) => {
    setShowPopup(false);
    let payload = omit(obj, ["data", "loading", "total", "intervalLimit"]);
    const response = await dispatch(subscriptionReports(payload));
    // console.log(response);
    if (response?.status === 200) {
      setTableData((prev) => {
        return {
          ...prev,
          data: response?.data?.rows || [],
          total: response?.data?.count || 0,
          loading: false,
        };
      });
      ExportPlanData(response?.data?.rows);
    }
  };

  useEffect(() => {
    fetchTableData(tableData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const header = [
    {
      title: <div className="ps-3">Name</div>,
    },
    {
      title: <div>Contact Details</div>,
    },
    {
      title: <div className="text-nowrap">Subscription</div>,
    },
    {
      title: <div className="text-nowrap">Subscription Date</div>,
    },
    {
      title: <div className="text-nowrap text-center">Subscription Count</div>,
    },
    {
      title: <div className="text-nowrap">Status</div>,
    },
  ];
  const rowData = [];
  tableData?.data.forEach((elem) => {
    const countryType = elem?.currency === "INR";
    const { User } = elem;
    const {
      subscriptionPlan,
      status,
      subscriptionCount,
      start_date,
      subscription_amount,
    } = elem;
    const {
      id,
      first_name,
      last_name,
      email,
      country_code,
      mobile_no,
      profile_Image,
    } = User;

    let obj = [
      {
        value: (
          <div
            className="text-start ps-3 d-flex align-items-center gap-2 pointer text-nowrap"
            onClick={() => {
              navigate(`/users/view-details/${id}`);
            }}
          >
            <div>
              <Profile
                isRounded
                text={`${first_name} ${last_name}`}
                url={profile_Image}
                size="s-34"
              />
            </div>
            <div>
              <div className="text-17-500-27">
                {first_name && last_name
                  ? titleCaseString(`${first_name} ${last_name}`)
                  : "User"}
              </div>
              {/* <div className="text-15-500 color-black-60">
                Join: {moment(createdAt).format("DD-MMM-YYYY")}
              </div> */}
            </div>
          </div>
        ),
      },
      {
        value: (
          <div>
            <div>{email}</div>
            <div className="text-nowrap">{`${getDialCode(
              country_code || "-"
            )} ${mobile_no || "-"}`}</div>
          </div>
        ),
      },
      {
        value: (
          <div className="text-start align-items-center gap-2 pointer text-nowrap">
            <div>{subscriptionPlan}</div>
            <div>
              {countryType ? "₹" : "$"}
              {subscription_amount}
            </div>
          </div>
        ),
      },
      {
        value: (
          <div className="text-nowrap">
            {start_date ? moment(start_date).format("DD-MMM-YYYY") : "-"}
          </div>
        ),
      },
      {
        value: <div className="text-center">{subscriptionCount || 0}</div>,
      },
      {
        value: (
          <div className="text-nowrap">
            {status === 0 ? (
              <span className="badge  text-bg-secondary">Unsubscribed</span>
            ) : status === 1 ? (
              <span className="badge  text-bg-success">Subscribed</span>
            ) : status === 2 ? (
              <span className="badge  text-bg-danger">Canceled</span>
            ) : (
              <span className="badge text-bg-warning">Unsuccessful</span>
            )}
          </div>
        ),
      },
    ];
    rowData.push({ data: obj });
  });

  return (
    <div className="bg-white rounded mt-2 pt-1 pb-1">
      {showPopup && (
        <SubscriptionFilterPopup
          tableData={tableData}
          setTableData={setTableData}
          handelSubscription={fetchTableData}
          onHide={() => {
            setShowPopup(false);
          }}
        />
      )}
      <div className="d-flex gap-3 text-20-700 justify-content-between align-items-center mb-5 p-3">
        <div className="text-22-700">Subscription Report</div>

        <div className="d-flex gap-4">
          <Button
            value="Filter"
            btnStyle="btn-dashboard-primary"
            className="cps-30 cpe-30 pt-2 pb-2 text-15-500 h-auto"
            onClick={() => {
              setShowPopup(true);
            }}
          />
          {tableData.data.length > 0 && (
            <CSVLink
              data={exportData?.data}
              headers={exportHeader}
              filename={`Subscription Report.csv`}
              className="text-decoration-none"
            >
              <Button
                value="Export"
                btnStyle="btn-dashboard-primary"
                className="cps-30 cpe-30 pt-2 pb-2 text-15-500 h-auto"
              />
            </CSVLink>
          )}
        </div>
      </div>
      <DisplayTable
        tableData={tableData}
        setTableData={setTableData}
        fetchTableData={fetchTableData}
        header={header}
        rowData={rowData}
      />
    </div>
  );
};
export default SubscriptionReport;
