import Button from "components/form/Button/Button";
import DatePicker from "components/form/DatePicker/DatePicker";
import Dropdown from "components/form/Dropdown/Dropdown";
import { Formik } from "formik";
import React from "react";
import { Modal } from "react-bootstrap";

const UnSubscribeFilterPopup = ({ onHide }) => {
  const initialValues = {};
  return (
    <Modal
      show
      aria-labelledby="contained-modal-title-vcenter"
      centered
      size="md"
      className="report-popup"
    >
      <Modal.Body>
        <div className="user-report-view-form-section p-2">
          <div className="">
            <Formik enableReinitialize initialValues={initialValues}>
              {(props) => {
                const { values, handleChange } = props;
                return (
                  <form>
                    <div className="row">
                      <div className="d-flex justify-content-end gap-3 mb-5">
                        <Button
                          value="cancel"
                          btnStyle="btn-primary-darker-outline"
                          className="cps-20 cpe-20 text-17-500"
                          onClick={onHide}
                        />
                        <Button
                          value="Search"
                          btnStyle="btn-dashboard-primary"
                          className="cps-20 cpe-20 text-17-500"
                        />
                      </div>
                      <div className="col-md-6 cmb-22">
                        <DatePicker
                          label="FROM DATE"
                          id="startDate"
                          onChange={handleChange}
                          value={values?.startDate}
                        />
                      </div>
                      <div className="col-md-6 cmb-22">
                        <DatePicker
                          label="TO DATE"
                          id="endDate"
                          onChange={handleChange}
                          value={values?.endDate}
                        />
                      </div>
                      {/* <div className="col-md-4" /> */}
                      {/* <div className="col-md-4" /> */}
                      <div className="col-md-6 cmb-22">
                        <Dropdown
                          id="subscriptionType"
                          label="SUBSCRIPTION TYPE"
                          placeholder="Select Subscription Type"
                          onChange={handleChange}
                          value={values?.subscriptionType}
                          options={[
                            {
                              id: "0",
                              label: "Subscribed",
                            },
                            {
                              id: "1",
                              label: "Un-Subscribed",
                            },
                          ]}
                        />
                      </div>
                      <div className="col-md-6 cmb-22">
                        <Dropdown
                          id="paymentType"
                          label="PAYMENT TYPE"
                          placeholder="Select Payment Type"
                          onChange={handleChange}
                          value={values?.paymentType}
                          options={[
                            {
                              id: "0",
                              label: "Cancelled",
                            },
                            {
                              id: "1",
                              label: "Unsuccessful",
                            },
                          ]}
                        />
                      </div>
                    </div>
                  </form>
                );
              }}
            </Formik>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default UnSubscribeFilterPopup;
