import DownloadButton from "components/layouts/DownloadButton";
import SortButton from "components/layouts/SortButton";
import React from "react";
import Chart from "react-google-charts";
import { icons } from "utils/constants";
import "./CardBarChart.scss";

const CardBarChart = () => {
  return (
    <div id="bar-chart-container">
      <div className="bar-chart-block cmb-99">
        <div className="bar-chart-heading-block d-flex flex-wrap justify-content-between cmb-16">
          <div className="bar-heading text-20-700 color-dashboard-primary">
            Customer Bar Chart
          </div>
          <div className="bar-chart-buttons d-flex flex-wrap gap-3">
            <SortButton
              val="2022"
              icon={<img src={icons.Sort} alt="sort-icon" className="cps-12" />}
            />
            <DownloadButton
              icon={
                <img
                  src={icons.Download}
                  alt="download-icon"
                  className="cpe-7"
                />
              }
              val="DOWNLOAD REPORT"
            />
          </div>
        </div>

        <div className="bar-chart-card-block">
          <div className="bar-chart-title text-17-700 cpt-18 cps-103">
            <span className="color-dashboard-primary">
              Periodic Business Bar Chart
            </span>
            <span className="color-blue-60">(Services, Products & Brands)</span>
          </div>

          <div className="bar-chart-card">
            <Chart
              chartType="ColumnChart"
              data={[
                ["months", "Beauty services", "Beauty products", "Brand media"],
                ["Jan", 0, 0, 0],
                ["Feb", 0, 0, 0],
                ["Mar", 0, 0, 0],
                ["Apr", 0, 0, 0],
                ["May", 0, 0, 0],
                ["Jun", 0, 0, 0],
                ["Jul", 0, 0, 0],
                ["Aug", 0, 0, 0],
                ["Sep", 0, 0, 0],
                ["Oct", 0, 0, 0],
                ["Nov", 1400, 400, 200],
                ["Dec", 0, 0, 0],
              ]}
              options={{
                legend: { position: "none" },
                vAxis: { title: "Customers" },
              }}
            />

            <div>
              <div className="d-flex gap-3 justify-content-center">
                <div className="text-11-500 color-black-100 d-flex align-items-center gap-2">
                  <span
                    className="p-1"
                    style={{
                      backgroundColor: "blue",
                    }}
                  />
                  <span>Beauty services</span>
                </div>
                <div className="text-11-500 color-black-100 d-flex align-items-center gap-2">
                  <span
                    className="p-1"
                    style={{
                      backgroundColor: "blue",
                    }}
                  />
                  <span>Beauty services</span>
                </div>
                <div className="text-11-500 color-black-100 d-flex align-items-center gap-2">
                  <span
                    className="p-1"
                    style={{
                      backgroundColor: "blue",
                    }}
                  />
                  <span>Beauty services</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bar-chart-block cmb-99">
        <div className="bar-chart-heading-block d-flex flex-wrap justify-content-between cmb-16">
          <div className="bar-heading text-20-700 color-dashboard-primary">
            Total Number of Registered Services, Product & Brands
          </div>
          <div className="bar-chart-buttons d-flex flex-wrap gap-3">
            <SortButton
              val="2022"
              icon={<img src={icons.Sort} alt="sort-icon" className="cps-12" />}
            />
            <DownloadButton
              icon={
                <img
                  src={icons.Download}
                  alt="download-icon"
                  className="cpe-7"
                />
              }
              val="DOWNLOAD REPORT"
            />
          </div>
        </div>

        <div className="bar-chart-card-block">
          <div className="bar-chart-title text-17-700 cpt-18 cps-103">
            <span className="color-dashboard-primary">
              Registered Services, Products and Brands Bar Chart
            </span>
          </div>

          <div className="bar-chart-card">
            <Chart
              chartType="ColumnChart"
              data={[
                ["months", "Beauty services", "Beauty products", "Brand media"],
                ["Jan", 1400, 400, 200],
                ["Feb", 1200, 400, 200],
                ["Mar", 1400, 800, 300],
                ["Apr", 1500, 600, 200],
                ["May", 1300, 400, 400],
                ["Jun", 1200, 300, 200],
                ["Jul", 1300, 800, 200],
                ["Aug", 1000, 500, 300],
                ["Sep", 900, 800, 300],
                ["Oct", 1200, 800, 200],
                ["Nov", 1400, 400, 200],
                ["Dec", 1300, 600, 200],
              ]}
              options={{
                legend: { position: "none" },
                vAxis: { title: "Services, Products & Brands" },
              }}
            />
          </div>
        </div>
      </div>

      <div className="bar-chart-block cmb-99">
        <div className="bar-chart-heading-block d-flex flex-wrap justify-content-between cmb-16">
          <div className="bar-heading text-20-700 color-dashboard-primary">
            Financials
          </div>
          <div className="bar-chart-buttons d-flex flex-wrap gap-3">
            <SortButton
              val="2022"
              icon={<img src={icons.Sort} alt="sort-icon" className="cps-12" />}
            />
            <DownloadButton
              icon={
                <img
                  src={icons.Download}
                  alt="download-icon"
                  className="cpe-7"
                />
              }
              val="DOWNLOAD REPORT"
            />
          </div>
        </div>

        <div className="bar-chart-card-block">
          <div className="bar-chart-title text-17-700 cpt-18 cps-103">
            <span className="color-dashboard-primary">
              Periodic Financial Bar Chart
            </span>
            <span className="color-blue-60">(Services & Products)</span>
          </div>

          <div className="bar-chart-card">
            <Chart
              chartType="ColumnChart"
              data={[
                ["months", "Services", "Products"],
                ["Jan", 1400, 400],
                ["Feb", 1200, 400],
                ["Mar", 1400, 800],
                ["Apr", 1500, 600],
                ["May", 1300, 400],
                ["Jun", 1200, 300],
                ["Jul", 1300, 800],
                ["Aug", 1000, 500],
                ["Sep", 900, 800],
                ["Oct", 1200, 800],
                ["Nov", 1400, 400],
                ["Dec", 1300, 600],
              ]}
            />
          </div>
        </div>
      </div>

      <div className="bar-chart-block cmb-99">
        <div className="bar-chart-heading-block d-flex flex-wrap justify-content-between cmb-16">
          <div className="bar-heading text-20-700 color-dashboard-primary">
            Active & Inactive Clients
          </div>
          <div className="bar-chart-buttons d-flex flex-wrap gap-3">
            <SortButton
              val="2022"
              icon={<img src={icons.Sort} alt="sort-icon" className="cps-12" />}
            />
            <DownloadButton
              icon={
                <img
                  src={icons.Download}
                  alt="download-icon"
                  className="cpe-7"
                />
              }
              val="DOWNLOAD REPORT"
            />
          </div>
        </div>

        <div className="bar-chart-card-block">
          {/* <div className="bar-chart-title text-17-700 cpt-18">
            <span className="color-dashboard-primary">
              Periodic Business Bar Chart
            </span>
            <span className="color-blue-60">(Services, Products & Brands)</span>
          </div> */}

          <div className="bar-chart-card">
            <Chart
              chartType="ColumnChart"
              data={[
                ["months", "Active", "Inactive"],
                ["Jan", 1400, 400],
                ["Feb", 1200, 400],
                ["Mar", 1400, 800],
                ["Apr", 1500, 600],
                ["May", 1300, 400],
                ["Jun", 1200, 300],
                ["Jul", 1300, 800],
                ["Aug", 1000, 500],
                ["Sep", 900, 800],
                ["Oct", 1200, 800],
                ["Nov", 1400, 400],
                ["Dec", 1300, 600],
              ]}
            />
          </div>
        </div>
      </div>

      <div className="bar-chart-block cmb-99">
        <div className="bar-chart-heading-block d-flex flex-wrap justify-content-between cmb-16">
          <div className="bar-heading text-20-700 color-dashboard-primary">
            Active & Inactive Beauticians
          </div>
          <div className="bar-chart-buttons d-flex flex-wrap gap-3">
            <SortButton
              val="2022"
              icon={<img src={icons.Sort} alt="sort-icon" className="cps-12" />}
            />
            <DownloadButton
              icon={
                <img
                  src={icons.Download}
                  alt="download-icon"
                  className="cpe-7"
                />
              }
              val="DOWNLOAD REPORT"
            />
          </div>
        </div>

        <div className="bar-chart-card-block">
          {/* <div className="bar-chart-title text-17-700 cpt-18">
            <span className="color-dashboard-primary">
              Periodic Business Bar Chart
            </span>
            <span className="color-blue-60">(Services, Products & Brands)</span>
          </div> */}

          <div className="bar-chart-card">
            <Chart
              chartType="ColumnChart"
              data={[
                ["months", "Active", "Inactive"],
                ["Jan", 1400, 400],
                ["Feb", 1200, 400],
                ["Mar", 1400, 800],
                ["Apr", 1500, 600],
                ["May", 1300, 400],
                ["Jun", 1200, 300],
                ["Jul", 1300, 800],
                ["Aug", 1000, 500],
                ["Sep", 900, 800],
                ["Oct", 1200, 800],
                ["Nov", 1400, 400],
                ["Dec", 1300, 600],
              ]}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardBarChart;
