import Button from "components/form/Button/Button";
import React, { useEffect, useState } from "react";
import IpFilterPopup from "../IpFilterPopup";
import DisplayTable from "../DisplayTable";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ipReports } from "store/globalSlice";
import { omit } from "lodash";
import Profile from "components/layouts/Profile/Profile";
import { titleCaseString, getDialCode } from "utils/helpers";
import moment from "moment";
import { CSVLink } from "react-csv";

const IpReport = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showPopup, setShowPopup] = useState(false);
  const [exportData, setExportData] = useState({
    data: [],
    loader: false,
  });
  const [tableData, setTableData] = useState({
    offset: 0,
    limit: 10,
    intervalLimit: 10,
    total: 0,
    loading: true,
    data: [],
    startDate: "",
    endDate: "",
    first_name: "",
    last_name: "",
    email: "",
    mobile_no: "",
    Ip: "",
    countryCode: "US",
  });

  const exportHeader = [
    "First Name",
    "Last Name",
    "Email",
    "Mobile No",
    "Date Of Birth",
    "College",
    "College Location",
    "Country",
    "State",
    "City",
    "Zip",
    "Basic Education",
    "Ip",
  ];

  const ExportIpData = async (newData) => {
    let flagArray = [];
    flagArray = newData?.map((o) => {
      return [
        o.User?.first_name || "",
        o.User?.last_name || "",
        o.User?.email || "",
        o.User?.mobile_no || "",
        o.User?.DOB || "",
        o.User?.college?.college_name || "",
        o.User?.college_location?.college_location || "",
        o.User?.country?.country || "",
        o.User?.state?.state || "",
        o.User?.city?.city || "",
        o.User?.zip || "",
        o.User?.basic_education || "",
        o.Ip || "",
      ];
    });
    setExportData((prev) => {
      return { ...prev, data: flagArray, loader: false };
    });
  };

  const fetchTableData = async (obj) => {
    setShowPopup(false);
    let payload = omit(obj, ["data", "loading", "total", "intervalLimit"]);
    const response = await dispatch(ipReports(payload));
    setTableData((prev) => {
      return {
        ...prev,
        data: response?.data?.rows || [],
        total: response?.data?.count || 0,
        loading: false,
      };
    });
    ExportIpData(response?.data?.rows || []);
  };

  useEffect(() => {
    fetchTableData(tableData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const header = [
    {
      title: <div className="ps-3">Name</div>,
    },
    {
      title: <div className="text-nowrap">IP</div>,
    },
    {
      title: <div className="text-nowrap">Date of Birth</div>,
    },
    {
      title: <div>Contact Details</div>,
    },

    {
      title: <div className="text-nowrap">College Details</div>,
    },

    {
      title: <div className="text-nowrap">College Location</div>,
    },

    {
      title: (
        <div className="text-nowrap">Basic Education & Areas of Interset</div>
      ),
    },
  ];
  const rowData = [];
  const tempUser = { first_name: "", last_name: "", email: "", mobile_no: "" };
  tableData?.data.forEach((elem) => {
    const { User, Ip, createdAt } = elem;
    const {
      id,
      first_name,
      last_name,
      email,
      mobile_no,
      DOB,
      college,
      college_location,
      country,
      state,
      city,
      zip,
      basic_education,
      profile_Image,
      country_code,
    } = User || tempUser;
    let dialCode = getDialCode(country_code);
    let obj = [
      {
        value: (
          <div
            className="text-start ps-3 d-flex align-items-center gap-2 pointer text-nowrap"
            onClick={() => {
              if (first_name && last_name) {
                navigate(`/users/view-details/${id}`);
              }
            }}
          >
            <div>
              <Profile
                isRounded
                text={`${first_name} ${last_name}`}
                url={profile_Image}
                size="s-34"
              />
            </div>
            <div>
              <div className="text-17-500-27">
                {first_name && last_name
                  ? titleCaseString(`${first_name} ${last_name}`)
                  : "Visitor"}
              </div>
              {createdAt && (
                <div className="text-15-500 color-black-60">
                  Join:{" "}
                  {moment(createdAt, "YYYY-MM-DD hh:mm:ss").format(
                    "DD-MMM-YYYY"
                  )}
                </div>
              )}
            </div>
          </div>
        ),
      },
      {
        value: <div className="text-nowrap">{Ip}</div>,
      },
      {
        value: DOB ? (
          <span className="text-nowrap">
            {moment(DOB).format("DD-MM-YYYY")}
          </span>
        ) : (
          "-"
        ),
      },
      {
        value: (
          <div>
            <div>{email}</div>
            <div>
              {mobile_no
                ? `${dialCode ? `(${dialCode}) ${mobile_no}` : mobile_no}`
                : ""}
            </div>
          </div>
        ),
      },

      {
        value: (
          <div className="text-nowrap text-13-400">
            <div> {college?.college_name}</div>
            <div> {college_location?.college_location}</div>
          </div>
        ),
      },

      {
        value: (
          <div className="text-nowrap">
            {city?.city && state?.state && country?.country && zip !== null && (
              <div>
                {city?.city}, {state?.state}, {country?.country} - {zip}
              </div>
            )}
          </div>
        ),
      },

      {
        value: <div className="text-nowrap">{basic_education}</div>,
      },
    ];
    rowData.push({ data: obj });
  });
  return (
    <div className="bg-white rounded mt-2 pt-1 pb-1">
      {showPopup && (
        <IpFilterPopup
          tableData={tableData}
          setTableData={setTableData}
          handelIp={fetchTableData}
          onHide={() => {
            setShowPopup(false);
          }}
        />
      )}
      <div className="d-flex gap-3 text-20-700 justify-content-between align-items-center mb-5 p-3">
        <div className="text-22-700">IP Report</div>
        <div className="d-flex gap-4">
          <Button
            value="Filter"
            btnStyle="btn-dashboard-primary"
            className="cps-30 cpe-30 pt-2 pb-2 text-15-500 h-auto"
            onClick={() => {
              setShowPopup(true);
            }}
          />

          {tableData?.data.length > 0 && (
            <CSVLink
              data={exportData?.data}
              headers={exportHeader}
              filename={`Ip Report.csv`}
              className="text-decoration-none"
            >
              <Button
                value="Export"
                btnStyle="btn-dashboard-primary"
                className="cps-30 cpe-30 pt-2 pb-2 text-15-500 h-auto"
              />
            </CSVLink>
          )}
        </div>
      </div>
      <DisplayTable
        tableData={tableData}
        setTableData={setTableData}
        fetchTableData={fetchTableData}
        header={header}
        rowData={rowData}
      />
    </div>
  );
};

export default IpReport;
