import Button from "components/form/Button";
import Dropdown from "components/form/Dropdown";
import LabelText from "components/form/LabelText/LabelText";
import RadioButton from "components/form/RadioButton/RadioButton";
import TextEditor from "components/form/TextEditor";
import TextInput from "components/form/TextInput";
import { Formik } from "formik";
import { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { addPlan, throwSuccess } from "store/globalSlice";
import * as Yup from "yup";

const SubscriptionForm = () => {
  const dispatch = useDispatch();
  const formRef = useRef();
  const [btnLoading, setBtnLoading] = useState(false);
  // const [radioValue, setRadioValue] = useState("usd");
  const handelSave = async (values) => {
    setBtnLoading(true);
    const response = await dispatch(addPlan(values));
    // console.log("values", values);
    if (response?.status === 201) {
      dispatch(throwSuccess("Subscription Add Successfully"));
      if (formRef.current) {
        formRef.current.resetForm();
      }
    }
    setBtnLoading(false);
  };
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required."),
    category: Yup.string().required("Category is required."),
    amount: Yup.string().required("Amount is required."),
    description: Yup.string().required("Description is required."),
    // country_type: Yup.string().required("Country Type is required."),
  });
  const initialValues = {
    name: "",
    category: "",
    amount: "",
    description: "",
    country_type: "",
  };
  return (
    <div className="bg-white rounded p-3">
      <Formik
        innerRef={formRef}
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handelSave}
      >
        {(props) => {
          const {
            values,
            errors,
            handleChange,
            submitForm,
            handleReset,
            setFieldValue,
          } = props;
          const { name, category, amount, description, country_type } = values;

          return (
            <form className="row">
              <div className="cmb-25">
                <TextInput
                  label="Name(For Stripe)"
                  placeholder="Enter Name"
                  id="name"
                  value={name}
                  error={errors.name}
                  onChange={handleChange}
                />
              </div>
              <LabelText label="Country Type" />
              <div className="cmb-22 d-flex gap-3">
                <RadioButton
                  label="USD"
                  value="usd"
                  name="currency"
                  id="usd"
                  checked={country_type === "usd"}
                  onChange={(e) => {
                    // console.log(e.target.value);
                    setFieldValue("country_type", "usd");
                  }}
                />
                <RadioButton
                  label="INR"
                  value="INR"
                  name="currency"
                  id="inr"
                  checked={country_type === "INR"}
                  onChange={(e) => {
                    // console.log(e.target.value);
                    setFieldValue("country_type", "INR");
                  }}
                />
              </div>
              <div className="col-md-6 cmb-22">
                <Dropdown
                  label="Category"
                  placeholder="Select Category"
                  id="category"
                  value={category}
                  onChange={handleChange}
                  error={errors.category}
                  options={[
                    {
                      id: "Monthly",
                      label: "Monthly",
                    },
                    {
                      id: "Quarterly",
                      label: "Quarterly",
                    },
                    {
                      id: "Yearly",
                      label: "Yearly",
                    },
                  ]}
                />
              </div>
              <div className="col-md-6 cmb-22">
                <TextInput
                  // label="Amount($)"
                  label={country_type === "usd" ? "Amount($)" : "Amount(₹)"}
                  placeholder="Enter Amount"
                  id="amount"
                  value={amount}
                  error={errors.amount}
                  onChange={(e) => {
                    handleChange({
                      target: {
                        id: e.target.id,
                        value: e.target.value,
                        // value: e.target.value.replace(/\D/g, ""),
                      },
                    });
                  }}
                />
              </div>
              <div className="cmb-24">
                <TextEditor
                  label="Description"
                  placeholder="Description"
                  id="description"
                  value={description}
                  error={errors.description}
                  onChange={handleChange}
                />
              </div>
              <div className="d-flex justify-content-center gap-5 mt-3">
                <Button
                  value="Cancel"
                  btnStyle="btn-primary-darker-outline"
                  className="cps-40 cpe-40 text-17-500"
                  onClick={handleReset}
                />

                <Button
                  value="Submit"
                  btnStyle="btn-dashboard-primary"
                  className="cps-40 cpe-40 text-17-500"
                  onClick={(e) => {
                    if (!btnLoading) {
                      submitForm(e);
                    }
                  }}
                  btnLoading={btnLoading}
                />
              </div>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};
export default SubscriptionForm;
