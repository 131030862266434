import Button from "components/form/Button";
import Dropdown from "components/form/Dropdown";
import PasswordInput from "components/form/PasswordInput";
// import PhoneNumber from "components/form/PhoneNumber";
import TextInput from "components/form/TextInput";
import Loader from "components/layouts/Loader/Loader";
import { Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import {
  getSmtpSettingDetails,
  throwSuccess,
  updateSMTPSettingData,
} from "store/globalSlice";
import * as Yup from "yup";
import { isEqual } from "lodash";

const SMTPSetting = () => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);

  const [btnLoading, setBtnLoading] = useState(false);
  const [smtpData, setSmtpData] = useState({});
  const {
    id,
    host,
    port,
    smtpUserName,
    smtpPassword,
    senderMail,
    senderName,
    smtpEncryption,
  } = smtpData || {};
  const formRef = useRef();

  const fetchSMTPDetails = async () => {
    const response = await dispatch(getSmtpSettingDetails());

    const smtpDataObj = response?.data;
    setSmtpData(smtpDataObj);
    setIsLoading(false);
  };

  const handelSave = async (values) => {
    setBtnLoading(true);
    const response = await dispatch(updateSMTPSettingData(id, values));

    if (response?.status === 200) {
      dispatch(throwSuccess("Data Updated Successfully."));
    }
    setBtnLoading(false);
  };

  useEffect(() => {
    fetchSMTPDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validationSchema = Yup.object().shape({
    host: Yup.string().required("SMTP Host is required."),
    port: Yup.string().required("SMTP Port is required."),
    smtpUserName: Yup.string().required("SMTP Username is required."),
    smtpPassword: Yup.string().required("Password is required."),
    senderMail: Yup.string().required("Email is required."),
    // senderName: Yup.string().required("Sender Name is required."),
    smtpEncryption: Yup.string().required("Encryption is required."),
  });

  const initialValues = {
    host: host || "",
    port: port || "",
    smtpUserName: smtpUserName || "",
    smtpPassword: smtpPassword || "",
    senderMail: senderMail || "",
    senderName: senderName || "",
    smtpEncryption: smtpEncryption || "",
  };
  return (
    <div id="smtp-container" className="bg-white rounded p-3">
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center pt-5 pb-5">
          <Loader size="md" />
        </div>
      ) : (
        <>
          <div className="text-20-700 color-black-100 mb-3">SMTP Setting</div>
          <Formik
            innerRef={formRef}
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(e) => {
              handelSave(e);
            }}
          >
            {(props) => {
              const { values, errors, handleChange, submitForm, handleReset } =
                props;
              return (
                <form className="row">
                  <div className="col-md-6 cmb-22">
                    <TextInput
                      required
                      label="SMTP Host"
                      placeholder="Enter SMTP Host"
                      id="host"
                      value={values?.host}
                      onChange={handleChange}
                      error={errors.host}
                    />
                  </div>
                  <div className="col-md-6 cmb-22">
                    <TextInput
                      required
                      label="SMTP Port"
                      placeholder="Enter SMTP Port"
                      id="port"
                      value={values?.port}
                      onChange={handleChange}
                      error={errors.port}
                    />
                  </div>
                  <div className="col-md-6 cmb-22">
                    <TextInput
                      required
                      label="SMTP Username"
                      placeholder="Enter SMTP Username"
                      id="smtpUserName"
                      value={values?.smtpUserName}
                      onChange={handleChange}
                      error={errors.smtpUserName}
                    />
                  </div>
                  <div className="col-md-6 cmb-22">
                    <PasswordInput
                      required
                      label="SMTP Password"
                      placeholder="Enter SMTP Password"
                      id="smtpPassword"
                      value={values?.smtpPassword}
                      onChange={handleChange}
                      error={errors.smtpPassword}
                    />
                  </div>
                  <div className="col-md-6 cmb-22">
                    <TextInput
                      required
                      label="Sender Email Address"
                      placeholder="Enter Sender Email"
                      id="senderMail"
                      value={values?.senderMail}
                      onChange={handleChange}
                      error={errors.senderMail}
                    />
                  </div>
                  {/* <div className="col-md-6 cmb-22">
                    <TextInput
                      required
                      label="Sender Name"
                      placeholder="Enter Sender Name"
                      id="senderName"
                      value={values?.senderName}
                      error={errors.senderName}
                      onChange={handleChange}
                    />
                  </div> */}
                  <div className="col-md-6 cmb-22">
                    <Dropdown
                      isRequired
                      label="SMTP Encryption"
                      placeholder="Enter SMTP Encryption"
                      id="smtpEncryption"
                      value={values?.smtpEncryption}
                      error={errors.smtpEncryption}
                      onChange={handleChange}
                      options={[
                        {
                          id: "SSL",
                          label: "SSL",
                        },
                        {
                          id: "TSL",
                          label: "TSL",
                        },
                      ]}
                    />
                  </div>
                  <div className="d-flex justify-content-center gap-5 mt-3">
                    <Button
                      value="Cancel"
                      btnStyle="btn-primary-darker-outline"
                      className="cps-40 cpe-40 text-17-500"
                      onClick={handleReset}
                    />
                    <Button
                      id="save"
                      value="Save"
                      btnStyle="btn-dashboard-primary"
                      className="cps-40 cpe-40 text-17-500"
                      onClick={(e) => {
                        if (!btnLoading) {
                          submitForm(e);
                        }
                      }}
                      // onClick={handleSubmit}
                      btnLoading={btnLoading}
                      disabled={isEqual(values, initialValues)}
                    />
                  </div>
                </form>
              );
            }}
          </Formik>
        </>
      )}
    </div>
  );
};

export default SMTPSetting;
