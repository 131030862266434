import CreatableSelect from "react-select/creatable";
import Label from "../LabelText";
import "./CreatableDropdown.scss";

const CreatableDropdown = ({
  value,
  placeholder,
  options,
  error,
  id,
  optionKey,
  // optionValue,
  onChange,
  isLoading,
  disabled,
  name,
  // isClearable,
  label,
  isRequired,
  labelClass,
  // isSearchable = true,
  onCreateOption,
}) => {
  const optId = optionKey || "id";
  // const optVal = optionValue || "label";

  let fillValue = options.find((o) => `${o[optId]}` === `${value}`);
  if (!fillValue) {
    fillValue = null;
  }
  return (
    <div id="creatable-dropdown-container">
      {label && (
        <Label label={label} required={isRequired} className={labelClass} />
      )}
      <CreatableSelect
        onChange={(e) => {
          // console.log(e);
          onChange({
            target: {
              id: id,
              value: e ? e[optId] : "",
              data: e,
            },
          });
        }}
        value={fillValue}
        name={name}
        options={options}
        // getOptionLabel={(option) => option[optVal]}
        getOptionValue={(option) => option[optId]}
        placeholder={placeholder}
        isDisabled={disabled}
        isLoading={isLoading}
        className="basic-multi-select"
        classNamePrefix="select"
        hideSelectedOptions={false}
        closeMenuOnSelect={false}
        onCreateOption={onCreateOption}
      />

      {error && (
        <span className="text-13-400 pt-1">
          <span style={{ color: "red" }}>{error}</span>
        </span>
      )}
    </div>
  );
};
export default CreatableDropdown;
