import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  deleteUser,
  getUserDetails,
  throwSuccess,
  // throwSuccess,
  userChangeStaus,
} from "store/globalSlice";
import moment from "moment/moment";
import { useNavigate, useParams } from "react-router-dom";
import "./UserDetails.scss";
import Button from "components/form/Button";
import Loader from "components/layouts/Loader";
import StatusContainer from "components/layouts/StatusContainer/StatusContainer";
import DeleteConfirmPopup from "components/layouts/DeleteConfirmPopup/DeleteConfirmPopup";
import { getDialCode, titleCaseString } from "utils/helpers";

const UserDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [isUserDeletePopup, setIsUserDeletePopup] = useState(false);

  const [userdata, setUserdata] = useState({});
  const {
    // id,
    User_Ips,
    profile_Image,
    first_name,
    last_name,
    country_code,
    mobile_no,
    email,
    DOB,
    college,
    college_location,
    basic_education,
    mailing_address,
    country,
    state,
    city,
    zip,
    Orders,
    createdAt,
  } = userdata || {};

  // const handelDelete = async (id) => {
  //   const response = await dispatch(deleteUser(id));

  //   if (response?.status === 200) {
  //     navigate("/user");
  //   }
  // };

  const fetchUserData = async () => {
    const response = await dispatch(getUserDetails(params?.id));

    const userDataObj = response?.data;
    setUserdata(userDataObj);
    setIsLoading(false);
  };

  // const changeStatus = async () => {
  //   const response = await dispatch(userChangeStaus(params?.id));
  //   if (response?.status === 200) {
  //     dispatch(throwSuccess("Status Change Successfully."));
  //     fetchUserData();
  //   }
  // };

  useEffect(() => {
    fetchUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div id="user-view-container" className="bg-white rounded p-3">
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center pt-5 pb-5">
          <Loader size="md" />
        </div>
      ) : (
        <>
          <div className="d-flex justify-content-between">
            <div className="text-20-700 mb-3 gap-3 d-flex align-items-center">
              User Details
              <div className="button-block d-flex gap-3">
                <div className="status-block d-flex align-items-center gap-2  cps-18 cpe-18 pointer">
                  <StatusContainer
                    title="User"
                    data={userdata}
                    apiFunction={async () => {
                      return await dispatch(userChangeStaus(params?.id));
                    }}
                    handelSuccess={() => {
                      fetchUserData();
                    }}
                  />
                </div>

                {/* <Button
                  value="Status"
                  btnStyle="btn-dashboard-primary"
                  className="cps-18 cpe-18 text-15-500"
                  onClick={() => {
                    // console.log("ID", params?.id);
                    changeStatus(params?.id);
                  }}
                /> */}
              </div>
            </div>
            <div className="d-flex gap-3">
              <Button
                value="Edit"
                btnStyle="btn-dashboard-primary"
                className="cps-18 cpe-18 text-15-500"
                onClick={() => {
                  navigate(`/users/edit/${params?.id}`);
                }}
              />
              <Button
                value="Delete"
                btnStyle="btn-dashboard-primary"
                className="cps-18 cpe-18 text-15-500"
                onClick={() => {
                  setIsUserDeletePopup(true);
                }}
                // onClick={(e) => {
                //   e.preventDefault();
                //   handelDelete(params?.id);
                // }}
              />
            </div>
          </div>
          <div className="user-view-form-section">
            {isUserDeletePopup && (
              <DeleteConfirmPopup
                title="User"
                onHide={() => {
                  setIsUserDeletePopup(false);
                }}
                apiFunction={async () => {
                  await dispatch(deleteUser(params?.id));
                  dispatch(throwSuccess("User Delete Successfully."));
                  setIsUserDeletePopup(false);
                  navigate("/users");
                  // fetchTableData();
                  return;
                }}
              />
            )}
            <div className="">
              <div className="use-personal-details-container mb-3 d-flex justify-content-between">
                <div className="profile-detail-sec">
                  <div className="text-17-700 pt-3">Personal Info</div>
                  <div className="pt-3">
                    <span className="text-16-600 color-black-60">
                      FIRST NAME:{" "}
                    </span>
                    <span className="text-16-600 color-dashboard-primary">
                      {first_name}
                    </span>
                  </div>
                  <div className="pt-3">
                    <span className="text-16-600 color-black-60">
                      LAST NAME:{" "}
                    </span>
                    <span className="text-16-600 color-dashboard-primary">
                      {last_name}
                    </span>
                  </div>
                  <div className="pt-3">
                    <span className="text-16-600 color-black-60">EMAIL: </span>
                    <span className="text-16-600 color-dashboard-primary">
                      {email}
                    </span>
                  </div>
                  <div className="pt-3">
                    <span className="text-16-600 color-black-60">
                      MOBILE NO:{" "}
                    </span>
                    <span className="text-16-600 color-dashboard-primary">
                      {`${getDialCode(country_code || "-")} ${
                        mobile_no || "-"
                      }`}
                    </span>
                  </div>
                  <div className="pt-3">
                    <span className="text-16-600 color-black-60">
                      DATE OF BIRTH:{" "}
                    </span>
                    <span className="text-16-600 color-dashboard-primary">
                      {DOB}
                    </span>
                  </div>
                </div>
                <div className="profile-image-container pt3 mt-3">
                  <div className="img-sec">
                    {profile_Image !== null && (
                      <img
                        src={profile_Image}
                        alt="user-profile"
                        className="profile-image rounded-circle "
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="use-education-details-container mb-3">
                <div className="text-17-700 pt-3">Educational Info</div>
                <div className="pt-3">
                  <span className="text-16-600 color-black-60">
                    COLLEGE NAME:{" "}
                  </span>
                  <span className="text-16-600 color-dashboard-primary">
                    {titleCaseString(college?.college_name)}
                  </span>
                </div>
                <div className="pt-3">
                  <span className="text-16-600 color-black-60">
                    COLLEGE LOCATION:{" "}
                  </span>
                  <span className="text-16-600 color-dashboard-primary">
                    {titleCaseString(college_location?.college_location)}
                  </span>
                </div>
                <div className="pt-3">
                  <span className="text-16-600 color-black-60">
                    BASIC EDUCATION & AREAS OF INTEREST:{" "}
                  </span>
                  <span className="text-16-600 color-dashboard-primary">
                    {titleCaseString(basic_education)}
                  </span>
                </div>
              </div>
              <div className="use-education-details-container mb-3">
                <div className="text-17-700 pt-3">Address Info</div>
                <div className="pt-3">
                  <span className="text-16-600 color-black-60">
                    MAILING ADDRESS:{" "}
                  </span>
                  <span className="text-16-600 color-dashboard-primary">
                    {mailing_address}
                  </span>
                </div>
                <div className="pt-3">
                  <span className="text-16-600 color-black-60">COUNTRY: </span>
                  <span className="text-16-600 color-dashboard-primary">
                    {country?.country}
                  </span>
                </div>
                <div className="pt-3">
                  <span className="text-16-600 color-black-60">STATE: </span>
                  <span className="text-16-600 color-dashboard-primary">
                    {state?.state}
                  </span>
                </div>
                <div className="pt-3">
                  <span className="text-16-600 color-black-60">CITY: </span>
                  <span className="text-16-600 color-dashboard-primary">
                    {city?.city}
                  </span>
                </div>
                <div className="pt-3">
                  <span className="text-16-600 color-black-60">ZIP: </span>
                  <span className="text-16-600 color-dashboard-primary">
                    {zip}
                  </span>
                </div>
              </div>
              <div className="use-education-details-container mb-3">
                <div className="text-17-700 pt-3">Account Info</div>
                <div className="pt-3">
                  <span className="text-16-600 color-black-60">
                    Subscription:{" "}
                  </span>
                  <span className="text-16-600 color-dashboard-primary">
                    {Orders?.[0]?.subscriptionPlan}
                  </span>
                </div>
                <div className="pt-3">
                  <span className="text-16-600 color-black-60">Status: </span>
                  <span className="text-16-600 color-dashboard-primary">
                    {Orders?.[0]?.status === 1
                      ? "Active"
                      : Orders?.[0]?.status === 2
                      ? "Canceled"
                      : "Inactive"}
                  </span>
                </div>
                <div className="pt-3">
                  <span className="text-16-600 color-black-60">
                    Join Date:{" "}
                  </span>
                  <span className="text-16-600 color-dashboard-primary">
                    {moment(createdAt).format("DD-MMM-YYYY")}
                  </span>
                </div>
                <div className="pt-3">
                  <span className="text-16-600 color-black-60">
                    IP Address:{" "}
                  </span>
                  <span className="text-16-600 color-dashboard-primary">
                    {User_Ips?.[0]?.Ip}
                  </span>
                </div>
              </div>
            </div>
            {/* <div className="d-flex justify-content-center gap-5 mt-3">
          <Button
            value="Ok"
            btnStyle="btn-dashboard-primary"
            className="cps-40 cpe-40 text-17-500"
            onClick={() => {}}
          />
        </div> */}
          </div>
        </>
      )}
    </div>
  );
};

export default UserDetails;
