import DeleteConfirmPopup from "components/layouts/DeleteConfirmPopup/DeleteConfirmPopup";
import Table from "components/layouts/Table";
import moment from "moment";
import { useState } from "react";
import { Dropdown, Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { deleteSingleQues, throwSuccess } from "store/globalSlice";
import { icons } from "utils/constants";

const StudyToolsTable = ({
  qType,
  tableList,
  setTableList,
  fetchTableData,
  handelDeleteQues,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [popupData, setPopupData] = useState(null);
  const [isDeletePopup, setIsDeletePopup] = useState(false);
  const [quesDeleteID, setQuesDeleteID] = useState({});

  // const displayList = [];
  const header = [
    {
      title: <div className="ps-3">Question</div>,
    },
    {
      // title: <div>{qType === "ask-questions" ? "" : "Answer"}</div>,
      title: "Answer",
    },
    {
      title: <div className="text-nowrap">Created At</div>,
    },
    {
      title: "Action",
    },
  ];
  const rowData = [];

  tableList?.data.forEach((elem) => {
    const {
      id,
      question,
      title,
      answer,
      description,
      externalFile,
      createdAt,
      option1,
      option2,
      option3,
      option4,
    } = elem;
    let obj = [
      {
        value: <div className="ps-3">{question || title}</div>,
      },
      {
        value: (
          <div>
            {qType === "ask-questions" ? (
              <div
                className="p-1 bg-dashboard-primary color-white d-flex w-fit rounded ps-3 pe-3 pointer"
                onClick={() => {
                  let opt = "";
                  if (option1 === answer) {
                    opt = "a";
                  } else if (option2 === answer) {
                    opt = "b";
                  } else if (option3 === answer) {
                    opt = "c";
                  } else {
                    opt = "d";
                  }
                  let newAnswer = `Answer: (${opt}) ${answer}`;

                  setPopupData({
                    type: "text",
                    content: (
                      <>
                        <div>
                          {option1 && <div>a. {option1}</div>}
                          {option2 && <div>b. {option2}</div>}
                          {option3 && <div>c. {option3}</div>}
                          {option4 && <div>d. {option4}</div>}
                        </div>
                        <div className="mt-3">{newAnswer}</div>
                      </>
                    ),
                    question: `Question: ${question || title}`,
                  });
                }}
              >
                View
              </div>
            ) : (
              /* answer || "" */
              <div
                className="p-1 bg-dashboard-primary color-white d-flex w-fit rounded ps-3 pe-3 pointer"
                onClick={() => {
                  if (qType === "expert-solutions") {
                    setPopupData({
                      type: "text",
                      content: `Description: ${description}`,
                      question: `Question: ${question || title}`,
                    });
                  } else {
                    setPopupData({
                      type: "file",
                      content: externalFile,
                      question: `Question: ${question || title}`,
                    });
                  }
                }}
              >
                View
              </div>
            )}
          </div>
        ),
      },
      {
        value: (
          <div className="text-nowrap">
            {moment(createdAt).format("DD-MMM-YYYY")}
          </div>
        ),
      },
      {
        value: (
          <Dropdown align="end">
            <Dropdown.Toggle variant="" id="dropdown-basic">
              <img src={icons.threeDots} alt="options" className="pointer" />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item
                href=""
                className="d-flex align-items-center gap-2 mb-1"
                onClick={() => {
                  navigate(`/quiz-solutions/${qType}/${id}`);
                }}
              >
                <span>
                  <img src={icons.viewDetails} alt="details" />
                </span>
                <span className="mt-1">View</span>
              </Dropdown.Item>
              <Dropdown.Item
                href=""
                className="d-flex align-items-center gap-2 mb-1"
                onClick={() => {
                  navigate(`/quiz-solutions/${qType}/edit/${id}`);
                }}
              >
                <span>
                  <img src={icons.edit} alt="details" />
                </span>
                <span className="mt-1">Edit</span>
              </Dropdown.Item>
              <Dropdown.Item
                href=""
                className="d-flex align-items-center gap-2"
                onClick={() => {
                  setIsDeletePopup(true);
                  setQuesDeleteID(elem);
                }}
                // onClick={(e) => {
                //   e.preventDefault();
                //   handelDeleteQues(qType, id);
                // }}
              >
                <span>
                  <img src={icons.trash} alt="trash" />
                </span>
                <span className="mt-1">Delete</span>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        ),
      },
    ];
    rowData.push({ data: obj });
  });

  // displayList.forEach((elem) => {
  //   const { question, title, answer, description, externalFile, createdAt } =
  //     elem;
  //   let obj = [
  //     {
  //       value: <div className="ps-3">{question || title}</div>,
  //     },
  //     {
  //       value: (
  //         <div>
  //           {qType === "ask-questions" ? (
  //             answer || ""
  //           ) : (
  //             <div
  //               className="p-1 bg-dashboard-primary color-white d-flex w-fit rounded ps-3 pe-3 pointer"
  //               onClick={() => {
  //                 if (qType === "expert-solutions") {
  //                   setPopupData({
  //                     type: "text",
  //                     content: description,
  //                     question: question || title,
  //                   });
  //                 } else {
  //                   setPopupData({
  //                     type: "file",
  //                     content: externalFile,
  //                     question: question || title,
  //                   });
  //                 }
  //               }}
  //             >
  //               View
  //             </div>
  //           )}
  //         </div>
  //       ),
  //     },
  //     {
  //       value: moment(createdAt).format("DD-MMM-YYYY"),
  //     },
  //     {
  //       value: (
  //         <Dropdown align="end">
  //           <Dropdown.Toggle variant="" id="dropdown-basic">
  //             <img src={icons.threeDots} alt="options" className="pointer" />
  //           </Dropdown.Toggle>

  //           <Dropdown.Menu>
  //             <Dropdown.Item
  //               href=""
  //               className="d-flex align-items-center gap-2 mb-1"
  //             >
  //               <span>
  //                 <img src={icons.viewDetails} alt="details" />
  //               </span>
  //               <span className="mt-1">View Details</span>
  //             </Dropdown.Item>
  //             <Dropdown.Item
  //               href=""
  //               className="d-flex align-items-center gap-2"
  //             >
  //               <span>
  //                 <img src={icons.trash} alt="trash" />
  //               </span>
  //               <span className="mt-1">Delete</span>
  //             </Dropdown.Item>
  //           </Dropdown.Menu>
  //         </Dropdown>
  //       ),
  //     },
  //   ];
  //   rowData.push({ data: obj });
  // });

  return (
    <div className="bg-white rounded mt-2 pt-4 pb-3">
      {isDeletePopup && (
        <DeleteConfirmPopup
          title="Question"
          // deleteId={deleteId}
          onHide={() => {
            setIsDeletePopup(false);
          }}
          apiFunction={async () => {
            let temp;
            if (qType === "ask-questions") {
              temp = "askQuestion";
            } else if (qType === "expert-solutions") {
              temp = "expertSolution";
            } else if (qType === "flash-cards") {
              temp = "flashCard";
            }
            await dispatch(deleteSingleQues(temp, quesDeleteID.id));
            dispatch(throwSuccess("Question Delete Successfully."));
            setIsDeletePopup(false);
            fetchTableData();
            return;
          }}
          handelSuccess={() => {
            fetchTableData();
          }}
        />
      )}
      {popupData && (
        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show
          onHide={() => {
            setPopupData(null);
          }}
        >
          <Modal.Body>
            <div className="p-3">
              <div className="text-15-500">{popupData?.question}</div>
              <div className="mt-2">
                {popupData?.type === "text" ? (
                  popupData?.content
                ) : (
                  <iframe
                    src={popupData?.content}
                    title="Answer Link"
                    className="w-100"
                    style={{
                      width: "100%",
                      height: "300px",
                    }}
                  />
                )}
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}
      <Table
        isSearch
        header={header}
        rowData={rowData}
        isLoading={tableList?.loading}
        searchLabel="Search Using Question Or Keywords"
        searchText={tableList?.search}
        tableData={tableList}
        searchInputChange={(searchText) => {
          let oldData = { ...tableList, search: searchText, loading: true };
          setTableList(oldData);
          fetchTableData(oldData);
        }}
        changeOffset={(newOffset, newLimit = tableList.limit) => {
          let oldData = {
            ...tableList,
            offset: newOffset,
            limit: newLimit,
            loading: true,
          };
          setTableList(oldData);
          fetchTableData(oldData);
        }}
        isPagination
      />
    </div>
  );
};
export default StudyToolsTable;
