import { createSlice } from "@reduxjs/toolkit";
import { api } from "services";
import { storeLocalStorageData } from "utils/helpers";
const initialState = {
  userState: null,
  apiError: {},
  isSidebar: true,
  countryList: [],
  collegeList: [],
  locationList: [],
};
const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    setUserState(state, action) {
      state.userState = action.payload;
    },
    setUserData(state, action) {
      state.userData = action.payload;
    },
    setApiError(state, action) {
      state.apiError = action.payload;
    },
    setIsSidebar(state, action) {
      state.isSidebar = action.payload;
    },
    setCountryList(state, action) {
      state.countryList = action.payload;
    },
    setCollegeList(state, action) {
      state.collegeList = action.payload;
    },
    setLocationList(state, action) {
      state.locationList = action.payload;
    },
    reset: () => initialState,
  },
});

export const login = (formData) => async (dispatch) => {
  try {
    const res = await api.post("/auth/adminLogin", formData);
    const response = await dispatch(handelResponse(res));
    if (response?.status === 200) {
      storeLocalStorageData({ token: res?.token });
      dispatch(setUserState(JSON.stringify({ token: res?.token })));
    }
    return response;
  } catch (error) {
    return dispatch(handelCatch(error));
  }
};
export const handelLogout = () => async (dispatch) => {
  localStorage.clear();
  dispatch(setUserState(new Date().toLocaleString()));
  dispatch(reset());
};

//user
export const getAllUser = (queryParams) => async (dispatch) => {
  try {
    const res = await api.get(`/users/getAllUser${queryParams}`);
    return await dispatch(handelResponse(res));
  } catch (error) {
    return dispatch(handelCatch(error));
  }
};

export const getUserDetails = (id) => async (dispatch) => {
  try {
    const res = await api.get(`/users/getSingleUser/${id}`);
    const response = await dispatch(handelResponse(res));
    if (response?.status === 200) {
      storeLocalStorageData(response?.data);
      dispatch(setUserData(response?.data));
    }
    // return await dispatch(handelResponse(res));
    return response;
  } catch (error) {
    return dispatch(handelCatch(error));
  }
};

export const userUpdateDetails = (id, payload) => async (dispatch) => {
  try {
    const res = await api.put(`/users/updateUser/${id}`, payload);

    return await dispatch(handelResponse(res));
  } catch (error) {
    return dispatch(handelCatch(error));
  }
};

//user: Location
export const getAllCountires = () => async (dispatch) => {
  try {
    const res = await api.get("/location/getAllCountires");
    const response = await dispatch(handelResponse(res));
    if (response?.status === 200) {
      dispatch(setCountryList(response?.data || []));
    }
    return response;
  } catch (error) {
    return dispatch(handelCatch(error));
  }
};
export const getAllStates = (queryParams) => async (dispatch) => {
  try {
    const res = await api.get(`/location/getAllStates${queryParams}`);
    return await dispatch(handelResponse(res));
  } catch (error) {
    return dispatch(handelCatch(error));
  }
};
export const getAllCity = (queryParams) => async (dispatch) => {
  try {
    const res = await api.get(`/location/getAllCity${queryParams}`);
    return await dispatch(handelResponse(res));
  } catch (error) {
    return dispatch(handelCatch(error));
  }
};
export const getAllColleges = () => async (dispatch) => {
  try {
    const res = await api.get("/location/getAllColleges");
    const response = await dispatch(handelResponse(res));
    if (response?.status === 200) {
      dispatch(setCollegeList(response?.data || []));
    }
    return response;
  } catch (error) {
    return dispatch(handelCatch(error));
  }
};
export const getAllClgLocation = () => async (dispatch) => {
  try {
    const res = await api.get("/location/getAllClgLocation");
    const response = await dispatch(handelResponse(res));
    if (response?.status === 200) {
      dispatch(setLocationList(response?.data || []));
    }
    return response;
  } catch (error) {
    return dispatch(handelCatch(error));
  }
};

export const deleteUser = (id) => async (dispatch) => {
  try {
    const res = await api.delete(`/users/deleteUser/${id}`);

    return await dispatch(handelResponse(res));
  } catch (error) {
    return dispatch(handelCatch(error));
  }
};

export const userChangeStaus = (id) => async (dispatch) => {
  try {
    const res = await api.put(`/users/changeStaus/${id}`);
    return await dispatch(handelResponse(res));
  } catch (error) {
    return dispatch(handelCatch(error));
  }
};

//Study tool
export const addQuestions = (formData) => async (dispatch) => {
  try {
    const res = await api.post("/questions/addQuestions", formData);
    return await dispatch(handelResponse(res));
  } catch (error) {
    return dispatch(handelCatch(error));
  }
};
export const getAllAskQuestions = (queryParams) => async (dispatch) => {
  try {
    const res = await api.get(`/questions/getAllAskQuestions${queryParams}`);
    return await dispatch(handelResponse(res));
  } catch (error) {
    return dispatch(handelCatch(error));
  }
};
export const getAllExpertQuestions = (queryParams) => async (dispatch) => {
  try {
    const res = await api.get(`/questions/getAllExpertQuestions${queryParams}`);
    return await dispatch(handelResponse(res));
  } catch (error) {
    return dispatch(handelCatch(error));
  }
};
export const getAllFlashCardQuestions = (queryParams) => async (dispatch) => {
  try {
    const res = await api.get(
      `/questions/getAllFlashCardQuestions${queryParams}`
    );
    return await dispatch(handelResponse(res));
  } catch (error) {
    return dispatch(handelCatch(error));
  }
};

export const getSingleQuestions = (type, id) => async (dispatch) => {
  try {
    const res = await api.get(`/questions/getSingleQuestion/${type}/${id}`);

    const response = await dispatch(handelResponse(res));

    if (response?.status === 200) {
      storeLocalStorageData(response?.data);
      // dispatch(setQuesdata(response?.data));
    }
    // return await dispatch(handelResponse(res));
    return response;
  } catch (error) {
    return dispatch(handelCatch(error));
  }
};

export const quesDetailsUpdate = (id, formData) => async (dispatch) => {
  try {
    const res = await api.put(`/questions/updateQuestion/${id}`, formData);

    return await dispatch(handelResponse(res));
  } catch (error) {
    return dispatch(handelCatch(error));
  }
};

export const deleteSingleQues = (type, id) => async (dispatch) => {
  try {
    const res = await api.delete(`/questions/removeQuestion/${type}/${id}`);
    return await dispatch(handelResponse(res));
  } catch (error) {
    return dispatch(handelCatch(error));
  }
};

// export const getAllQuestions = () => async (dispatch) => {
//   try {
//     const res = await api.get("/questions/getAllQuestions");
//     return await dispatch(handelResponse(res));
//   } catch (error) {
//     return dispatch(handelCatch(error));
//   }
// };

//image gallery[upload]
export const imageUpload = (formData) => async (dispatch) => {
  try {
    const res = await api.post("/site/uploadFiles", formData);
    // console.log("res global slice", res);
    return await dispatch(handelResponse(res));
  } catch (error) {
    return dispatch(handelCatch(error));
  }
};

//image Gallery[get]
export const getImages = (payload) => async (dispatch) => {
  try {
    const res = await api.post("/site/getAllFiles", payload);
    // console.log("get global slice", res);
    return await dispatch(handelResponse(res));
  } catch (error) {
    return dispatch(handelCatch(error));
  }
};

//image Gallery[delete]
export const removeImages = (payload) => async (dispatch) => {
  try {
    const res = await api.delete("/site/removeFiles", payload);
    // console.log("delete global slice", res);
    return await dispatch(handelResponse(res));
  } catch (error) {
    return dispatch(handelCatch(error));
  }
};

//subscription
export const getSubscriptionPlan = () => async (dispatch) => {
  try {
    const res = await api.get("/plans/getSubscriptionPlan");
    return await dispatch(handelResponse(res));
  } catch (error) {
    return dispatch(handelCatch(error));
  }
};
export const addPlan = (formData) => async (dispatch) => {
  try {
    const res = await api.post("/plans/addPlan", formData);
    return await dispatch(handelResponse(res));
  } catch (error) {
    return dispatch(handelCatch(error));
  }
};
export const deletePlan = (id) => async (dispatch) => {
  try {
    const res = await api.delete(`/plans/deletePlan/${id}`);
    return await dispatch(handelResponse(res));
  } catch (error) {
    return dispatch(handelCatch(error));
  }
};

export const getSinglePlan = (id) => async (dispatch) => {
  try {
    const res = await api.get(`/plans/getSinglePlan/${id}`);

    const response = await dispatch(handelResponse(res));

    if (response?.status === 200) {
      storeLocalStorageData(response?.data);
    }
    // return await dispatch(handelResponse(res));
    return response;
  } catch (error) {
    return dispatch(handelCatch(error));
  }
};

export const editPlan = (id, payload) => async (dispatch) => {
  try {
    const res = await api.put(`/plans/updatePlan/${id}`, payload);

    return await dispatch(handelResponse(res));
  } catch (error) {
    return dispatch(handelCatch(error));
  }
};

export const planChangeStaus = (id) => async (dispatch) => {
  try {
    const res = await api.put(`/plans/changePlanStauts/${id}`);
    return await dispatch(handelResponse(res));
  } catch (error) {
    return dispatch(handelCatch(error));
  }
};

// export const showPlan = () => async (dispatch) => {
//   try {
//     const res = await api.get(`/plans/getSubscriptionPlan`);
//     return await dispatch(handelResponse(res));
//   } catch (error) {
//     return dispatch(handelCatch(error));
//   }
// }

//contact us
export const getContactDetails = (payload) => async (dispatch) => {
  try {
    const res = await api.post(`/contactus/getAllContact`, payload);

    return await dispatch(handelResponse(res));
  } catch (error) {
    return dispatch(handelCatch(error));
  }
};

export const deleteContactDetail = (payload) => async (dispatch) => {
  try {
    const res = await api.delete(`/contactus/removeContact`,payload);

    return await dispatch(handelResponse(res));
  } catch (error) {}
};

//payment history
export const getAllOrdersDetail = (payload) => async (dispatch) => {
  try {
    const res = await api.post(`/orders/getAllOrders`,payload);
    return await dispatch(handelResponse(res));
  } catch (error) {
    return dispatch(handelCatch(error));
  }
};

//site setting
export const getSiteSetingDetails = () => async (dispatch) => {
  try {
    const res = await api.get("/site/getSiteData");

    return await dispatch(handelResponse(res));
  } catch (error) {
    return dispatch(handelCatch(error));
  }
};

export const updateSiteSetingDetails = (id, formData) => async (dispatch) => {
  try {
    const res = await api.put(`/site/updateSiteData/${id}`, formData);

    return await dispatch(handelResponse(res));
  } catch (error) {
    return dispatch(handelCatch(error));
  }
};

//smtp setting
export const getSmtpSettingDetails = () => async (dispatch) => {
  try {
    const res = await api.get("/smtp/getSmtpData/");
    return await dispatch(handelResponse(res));
  } catch (error) {
    return dispatch(handelCatch(error));
  }
};

export const updateSMTPSettingData = (id, payload) => async (dispatch) => {
  try {
    const res = await api.put(`/smtp/updateSmtpData/${id}`, payload);

    return await dispatch(handelResponse(res));
  } catch (error) {
    return dispatch(handelCatch(error));
  }
};

// Reports[Users];
export const searchUserData = (payload) => async (dispatch) => {
  try {
    const res = await api.post("/users/userReports", payload);
    return await dispatch(handelResponse(res));
  } catch (error) {
    return dispatch(handelCatch(error));
  }
};

// Reports - subscription
export const subscriptionReports = (payload) => async (dispatch) => {
  try {
    const res = await api.post("/orders/subscriptionReports", payload);
    return await dispatch(handelResponse(res));
  } catch (error) {
    return dispatch(handelCatch(error));
  }
};

// Reports - subscribe
// export const subscribeReports = (payload) => async (dispatch) => {
//   try {
//     const res = await api.post("/orders/subscribedReports", payload);
//     return await dispatch(handelResponse(res));
//   } catch (error) {
//     return dispatch(handelCatch(error));
//   }
// };

// Reports - ip
export const ipReports = (payload) => async (dispatch) => {
  try {
    const res = await api.post("/users/userIpReports", payload);
    return await dispatch(handelResponse(res));
  } catch (error) {
    return dispatch(handelCatch(error));
  }
};

export const throwSuccess = (msg) => async (dispatch) => {
  let message = msg || "Operation Done Successfully.";
  dispatch(
    setApiError({
      show: true,
      message: message,
      type: "success",
    })
  );
};

export const throwError = (res) => async (dispatch) => {
  let message = res?.message;
  message = message || "Something went wrong!";
  dispatch(
    setApiError({
      show: true,
      message: message,
      type: "danger",
    })
  );
};
export const handelResponse = (res) => async (dispatch) => {
  let returnValue = null;
  const status = res?.status;
  switch (status) {
    case 200:
      returnValue = res;
      break;
    case 201:
      returnValue = res;
      break;
    case 400:
      dispatch(throwError(res));
      returnValue = null;
      break;
    case 401:
      dispatch(handelLogout());
      break;
    case 403:
      dispatch(handelLogout());
      break;
    default:
      throwError({ message: "Something went wrong!" });
      returnValue = null;
      break;
  }

  return returnValue;
};
export const handelCatch = (error) => async (dispatch) => {
  let status = error?.response?.status;
  let messsage = error?.response?.data?.message;
  if (status === 401 || status === 403) {
    dispatch(handelLogout());
  } else {
    messsage = messsage || "Something went wrong!";
    dispatch(
      setApiError({
        show: true,
        message: messsage,
        type: "danger",
      })
    );
  }
  return null;
};
export const {
  setUserState,
  setUserData,
  setApiError,
  setIsSidebar,
  reset,
  setCountryList,
  setCollegeList,
  setLocationList,
} = globalSlice.actions;

export default globalSlice.reducer;
