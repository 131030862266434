import Button from "components/form/Button";
import DatePicker from "components/form/DatePicker";
import PhoneNumber from "components/form/PhoneNumber";
import TextInput from "components/form/TextInput";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllCity,
  getAllClgLocation,
  getAllColleges,
  getAllCountires,
  getAllStates,
  getUserDetails,
  throwError,
  throwSuccess,
  userUpdateDetails,
} from "store/globalSlice";
import moment from "moment/moment";
import { useParams } from "react-router-dom";
import Dropdown from "components/form/Dropdown";
import { Formik } from "formik";
import * as Yup from "yup";
import { isEqual, unionBy, map, cloneDeep, omit, lowerCase } from "lodash";
import "./UserEdit.scss";
import { objectToFormData } from "utils/helpers";
import CreatableDropdown from "components/form/CreatableDropdown";
import TextArea from "components/form/TextArea";
import Loader from "components/layouts/Loader";
import ImageCropper from "components/layouts/ImageCropper/ImageCropper";

const UserEdit = () => {
  const dispatch = useDispatch();
  const params = useParams();

  const { countryList, collegeList, locationList } = useSelector((state) => ({
    userData: state.global.userData,
    countryList: state.global.countryList,
    collegeList: state.global.collegeList,
    locationList: state.global.locationList,
  }));

  // const [tempData, setTempData] = useState(null);
  const [tempList, setTempList] = useState({
    collegeList: [],
    locationList: [],
  });
  const [timer, setTimer] = useState("");
  const [cropFile, setCropFile] = useState(null);
  const [stateList, setStateList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);
  const [cityData, setCityData] = useState({
    list: [],
    search: "",
    total: 0,
    offset: 0,
    limit: 50,
    state_id: "",
    isLoading: false,
  });

  const [loader, setLoader] = useState({
    college: true,
    location: true,
    country: true,
    state: false,
    city: false,
  });

  const [userdata, setUserdata] = useState({});
  const {
    first_name,
    last_name,
    mobile_no,
    email,
    DOB,
    mailing_address,
    country_id,
    state_id,
    city_id,
    college_location,
    college,
    zip,
    basic_education,
    city,
  } = userdata || {};

  const handelSave = async (values) => {
    setBtnLoading(true);
    let payload = objectToFormData(omit(values, ["email"]));

    const response = await dispatch(userUpdateDetails(params?.id, payload));
    if (response?.status === 200) {
      dispatch(throwSuccess("Profile Updated Successfully."));
      await dispatch(getUserDetails(params?.id));
    }
    setBtnLoading(false);
  };

  const fetchUserData = async () => {
    const response = await dispatch(getUserDetails(params?.id));

    const userDataObj = response?.data;
    setUserdata(userDataObj);
    setIsLoading(false);
  };

  const fetchStateFromCountry = async (countryID) => {
    setLoader((prev) => {
      return { ...prev, state: true };
    });
    const response = await dispatch(getAllStates(`?country_id=${countryID}`));
    setStateList(response?.data || []);
    setLoader((prev) => {
      return { ...prev, state: false };
    });
  };

  const handleSearchCity = (e) => {
    let time = timer;
    clearTimeout(time);
    time = setTimeout(() => {
      let oldData = cloneDeep({
        ...cityData,
        offset: 0,
        search: lowerCase(e),
        isLoading: true,
        list: [],
      });
      setCityData(oldData);
      fetchCityFromState(oldData, true);
    }, 800);
    setTimer(time);
  };
  const handelScrollCity = () => {
    if (cityData.list.length < cityData.total) {
      let oldData = cloneDeep({
        ...cityData,
        offset: cityData.offset + cityData?.limit,
        isLoading: true,
      });
      setCityData(oldData);
      fetchCityFromState(oldData);
    }
  };

  const fetchCityFromState = async (obj, isReset) => {
    const queryString = new URLSearchParams(
      omit({ ...obj }, ["list", "total", "isLoading"])
    ).toString();

    const response = await dispatch(getAllCity(`?${queryString}`));
    setCityData((prev) => {
      let resData = response?.data?.rows || [];
      let listData = isReset ? resData : [...prev.list, ...resData];
      let existingList = city?.id ? [city] : [];

      return {
        ...prev,
        list: unionBy(listData, existingList, "id"),
        total: response?.data?.count || 0,
        isLoading: false,
      };
    });
  };

  const initAPI = async () => {
    await dispatch(getAllColleges());
    await dispatch(getAllClgLocation());
    await dispatch(getAllCountires());
    setLoader((prev) => {
      return { ...prev, college: false, location: false, country: false };
    });
  };

  useEffect(() => {
    if (state_id) {
      let oldData = cloneDeep({
        ...cityData,
        state_id: state_id,
      });
      setCityData(oldData);
      fetchCityFromState(oldData, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state_id]);

  useEffect(() => {
    if (country_id) {
      fetchStateFromCountry(country_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country_id]);

  useEffect(() => {
    initAPI();
    fetchUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validationSchema = Yup.object().shape({
    first_name: Yup.string()
      .required("First name is required.")
      .matches(/^[aA-zZ\s]+$/, "Only alphabets allowed."),
    last_name: Yup.string()
      .required("Last name is required.")
      .matches(/^[aA-zZ\s]+$/, "Only alphabets allowed."),
    mobile_no: Yup.string().required("Mobile is required."),
    email: Yup.string()
      .required("Email is required.")
      .matches(/[a-z0-9]+@[a-z]+\.[a-z]{2,3}/, "Email must be a valid email"),
    DOB: Yup.string().test(
      "DOB",
      "User must be atleast 18 years of age!",
      (value) => {
        if (value) {
          return moment().diff(moment(value), "years") >= 18;
        } else {
          return Yup.string();
        }
      }
    ),
  });

  const initialValues = {
    first_name: first_name || "",
    last_name: last_name || "",
    mobile_no: mobile_no || "",
    email: email || "",
    DOB: DOB || "",
    college_name: college?.college_name || "",
    college_location: college_location?.college_location || "",
    mailing_address: mailing_address || "",
    country_id: country_id || "",
    state_id: state_id || "",
    city_id: city_id || "",
    zip: zip || "",
    basic_education: basic_education || "",
    profile_Image: "",
    country_code: "IN",
  };

  return (
    <div id="user-edit-container" className="bg-white rounded p-3">
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center pt-5 pb-5">
          <Loader size="md" />
        </div>
      ) : (
        <>
          <div className="text-20-700 mb-3">User Details</div>

          <div className="user-view-form-section">
            <div className="">
              <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(e) => {
                  handelSave(e);
                }}
              >
                {(props) => {
                  const {
                    values,
                    errors,
                    handleChange,
                    handleSubmit,
                    setFieldError,
                    setFieldValue,
                    handleReset,
                  } = props;

                  return (
                    <form>
                      {cropFile && (
                        <ImageCropper
                          onHide={() => {
                            setCropFile(null);
                          }}
                          fileURL={cropFile}
                          handelCropImage={(file) => {
                            setFieldValue("profile_Image", file || "");
                          }}
                        />
                      )}
                      <div className="row">
                        <div className="file-upload-block cmb-22">
                          <span className="file-upload-input">
                            <div className="choose_file">
                              <span className="btn-block pointer text-14-600 bg-dashboard-primary color-white text-nowrap">
                                Upload Photo
                              </span>
                              <input
                                name="Select File"
                                type="file"
                                onChange={(e) => {
                                  var files = e?.target?.files;
                                  var extension = files[0]?.type;

                                  if (
                                    [
                                      "image/png",
                                      "image/jpg",
                                      "image/jpeg",
                                    ].includes(extension)
                                  ) {
                                    if (e?.target?.files[0]) {
                                      setCropFile(
                                        URL.createObjectURL(e?.target?.files[0])
                                      );
                                    }
                                  } else {
                                    dispatch(
                                      throwError(
                                        "Invalid file select. (PNG || JPG || JPEG)"
                                      )
                                    );
                                  }
                                }}
                              />
                            </div>
                          </span>
                        </div>
                        <div className="col-md-6"></div>
                        <div className="col-md-6 cmb-22">
                          <TextInput
                            required
                            label="FIRST NAME"
                            placeholder="First name"
                            id="first_name"
                            value={values.first_name}
                            error={errors.first_name}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="col-md-6">
                          <TextInput
                            required
                            label="LAST NAME"
                            placeholder="Last name"
                            id="last_name"
                            value={values.last_name}
                            error={errors.last_name}
                            onChange={handleChange}
                          />
                        </div>

                        <div className="col-md-4 cmb-22">
                          <PhoneNumber
                            required
                            id="mobile_no"
                            label="MOBILE NO"
                            placeholder="Mobile number"
                            value={values?.mobile_no}
                            error={errors.mobile_no}
                            country_code={values?.country_code}
                            onCodeChange={(e) => {
                              handleChange(e);
                              setFieldValue("mobile_no", "");
                            }}
                            onChange={handleChange}
                            onBlur={(isError) => {
                              let newerror = isError
                                ? "Invalid mobile number"
                                : "";
                              setFieldError("mobile_no", newerror);
                            }}
                          />
                        </div>
                        <div className="col-md-4">
                          <TextInput
                            required
                            label="EMAIL"
                            placeholder="user123@gmail.com"
                            id="email"
                            value={values?.email}
                            error={errors.email}
                            onChange={handleChange}
                            disabled
                          />
                        </div>
                        <div className="col-md-4">
                          <DatePicker
                            label="DOB"
                            placeholder="DOB"
                            id="DOB"
                            value={values?.DOB}
                            error={errors?.DOB}
                            onChange={handleChange}
                            maxDate={moment()}
                          />
                        </div>
                        <div className="col-md-8 cmb-22">
                          <CreatableDropdown
                            label="COLLEGE NAME"
                            placeholder="College name"
                            options={unionBy(
                              map(collegeList, (e) => {
                                return { ...e, label: e.college_name };
                              }),
                              tempList.collegeList,
                              "label"
                            )}
                            // optionValue="college_name"
                            optionKey="college_name"
                            id="college_name"
                            value={values.college_name || null}
                            onChange={handleChange}
                            isLoading={loader.college}
                            onCreateOption={(e) => {
                              setTempList((prev) => {
                                let oldList = prev.collegeList;
                                oldList.push({ label: e, college_name: e });
                                return { ...prev, collegeList: oldList };
                              });
                              setFieldValue("college_name", e);
                            }}
                          />
                          {/* <Dropdown label="COLLEGE NAME" options={[]} value="" /> */}
                        </div>
                        <div className="col-md-4">
                          <CreatableDropdown
                            label="COLLEGE LOCATION"
                            placeholder="College location"
                            options={unionBy(
                              map(locationList, (e) => {
                                return { ...e, label: e.college_location };
                              }),
                              tempList.locationList,
                              "label"
                            )}
                            // optionValue="college_location"
                            optionKey="college_location"
                            id="college_location"
                            value={values.college_location}
                            onChange={handleChange}
                            isLoading={loader.location}
                            onCreateOption={(e) => {
                              setTempList((prev) => {
                                let oldList = prev.locationList;
                                oldList.push({ label: e, college_location: e });
                                return { ...prev, locationList: oldList };
                              });
                              setFieldValue("college_location", e);
                            }}
                          />
                          {/* <Dropdown label="COLLEGE LOCATION" options={[]} value="" /> */}
                        </div>

                        <div className="col-md-12 cmb-22">
                          <TextArea
                            label="MAILING ADDRESS"
                            placeholder="Mailing address"
                            id="mailing_address"
                            value={values.mailing_address}
                            onChange={handleChange}
                          />
                        </div>

                        <div className="col-md-6 cmb-22">
                          <Dropdown
                            label="COUNTRY"
                            placeholder="Select country"
                            options={countryList}
                            optionValue="country"
                            id="country_id"
                            value={values.country_id}
                            isLoading={loader.country}
                            onChange={(e) => {
                              setFieldValue("state_id", "");
                              setFieldValue("city_id", "");
                              if (e?.target?.value) {
                                fetchStateFromCountry(e?.target?.value);
                              }
                              handleChange(e);
                            }}
                          />
                        </div>

                        <div className="col-md-6">
                          <Dropdown
                            label="STATE"
                            placeholder="Select state"
                            options={stateList}
                            id="state_id"
                            value={values.state_id}
                            optionValue="state"
                            isLoading={loader.state}
                            onChange={(e) => {
                              if (e?.target?.value) {
                                let oldData = cloneDeep({
                                  ...cityData,
                                  list: [],
                                  search: "",
                                  isLoading: true,
                                  state_id: e?.target?.value,
                                });
                                setCityData(oldData);
                                fetchCityFromState(oldData, true);
                              }
                              handleChange(e);
                            }}
                            disabled={!values.country_id}
                          />
                        </div>
                        <div className="col-md-6 cmb-22">
                          <Dropdown
                            label="CITY"
                            placeholder="Select city"
                            options={cityData?.list}
                            optionValue="city"
                            id="city_id"
                            value={values.city_id}
                            onChange={handleChange}
                            isLoading={cityData.isLoading}
                            disabled={!values.state_id}
                            onInputChange={handleSearchCity}
                            onMenuScrollToBottom={handelScrollCity}
                          />
                        </div>

                        <div className="col-md-6">
                          <TextInput
                            label="ZIP"
                            placeholder="Zip code"
                            id="zip"
                            value={values.zip}
                            onChange={handleChange}
                          />
                        </div>

                        <div className="col-md-12 cmb-22">
                          <TextArea
                            label="BASIC EDUCATION & AREAS OF INTEREST"
                            placeholder="Enter your basic education & areas of interest separated by commas"
                            id="basic_education"
                            value={values.basic_education}
                            onChange={handleChange}
                          />
                        </div>

                        {/* <div className="col-md-4 cmb-22">
                      <TextInput
                        label="Subscription"
                        id="Subscription_plan"
                        value={Subscription_plan?.name}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-md-4">
                      <TextInput
                        label="Status"
                        id="status"
                        value={status === 1 ? "Active" : "InActive"}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-md-4">
                      <TextInput
                        label="Join Date"
                        id="join_date"
                        value={moment(createdAt).format("DD-MMM-YYYY")}
                      />
                    </div> */}
                        <div className="d-flex justify-content-center gap-5 mt-3">
                          <Button
                            value="Cancel"
                            btnStyle="btn-primary-darker-outline"
                            className="cps-40 cpe-40 text-17-500"
                            onClick={handleReset}
                          />
                          <Button
                            value="Submit"
                            btnStyle="btn-dashboard-primary"
                            className="cps-40 cpe-40 text-17-500"
                            onClick={handleSubmit}
                            btnLoading={btnLoading}
                            disabled={isEqual(values, initialValues)}
                          />

                          {/* <Button
            value="Submit"
            btnStyle="btn-dashboard-primary"
            className="cps-40 cpe-40 text-17-500"
            onClick={() => {
              // updateUserData();
            }}
          /> */}
                        </div>
                      </div>
                    </form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </>
      )}

      {/* <form> */}
      {/* <div className="col-md-6 cmb-22">
              <TextInput
                label="FIRST NAME"
                value={first_name}
                onChange={() => {}}
              />
            </div>
            <div className="col-md-6">
              <TextInput
                label="LAST NAME"
                value={last_name}
                onChange={() => {}}
              />
            </div> */}

      {/* <div className="col-md-4 cmb-22">
            <PhoneNumber
              label="MOBILE NO"
              value={mobile_no}
              onChange={() => {}}
              onBlur={() => {}}
            />
          </div> */}
      {/* <div className="col-md-4">
            <TextInput label="EMAIL" value={email} onChange={() => {}} />
          </div> */}
      {/* <div className="col-md-4">
            <DatePicker label="DOB" value={DOB} onChange={() => {}} />
          </div> */}
      {/* <div className="col-md-8 cmb-22">
            <Dropdown label="COLLEGE NAME" options={[]} value="" />
          </div> */}
      {/* <div className="col-md-4">
            <Dropdown label="COLLEGE LOCATION" options={[]} value="" />
          </div> */}

      {/* <div className="col-md-12 cmb-22">
            <TextInput label="MAILING ADDRESS" />
          </div> */}

      {/* <div className="col-md-6 cmb-22">
            <Dropdown label="COUNTRY" options={[]} value="" />
          </div> */}

      {/* <div className="col-md-6">
            <Dropdown label="STATE" options={[]} value="" />
          </div> */}
      {/* <div className="col-md-6 cmb-22">
            <TextInput label="CITY" />
          </div> */}

      {/* <div className="col-md-6"><TextInput label="ZIP" /></div> */}

      {/* <div className="col-md-12 cmb-22">
            <TextInput label="BASIC EDUCATION & AREAS OF INTEREST" />
          </div> */}

      {/* <div className="col-md-4 cmb-22">
            <TextInput
              label="Subscription"
              value={Subscription_plan?.name}
              onChange={() => {}}
            />
          </div> */}
      {/* <div className="col-md-4">
            <TextInput
              label="Status"
              value={status === 1 ? "Active" : "InActive"}
              onChange={() => {}}
            />
          </div> */}
      {/* <div className="col-md-4">
            <TextInput
              label="Join Date"
              value={moment(createdAt).format("DD-MMM-YYYY")}
              onChange={() => {}}
            />
          </div> */}
      {/* <div className="d-flex justify-content-center gap-5 mt-3"> */}
      {/* <Button
                value="Submit"
                btnStyle="btn-dashboard-primary"
                className="cps-40 cpe-40 text-17-500"
                onClick={() => {
                  updateUserData();
                }}
              /> */}

      {/* <Button
            value="Submit"
            btnStyle="btn-dashboard-primary"
            className="cps-40 cpe-40 text-17-500"
            onClick={() => {
              // updateUserData();
            }}
          /> */}
      {/* </div> */}
      {/* </form> */}
    </div>
  );
};

export default UserEdit;
