import React from "react";
import LabelText from "../LabelText";
import "./TextInput.scss";

const TextInput = ({
  placeholder,
  label,
  required,
  id,
  onChange,
  value,
  rightIcon,
  rightIconClick,
  onKeyUp,
  error,
  type,
  disabled,
  labelPrev,
}) => {
  return (
    <div id="text-input-container">
      <LabelText label={label} required={required} />
      <div className="label-text text-15-500 color-black-40 w-100">
        {labelPrev}
      </div>

      <div className="text-input-block">
        <input
          id={id}
          type={type || "text"}
          placeholder={placeholder}
          className="text-input text-15-500 color-black-100 text-truncate"
          value={value}
          onChange={onChange}
          onKeyUp={onKeyUp}
          disabled={disabled}
          // autoComplete="new-password"
        />
        {rightIcon && (
          <div className="right-icon-block" onClick={rightIconClick}>
            {rightIcon}
          </div>
        )}
        {error && (
          <span className="text-13-500 pt-1">
            <span style={{ color: "red" }}>{error}</span>
          </span>
        )}
      </div>
    </div>
  );
};

export default TextInput;
