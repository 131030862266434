import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { omit } from "lodash";
import Profile from "components/layouts/Profile";
import { getDialCode, titleCaseString } from "utils/helpers";
import { searchUserData } from "store/globalSlice";
import DisplayTable from "../DisplayTable";
import Button from "components/form/Button";
import UserFilterPopup from "../UserFilterPopup";
import { CSVLink } from "react-csv";

const UserReport = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const [showPopup, setShowPopup] = useState(false);
  const [exportData, setExportData] = useState({ data: [], loader: false });
  const [tableData, setTableData] = useState({
    offset: 0,
    limit: 10,
    intervalLimit: 10,
    total: 0,
    loading: true,
    data: [],
    startDate: "",
    endDate: "",
    first_name: "",
    last_name: "",
    mobile_no: "",
    email: "",
    DOB: "",
    college_name: "",
    college_location: "",
    country: "",
    state: "",
    city: "",
    zip: "",
    basic_education: "",
    status: "",
    Ip: "",
    countryCode: "US",
  });
  const exportHeader = [
    "First Name",
    "Last Name",
    "Email",
    "Mobile No",
    "Date Of Birth",
    "College Name",
    "College Location",
    "Country",
    "State",
    "City",
    "Zip",
    "Basic Education",
    // "profile_Image",
  ];
  // console.log(exportHeader);

  // const exportdata = [];
  // tableData?.data.map((elm) => {
  //   const {
  //     first_name,
  //     last_name,
  //     email,
  //     mobile_no,
  //     DOB,
  //     college,
  //     college_location,
  //     country,
  //     state,
  //     city,
  //     zip,
  //     basic_education,
  //     // profile_Image,
  //   } = elm;
  //   exportdata.push([
  //     first_name,
  //     last_name,
  //     email,
  //     mobile_no,
  //     DOB,
  //     college?.college_name,
  //     college_location?.college_location,
  //     country?.country,
  //     state?.state,
  //     city?.city,
  //     zip,
  //     basic_education,
  //     // profile_Image,
  //   ]);

  //   console.log(exportdata);
  // });
  const getExportData = async (returnData) => {
    let flagArray = [];
    flagArray = returnData?.map((o) => {
      return [
        o.first_name || "",
        o.last_name || "",
        o.email || "",
        o.mobile_no || "",
        o.DOB || "",
        o.college?.college_name || "",
        o.college_location?.college_location || "",
        o.country?.country || "",
        o.state?.state || "",
        o.city?.city || "",
        o.zip || "",
        o.basic_education || "",
      ];
    });
    setExportData((prev) => {
      return { ...prev, data: flagArray, loader: false };
    });
  };
  const fetchTableData = async (obj) => {
    setShowPopup(false);
    let payload = omit(obj, ["data", "loading", "total", "intervalLimit"]);
    const response = await dispatch(searchUserData(payload));
    // console.log(response);
    if (response?.status === 200) {
      setTableData((prev) => {
        return {
          ...prev,
          data: response?.data?.rows || [],
          total: response?.data?.count || 0,
          loading: false,
          // offset: 0,
          // limit: 5,
        };
      });
      getExportData(response?.data.rows);
    }
  };

  useEffect(() => {
    fetchTableData(tableData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const header = [
    {
      title: <div className="ps-3">Name</div>,
    },
    {
      title: <div className="text-nowrap">Date of Birth</div>,
    },
    {
      title: <div>Contact Details</div>,
    },

    {
      title: <div className="text-nowrap">College Name</div>,
    },
    {
      title: <div className="text-nowrap">College Location</div>,
    },
    {
      title: <div className="text-nowrap">Country</div>,
    },
    {
      title: <div className="text-nowrap">State</div>,
    },
    {
      title: <div className="text-nowrap">City</div>,
    },
    {
      title: <div className="text-nowrap">Zipcode</div>,
    },
    {
      title: (
        <div className="text-nowrap">Basic Education & Areas of Interset</div>
      ),
    },
  ];
  const rowData = [];
  tableData?.data.forEach((elem) => {
    const {
      first_name,
      last_name,
      email,
      country_code,
      mobile_no,
      DOB,
      college,
      college_location,
      country,
      state,
      city,
      zip,
      basic_education,
      profile_Image,
      createdAt,
    } = elem;
    let obj = [
      {
        value: (
          <div
            className="text-start ps-3 d-flex align-items-center gap-2 pointer text-nowrap"
            onClick={() => {
              navigate(`/users/view-details/${elem?.id}`);
            }}
          >
            <div>
              <Profile
                isRounded
                text={`${first_name} ${last_name}`}
                url={profile_Image}
                size="s-34"
              />
            </div>
            <div>
              <div className="text-17-500-27">
                {first_name && last_name
                  ? titleCaseString(`${first_name} ${last_name}`)
                  : "User"}
              </div>
              <div className="text-15-500 color-black-60">
                Join: {moment.utc(createdAt).format("DD-MMM-YYYY")}
              </div>
            </div>
          </div>
        ),
      },
      {
        value: DOB ? (
          <span className="text-nowrap">
            {moment(DOB).format("DD-MM-YYYY")}
          </span>
        ) : (
          "-"
        ),
      },
      {
        value: (
          <div>
            <div>{email}</div>
            <div>{`${getDialCode(country_code || "-")} ${
              mobile_no || "-"
            }`}</div>
          </div>
        ),
      },
      {
        value: <div className="text-nowrap">{college?.college_name}</div>,
      },
      {
        value: (
          <div className="text-nowrap">
            {college_location?.college_location}
          </div>
        ),
      },
      {
        value: <div>{country?.country || ""}</div>,
      },
      {
        value: <div>{state?.state || ""}</div>,
      },
      {
        value: <div>{city?.city || ""}</div>,
      },
      {
        value: <div className="text-nowrap">{zip || ""}</div>,
      },
      {
        value: <div className="text-nowrap">{basic_education}</div>,
      },
    ];
    rowData.push({ data: obj });
  });

  return (
    <div className="bg-white rounded mt-2 pt-1 pb-1">
      {showPopup && (
        <UserFilterPopup
          tableData={tableData}
          setTableData={setTableData}
          setShowPopup={setShowPopup}
          fetchTableData={fetchTableData}
          onHide={() => {
            setShowPopup(false);
          }}
          handelFilter={(data) => {
            let oldData = {
              ...tableData,
              ...data,
              offset: 0,
              limit: 10,
              loading: true,
            };
            setTableData(oldData);
            fetchTableData(oldData);
            setShowPopup(false);
          }}
        />
      )}
      <div className="d-flex gap-3 text-20-700 justify-content-between align-items-center mb-5 p-3">
        <div className="text-22-700">Users Report</div>
        <div className="d-flex gap-4">
          <Button
            value="Filter"
            btnStyle="btn-dashboard-primary"
            className="cps-30 cpe-30 pt-2 pb-2 text-15-500 h-auto"
            onClick={() => {
              setShowPopup(true);
            }}
          />

          <CSVLink
            data={exportData?.data}
            headers={exportHeader}
            filename={`User Rreport.csv`}
            className="text-decoration-none"
          >
            <Button
              value="Export"
              btnStyle="btn-dashboard-primary"
              className="cps-30 cpe-30 pt-2 pb-2 text-15-500 h-auto"
              btnLoading={exportData.loader}
            />
          </CSVLink>
        </div>
      </div>
      <DisplayTable
        tableData={tableData}
        setTableData={setTableData}
        fetchTableData={fetchTableData}
        header={header}
        rowData={rowData}
      />
    </div>
  );
};
export default UserReport;
