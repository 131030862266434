import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  getAllAskQuestions,
  getAllExpertQuestions,
  getAllFlashCardQuestions,
} from "store/globalSlice";
import { objectToQueryParams } from "utils/helpers";
import StudyToolsForm from "./StudyToolsForm";
import { omit } from "lodash";
import { useDispatch } from "react-redux";
import StudyToolsTable from "./StudyToolsTable";
import Button from "components/form/Button/Button";
import { CSVLink } from "react-csv";

const exportHeader = {
  askQuestion: [
    "question",
    "option1",
    "option2",
    "option3",
    "option4",
    "answer",
    "keywords",
    "slug",
    "meta_title",
    "meta_description",
    "meta_keywords",
  ],
  expertSolution: [
    "title",
    "description",
    "keywords",
    "slug",
    "meta_title",
    "meta_description",
    "meta_keywords",
  ],
  flashCard: [
    "title",
    "externalFile",
    "keywords",
    "slug",
    "meta_title",
    "meta_description",
    "meta_keywords",
  ],
};

const StudyTools = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const { qType } = params;
  const paramsObject = {
    "ask-questions": "askQuestion",
    "expert-solutions": "expertSolution",
    "flash-cards": "flashCard",
  };
  let type = paramsObject[qType];
  const [exportData, setExportData] = useState({
    data: [],
    loader: false,
  });
  const [viewType, setViewType] = useState(0);
  const [tableList, setTableList] = useState({
    data: [],
    total: 0,
    offset: 0,
    limit: 10,
    intervalLimit: 10,
    search: "",
    loading: true,
  });
  const getExportData = async (returnData) => {
    
    let flagArray = [];
    if (returnData.length > 0) {
      if (qType === "ask-questions") {
        flagArray = returnData?.map((o) => {
          return [
            o.question || "",
            o.option1 || "",
            o.option2 || "",
            o.option3 || "",
            o.option4 || "",
            o.answer || "",
            o.keywords || "",
            o.slug || "",
            o.meta_title || "",
            o.meta_description || "",
            o.meta_keywords || "",
          ];
        });
      } else if (qType === "expert-solutions") {
        flagArray = returnData?.map((o) => {
          return [
            o.title || "",
            o.description || "",
            o.keywords || "",
            o.slug || "",
            o.meta_title || "",
            o.meta_description || "",
            o.meta_keywords || "",
          ];
        });
      } else if (qType === "flash-cards") {
        flagArray = returnData?.map((o) => {
          return [
            o.title || "",
            o.externalFile || "",
            o.keywords || "",
            o.slug || "",
            o.meta_title || "",
            o.meta_description || "",
            o.meta_keywords || "",
          ];
        });
      }
    }

    setExportData((prev) => {
      return { ...prev, data: flagArray, loader: false };
    });
  };
  let response;
  const fecthAllAskQuestion = async (obj) => {
    setTableList({
      data: [],
      total: 0,
      offset: 0,
      limit: 10,
      intervalLimit: 10,
      search: "",
      loading: true,
    });

    const queryParams = `?${objectToQueryParams(
      omit(obj, ["data", "loading", "total"])
    )}`;
    response = await dispatch(getAllAskQuestions(queryParams));
    setTableList((prev) => {
      return {
        ...prev,
        data: response?.askQuestionData?.rows || [],
        total: response?.askQuestionData?.count || 0,
        loading: false,
      };
    });
    getExportData(response?.askQuestionData?.rows)
  };
  const fecthAllExpertQuestion = async (obj) => {
    setTableList({
      data: [],
      total: 0,
      offset: 0,
      limit: 10,
      intervalLimit: 10,
      search: "",
      loading: true,
    });
    const queryParams = `?${objectToQueryParams(
      omit(obj, ["data", "loading", "total"])
    )}`;
    response = await dispatch(getAllExpertQuestions(queryParams));
    setTableList((prev) => {
      return {
        ...prev,
        data: response?.expertSolutionData?.rows || [],
        total: response?.expertSolutionData?.count || 0,
        loading: false,
      };
    });
    getExportData(response?.expertSolutionData?.rows)
  };
  const fecthAllFlashQuestion = async (obj) => {
    setTableList({
      data: [],
      total: 0,
      offset: 0,
      limit: 10,
      intervalLimit: 10,
      search: "",
      loading: true,
    });
    const queryParams = `?${objectToQueryParams(
      omit(obj, ["data", "loading", "total"])
    )}`;
    response = await dispatch(getAllFlashCardQuestions(queryParams));

    setTableList((prev) => {
      return {
        ...prev,
        data: response?.flashCardData?.rows || [],
        total: response?.flashCardData?.count || 0,
        loading: false,
      };
    });
    getExportData(response?.flashCardData?.rows)
  };

  // const handelDeleteQues = async (payload, id) => {
  //   let temp;
  //   if (payload === "ask-questions") {
  //     temp = "askQuestion";
  //   } else if (payload === "expert-solutions") {
  //     temp = "expertSolution";
  //   } else if (payload === "flash-cards") {
  //     temp = "flashCard";
  //   }
  //   // console.log("delete:", temp, id);
  //   const response = await dispatch(deleteSingleQues(temp, id));
  //   if (response?.status === 200) {
  //     fetchTableData();
  //   }
  // };

  const fetchTableData = async (obj) => {
    if (qType === "ask-questions") {
      fecthAllAskQuestion(obj);
    } else if (qType === "expert-solutions") {
      fecthAllExpertQuestion(obj);
    } else if (qType === "flash-cards") {
      fecthAllFlashQuestion(obj);
    }
  };
 
  useEffect(() => {
    setViewType(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);
  useEffect(() => {
    if (viewType === 0) {
      fetchTableData(tableList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, viewType]);
  const activeClass =
    "bg-dashboard-primary color-white p-1 pt-2 ps-3 pe-3 pointer d-flex align-items-center";
  const inActiveClass =
    "color-dashboard-primary bg-light-gray p-1 pt-2 ps-3 pe-3 pointer d-flex align-items-center";

  return (
    <div className="bg-white rounded mt-2 pt-5 pb-3">
      <div className="d-flex justify-content-between">
        <div className="d-flex ms-1 gap-3">
          <div
            className={viewType === 0 ? activeClass : inActiveClass}
            onClick={() => {
              setViewType(0);
            }}
          >
            View Questions
          </div>
          <div
            className={viewType === 1 ? activeClass : inActiveClass}
            onClick={() => {
              setViewType(1);
            }}
          >
            Add Questions
          </div>
        </div>
       {tableList.data.length > 0 && 
       <div className="d-flex justify-content-end aligns-items-center">
          <CSVLink
            className="text-decoration-none"
            data={exportData?.data}
            // asyncOnClick={true}
            filename={`${type}.csv`}
            headers={exportHeader[type]}
            // onClick={async (_, done) => {
            //   await getExportData(done);
            // }}
          >
            <Button
              value="Export"
              btnStyle="btn-dashboard-primary"
              className="cps-15 cpe-15 text-15-500"
              btnLoading={exportData.loader}
            />
          </CSVLink>
        </div>}
      </div>
      {viewType === 0 ? (
        <StudyToolsTable
          tableList={tableList}
          setTableList={setTableList}
          fetchTableData={fetchTableData}
          // handelDeleteQues={handelDeleteQues}
          qType={qType}
        />
      ) : (
        <StudyToolsForm type={type} />
      )}
      {/* <div className="d-flex me-2">
          <Button
            value="+ Add Question"
            className="ps-3 pe-3 h-auto pt-2 pb-1"
            btnStyle="btn-primary-darker-outline"
            onClick={() => {
              navigate(`/study-tools/question/add-question`);
            }}
          />
        </div> */}
    </div>
  );
};
export default StudyTools;
