import CheckBox from "components/form/CheckBox/CheckBox";
import React, { useState } from "react";
import "./CardView.scss";

const CardView = ({ tableData, imageIds, setImageIds }) => {
  const [copyId, setCopyId] = useState("");

  const handleClick = (e) => {
    setCopyId(e?.id);
    navigator.clipboard.writeText(e?.ExternalFile);
    setTimeout(() => {
      setCopyId("");
    }, 1000);
  };

  const handleClicked = (e) => {
    let oldList = imageIds;
    if (oldList.includes(e)) {
      setImageIds(imageIds.filter((o) => o !== e));
    } else {
      setImageIds([...imageIds, e]);
    }
  };

  return (
    <div className="gallery-section pb-2 mb-4">
      {tableData?.data.map((elem, index) => {
        const isPDF = elem?.ExternalFile?.split(".")?.pop() === "pdf";

        return (
          <div className="img-card" key={index}>
            <div className="grid-block">
              <div className="d-flex mt-2 ms-2 pb-2">
                <CheckBox
                  id={elem?.id}
                  onChange={() => {
                    handleClicked(elem?.id);
                  }}
                  isCheck={imageIds.includes(elem?.id)}
                />
              </div>
              {isPDF ? (
                <iframe
                  src={elem?.ExternalFile}
                  title="Pdf"
                  className="w-100"
                  style={{
                    width: "150px",
                    height: "158px",
                  }}
                />
              ) : (
                <div className="image-container">
                  <div className="image-div mb-3 d-flex justify-content-center">
                    <img
                      src={elem?.ExternalFile}
                      alt="photos"
                      className="grid-image fill-image"
                    />
                  </div>
                </div>
              )}
            </div>

            <div className="d-flex justify-content-center mt-2">
              <span
                id={elem?.id}
                className="color-dashboard-primary pointer"
                onClick={() => {
                  handleClick(elem);
                }}
              >
                {copyId === elem.id ? "Copied!" : "Copy URL"}
              </span>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default CardView;
