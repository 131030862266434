import React from "react";
import { useParams } from "react-router-dom";
// import ReportsTable from "./ReportsTable";
import SubscriptionReport from "./SubscriptionReport";
import UserReport from "./UserReport";
import SubscribeReport from "./SubscribeReport/SubscribeReport";
import IpReport from "./IpReport/IpReport";

const Report = () => {
  const params = useParams();

  const { rType } = params;
  // const paramsObj = {
  //   "user-reports": "userRreports",
  //   "subscription-reports": "subscriptionReports",
  //   "un-subscribe-reports": "un-subscribeReports",
  //   "ip-reports": "ipReports",
  // };
  // let type = paramsObj[rType];
  return (
    <div id="reports-details">
      {/* <div className="d-flex ms-1 gap-3 text-20-700 ps-2 justify-content-between mb-5">
        Report
        <div className="d-flex gap-4">
          <Button
            value="Filter"
            btnStyle="btn-dashboard-primary"
            className="cps-30 cpe-30 pt-2 pb-2 text-15-500 h-auto"
              onClick={() => {
                setShowPopup(true);
              }}
          />

          <Button
              value="Export"
              btnStyle="btn-dashboard-primary"
              className="cps-30 cpe-30 pt-2 pb-2 text-15-500 h-auto"
            />
        </div>
      </div> */}

      {rType === "user-reports" && <UserReport />}
      {rType === "subscription-reports" && <SubscriptionReport />}
      {rType === "un-subscribe-reports" && <SubscribeReport />}
      {rType === "ip-reports" && <IpReport />}
    </div>
  );
};

export default Report;
