import CheckBox from "components/form/CheckBox/CheckBox";
import React, { useState } from "react";
import Table from "react-bootstrap/Table";
import "./TableView.scss";

const TableView = ({ tableData, imageIds, setImageIds }) => {
  const [copyId, setCopyId] = useState("");
  const handleClick = (e) => {
    setCopyId(e?.id);
    navigator.clipboard.writeText(e?.ExternalFile);
    setTimeout(() => {
      setCopyId("");
    }, 1000);
  };
  const handleClicked = (e) => {
    let oldList = imageIds;
    if (oldList.includes(e)) {
      setImageIds(imageIds.filter((o) => o !== e));
    } else {
      setImageIds([...imageIds, e]);
    }
  };
  return (
    <div>
      <Table responsive>
        <thead>
          <tr>
            <th className="text-center">Image</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody className="table-image-section">
          {tableData?.data.map((elem, index) => {
            const isPDF = elem?.ExternalFile?.split(".")?.pop() === "pdf";
            return (
              <tr key={index}>
                <td>
                  <div className="d-flex align-items-center">
                    <CheckBox
                      id={elem?.id}
                      onChange={() => {
                        handleClicked(elem?.id);
                      }}
                      isCheck={imageIds.includes(elem?.id)}
                    />
                    <div className="d-flex gap-3 align-items-center">
                      {isPDF ? (
                        <iframe
                          src={elem?.ExternalFile}
                          title="Pdf"
                          className="w-100"
                          style={{
                            width: "70px",
                            height: "100px",
                          }}
                        />
                      ) : (
                        <div className="image-div-table">
                          <img
                            src={elem?.ExternalFile}
                            alt="6"
                            className="image-table"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </td>
                <td>
                  <div>
                    <div
                      className="color-dashboard-primary pointer"
                      onClick={() => {
                        handleClick(elem);
                      }}
                    >
                      {copyId === elem.id ? "Copied!" : "Copy URL"}
                    </div>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

export default TableView;
