import Table from "components/layouts/Table";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  deleteUser,
  getAllUser,
  throwSuccess,
  userChangeStaus,
} from "store/globalSlice";
import {
  getDialCode,
  objectToQueryParams,
  titleCaseString,
} from "utils/helpers";
import Profile from "components/layouts/Profile";
import moment from "moment/moment";
import { Dropdown } from "react-bootstrap";
import { icons } from "utils/constants";
import StatusContainer from "components/layouts/StatusContainer/StatusContainer";
import { omit } from "lodash";
import { useNavigate } from "react-router-dom/dist";
import DeleteConfirmPopup from "components/layouts/DeleteConfirmPopup/DeleteConfirmPopup";
import Button from "components/form/Button/Button";
import { CSVLink } from "react-csv";

const Users = () => {
  // const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isDeletePopup, setIsDeletePopup] = useState(false);
  const [deleteId, setDeleteId] = useState({});
  const [exportData, setExportData] = useState({
    data: [],
    loader: false,
  });
  const [tableData, setTableData] = useState({
    offset: 0,
    limit: 10,
    intervalLimit: 10,
    total: 0,
    search: "",
    loading: true,
    data: [],
  });
  const exportHeader = [
    "First Name",
    "Last Name",
    "Email",
    "Mobile No",
    "Date Of Birth",
    "College Name",
    "College Location",
    "Basic Education",
    "Mailing Address",
    "Country",
    "State",
    "City",
    "Zip",
    "Subscription",
    "Status",
    "Join Date",
  ];

  const getUserExport = async (returnData) => {
    let flagArray = [];

    if (returnData.length > 0) {
      flagArray = returnData?.map((o) => {
        return [
          o.first_name || "",
          o.last_name || "",
          o.mobile_no || "",
          o.email || "",
          o.DOB || "",
          o.college?.college_name || "",
          o.college_location?.college_location || "",
          o.basic_education || "",
          o.mailing_address || "",
          o.country?.country || "",
          o.state?.state || "",
          o.city?.city || "",
          o.zip || "",
          o.Orders[0]?.subscriptionPlan || "",
          o.status === 1 ? "Active" : "Inactive" || "",
          moment(o.createdAt).format("DD-MMM-YYYY") || "",
        ];
      });
    }
    setExportData((prev) => {
      return { ...prev, data: flagArray, loader: false };
    });
  };

  // const handelDelete = async (id) => {
  //   // console.log("response: ", id);
  //   const response = await dispatch(deleteUser(id));
  //   if (response?.status === 200) {
  //     fetchTableData();
  //   }
  // };

  const fetchTableData = async (obj) => {
    const queryParams = `?${objectToQueryParams(
      omit(obj, ["data", "loading", "total"])
    )}`;

    const response = await dispatch(getAllUser(queryParams));
    setTableData((prev) => {
      return {
        ...prev,

        data: response?.data?.rows || [],
        total: response?.data?.count || 0,
        loading: false,
      };
    });
    getUserExport(response?.data.rows);
  };

  useEffect(() => {
    fetchTableData(tableData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const header = [
    {
      title: <div className="text-start ps-3">Name</div>,
    },
    {
      title: "Email ID",
    },
    {
      title: "Mobile",
    },
    {
      title: <div className="text-start text-nowrap">Date Of Birth</div>,
    },
    {
      title: "Subscription",
    },
    {
      title: "Status",
    },
    {
      title: "Action",
    },
  ];

  const rowData = [];
  tableData?.data.forEach((elem) => {
    const {
      // id,
      first_name,
      last_name,
      is_email_verify,
      email,
      is_mobile_verify,
      country_code,
      mobile_no,
      profile_Image,
      createdAt,
      DOB,
      Orders,
    } = elem;
    let plan = Orders?.[0] || {};
    const { subscriptionPlan, start_date, end_date } = plan;

    // let planStart = moment().format("DD-MMM-YYYY");
    // let planEnd = "";
    // if (planDate) {
    //   if (subscriptionPlan === "Monthly") {
    //     planEnd = moment(planStart).subtract(30, "d").format("DD-MMM-YYYY");
    //   }
    //   if (subscriptionPlan === "Quarterly") {
    //     planEnd = moment(planStart).subtract(90, "d").format("DD-MMM-YYYY");
    //   }
    //   if (subscriptionPlan === "Yearly") {
    //     planEnd = moment(planStart).subtract(365, "d").format("DD-MMM-YYYY");
    //   }
    // }
    let obj = [
      {
        value: (
          <div
            className="text-start ps-3 d-flex align-items-center gap-2 pointer"
            onClick={() => {
              navigate(`/users/view-details/${elem?.id}`);
            }}
          >
            <div>
              <Profile
                isRounded
                text={`${first_name} ${last_name}`}
                url={profile_Image}
                size="s-34"
              />
            </div>
            <div>
              <div>
                {first_name && last_name
                  ? titleCaseString(`${first_name} ${last_name}`)
                  : "User"}
              </div>
              <div className="text-13-400 color-black-60 text-nowrap">
                Join: {moment(createdAt).format("DD-MM-YYYY")}
              </div>
            </div>
          </div>
        ),
      },
      {
        value: (
          <div className="d-flex">
            {email || "-"}
            <div className="ps-1">
              {is_email_verify === 1 && (
                <i className="bi bi-check-circle-fill ps-1 text-success" />
              )}
            </div>
          </div>
        ),
      },
      {
        value: (
          <div className="d-flex text-nowrap">
            {`${getDialCode(country_code || "-")} ${mobile_no || "-"}`}

            <div className="ps-1">
              {is_mobile_verify === 1 && (
                <i className="bi bi-check-circle-fill ps-1 text-success" />
              )}
            </div>
          </div>
        ),
      },
      {
        value: DOB ? <span>{moment(DOB).format("DD-MM-YYYY")}</span> : "-",
      },
      {
        value: (
          <div>
            {start_date ? (
              <>
                <div>{subscriptionPlan}</div>
                <div>{`${
                  start_date ? moment(start_date).format("DD-MM-YYYY") : ""
                }${start_date && end_date ? " - " : ""}${
                  end_date ? moment(end_date).format("DD-MM-YYYY") : ""
                }`}</div>
              </>
            ) : (
              "-"
            )}
          </div>
        ),
      },
      {
        value: (
          <StatusContainer
            title="User"
            data={elem}
            apiFunction={async () => {
              return await dispatch(userChangeStaus(elem?.id));
            }}
            handelSuccess={() => {
              setTableData((prev) => {
                let oldVal = prev?.data;
                oldVal.map((el) => {
                  let newObj = el;
                  if (newObj?.id === elem?.id) {
                    newObj.status = elem?.status === 1 ? 0 : 1;
                  }
                  return newObj;
                });
                return { ...prev, data: oldVal };
              });
            }}
          />
        ),
      },
      {
        value: (
          <Dropdown align="end">
            <Dropdown.Toggle variant="" id="dropdown-basic">
              <img src={icons.threeDots} alt="options" className="pointer" />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item
                href=""
                className="d-flex align-items-center gap-2 mb-1"
                onClick={() => {
                  navigate(`/users/view-details/${elem?.id}`);
                }}
              >
                <span>
                  <img src={icons.viewDetails} alt="details" />
                </span>
                <span className="mt-1">View Details</span>
              </Dropdown.Item>
              <Dropdown.Item
                href=""
                className="d-flex align-items-center gap-2"
                onClick={() => {
                  navigate(`/users/edit/${elem?.id}`);
                }}
              >
                <span>
                  <img src={icons.edit} alt="edit" />
                </span>
                <span className="mt-1">Edit</span>
              </Dropdown.Item>
              <Dropdown.Item
                href=""
                className="d-flex align-items-center gap-2"
                onClick={() => {
                  setIsDeletePopup(true);
                  setDeleteId(elem);
                }}

                // onClick={(e) => {
                //   e.preventDefault();
                //   handelDelete(id);
                // }}
              >
                <span>
                  <img src={icons.trash} alt="trash" />
                </span>
                <span className="mt-1">Delete</span>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        ),
      },
    ];
    rowData.push({ data: obj });
  });
  // const csvData = [
  //   ["Ahmed", "Tomi", "ah@smthing.co.com"],
  //   ["Raed", "Labes", "rl@smthing.co.com"],
  //   ["Yezzi", "Min l3b", "ymin@cocococo.com"],
  // ];

  return (
    <div className="bg-white rounded pb-3">
      {isDeletePopup &&
        (deleteId ? (
          <DeleteConfirmPopup
            title="User"
            deleteId={deleteId}
            onHide={() => {
              setIsDeletePopup(false);
            }}
            apiFunction={async () => {
              await dispatch(deleteUser(deleteId.id));
              dispatch(throwSuccess("User Delete Successfully."));
              setIsDeletePopup(false);
              fetchTableData();
              return;
            }}
            handelSuccess={() => {
              fetchTableData();
            }}
          />
        ) : (
          ""
        ))}

      <div className="text-20-700 color-black-100 cps-24 cpt-24 cpe-24 mb-3 d-flex justify-content-between">
        Users
        {tableData.data.length > 0 && (
          <div>
            <CSVLink
              className="text-decoration-none"
              filename={`User.csv`}
              data={exportData?.data}
              headers={exportHeader}
            >
              <Button
                value="Export"
                btnStyle="btn-dashboard-primary"
                className="cps-15 cpe-15 text-15-500"
                // btnLoading={exportData.loader}
              />
            </CSVLink>
          </div>
        )}
      </div>
      <Table
        isSearch
        header={header}
        rowData={rowData}
        isLoading={tableData?.loading}
        searchLabel="Search User"
        searchText={tableData?.search}
        tableData={tableData}
        searchInputChange={(searchText) => {
          let oldData = { ...tableData, search: searchText, loading: true };
          setTableData(oldData);
          fetchTableData(oldData);
        }}
        changeOffset={(newOffset, newLimit = tableData.limit) => {
          let oldData = {
            ...tableData,
            offset: newOffset,
            limit: newLimit,
            loading: true,
          };
          setTableData(oldData);
          fetchTableData(oldData);
        }}
        // changeLimit={(newLimit) => {
        //   let oldData = { ...tableData, limit: newLimit, loading: true };
        //   setTableData(oldData);
        //   fetchTableData(oldData);
        // }}
        isPagination
      />
    </div>
  );
};
export default Users;
